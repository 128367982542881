import Vue from "vue";
import Vuex from "vuex";
import WebUtils from "./utils/WebUtils"
import FileUtils from "./utils/FileUtils";

Vue.use(Vuex);

const store = new Vuex.Store({
    strict: true,
    state: { 
        authToken: window.localStorage.getItem('AUTH_TOKEN'),
        urlParams: {},
        hotels: {
            data: {},
            pagination: {},
        },
        customers: {
            data: {},
            pagination: {},
        },
        pages: {
            data: {},
            pagination: {},
        },
        sidebarEntries: [],
        dashboard: {
            customers: {},
            points: {
                claimed: {},
                redeemed: {},
            },
            hotels: {
                deals: {},
            },
            outlets: {
                deals: {},
            },
            deals: {},
            users: {},
        },
        settings: {
            data: {}
        }
    },
    getters: {
        isLoggedIn(state) {
            return state.authToken != null;
        },
        getAuthToken(state) {
            return state.authToken;
        },
        getHotels(state) {
            return state.hotels;
        },
        getHotel(state) {
            const { hotelId } = state.urlParams;
            try {
                return state.hotels.data[hotelId];
            }
            catch (ex) {}
            return null;
        },
        getHotelOutlets(state) {
            const { hotelId } = state.urlParams;
            try {
                return state.hotels.data[hotelId].outlets;
            }
            catch(ex) {
                return {
                    data: [],
                    pagination: {},
                };
            }
        },
        getHotelDeals(state) {
            const { hotelId } = state.urlParams;
            try {
                return state.hotels.data[hotelId].deals;
            }
            catch(ex) {
                return {
                    data: [],
                    pagination: {},
                };
            }
        },
        getHotelOutlet(state) {
            const { hotelId, outletId } = state.urlParams;
            try {
                return state.hotels.data[hotelId].outlets.data[outletId];
            }
            catch (ex) {}
            return null;
        },
        getHotelOutletDeals(state) {
            const { hotelId, outletId } = state.urlParams;
            try {
                return state.hotels.data[hotelId].outlets.data[outletId].deals;
            }
            catch (ex) {}
            return null;
        },
        getCustomers(state) {
            return state.customers;
        },
        getCustomer(state) {
            const { customerId } = state.urlParams;
            try {
                return state.customers.data[customerId];
            }
            catch (ex) {}
            return null;
        },
        getSidebarEntries(state) {
            return state.sidebarEntries;
        },
        getDashboard(state) {
            return state.dashboard;
        },
        getPages(state) {
            return state.pages;
        },
        getPage(state) {
            const { pageId } = state.urlParams;
            try {
                return state.pages.data[pageId];
            }
            catch(ex) {}
            return null;
        },
        getSetting(state) {
            const { settingId } = state.urlParams;
            try {
                return state.settings.data[settingId];
            }
            catch (ex) {}
            return null;
        },
    },
    mutations: {
        setAuthToken(state, token) {
            state.authToken = token;
        },
        setUrlParams(state, data) {
            state.urlParams = data;
        },
        loadHotels(state, data) {
            const h = {};
            data.hotels.forEach((hotel) => {
                h[hotel.id] = hotel;
            });
            const prevHotels = state.hotels.data;
            const newHotels = Object.assign({}, prevHotels, h);
            const prevCurrentPage = state.hotels.pagination.current_page ? state.hotels.pagination.current_page : 0;
            var pagiantionToUse = data.pagination;
            if (prevCurrentPage > data.pagination.current_page) {
                pagiantionToUse = state.hotels.pagination;
            }
            state.hotels = {
                data: newHotels,
                pagination: pagiantionToUse,
            };
        },
        addHotel(state, hotel) {
            Vue.set(state.hotels.data, hotel.id, hotel);
        },
        loadHotel(state, hotel) {
            Vue.set(state.hotels.data, hotel.id, hotel);
        },
        removeHotel(state, hotelId) {
            Vue.delete(state.hotels.data, hotelId);
        },
        loadHotelOutlets(state, data) {
            const hotelId = data.hotelId;

            const o = {};
            data.outlets.forEach((outlet) => {
                o[outlet.id] = outlet;
            });
            
            Vue.set(state.hotels.data[hotelId], 'outlets', {
                data: o,
                pagination: data.pagination,
            });
        },
        loadSetting(state, setting) {
            Vue.set(state.settings.data, setting.id, setting);
        },
        addHotelOutlet(state, data) {
            const {
                outlet,
                hotelId,
            } = data;

            if (!state.hotels.data[hotelId].outlets) {
                Vue.set(state.hotels.data[hotelId], "outlets", {
                    data: [],
                    pagination: {},
                });
            }
            
            Vue.set(state.hotels.data[hotelId].outlets.data, outlet.id, outlet);
        },
        editHotelOutlet(state, data) {
            const {
                outlet,
                hotelId,
            } = data;

            Vue.set(state.hotels.data[hotelId].outlets.data, outlet.id, outlet);
        },
        deleteHotelOutlet(state, data) {
            const {
                outletId,
                hotelId,
            } = data;

            Vue.delete(state.hotels.data[hotelId].outlets.data, outletId);
        },
        loadHotelDeals(state, data) {
            const hotelId = data.hotelId;
            if (!state.hotels.data[hotelId].deals) {
                Vue.set(state.hotels.data[hotelId], "deals", {
                    data: [],
                    pagination: {},
                });
            }
            const d = {};
            data.deals.forEach((deal) => {
                d[deal.id] = deal;
            });

            Vue.set(state.hotels.data[hotelId], "deals", {
                data: d,
                pagination: data.pagination,
            });
        },
        addHotelDeal(state, data) {
            const {
                deal,
                hotelId,
            } = data;
            if (!state.hotels.data[hotelId].deals) {
                Vue.set(state.hotels.data[hotelId], "deals", {
                    data: [],
                    pagination: {},
                });
            }

            Vue.set(state.hotels.data[hotelId].deals.data, deal.id, deal);
        },
        editHotelDeal(state, data) {
            const {
                deal,
                hotelId,
            } = data;

            Vue.set(state.hotels.data[hotelId].deals.data, deal.id, deal);
        },
        deleteHotelDeal(state, data) {
            const {
                dealId,
                hotelId,
            } = data;

            Vue.delete(state.hotels.data[hotelId].deals.data, dealId);
        },
        addHotelDealCarousel(state, data) {
            const {
                hotelId,
                dealId
            } = data;

            Vue.set(state.hotels.data[hotelId].deals.data[dealId], "isInCarousel", true);
        },
        removeHotelDealCarousel(state, data) {
            const {
                hotelId,
                dealId
            } = data;

            Vue.set(state.hotels.data[hotelId].deals.data[dealId], "isInCarousel", false);
        },
        addHotelOutletDealCarousel(state, data) {
            const {
                hotelId,
                outletId,
                dealId
            } = data;

            Vue.set(state.hotels.data[hotelId].outlets.data[outletId].deals.data[dealId], "isInCarousel", true);
        },
        removeHotelOutletDealCarousel(state, data) {
            const {
                hotelId,
                outletId,
                dealId
            } = data;

            Vue.set(state.hotels.data[hotelId].outlets.data[outletId].deals.data[dealId], "isInCarousel", false);
        },
        loadHotelOutlet(state, data) {
            const {
                hotelId,
                outletId,
                outlet
            } = data;

            if (!state.hotels.data[hotelId].outlets) {
                Vue.set(state.hotels.data[hotelId], "outlets", {
                    data: [],
                    pagination: {},
                });
            }

            Vue.set(state.hotels.data[hotelId].outlets.data, outletId, outlet);
        },
        loadHotelOutletDeals(state, data) {
            const {
                hotelId,
                outletId,
            } = data;
            
            const d = {};
            data.deals.forEach((deal) => {
                d[deal.id] = deal;
            });

            Vue.set(state.hotels.data[hotelId].outlets.data[outletId], "deals", {
                data: d,
                pagination: data.pagination,
            });
        },
        addHotelOutletDeal(state, data) {
            const {
                hotelId,
                outletId,
                deal,
            } = data;

            Vue.set(state.hotels.data[hotelId].outlets.data[outletId].deals.data, deal.id, deal);
        },
        editHotelOutletDeal(state, data) {
            const {
                hotelId,
                outletId,
                deal,
            } = data;

            Vue.set(state.hotels.data[hotelId].outlets.data[outletId].deals.data, deal.id, deal);
        },
        deleteHotelOutletDeal(state, data) {
            const {
                hotelId,
                outletId,
                hotelOutletDealId,
            } = data;

            Vue.delete(state.hotels.data[hotelId].outlets.data[outletId].deals.data, hotelOutletDealId);
        },
        loadCustomers(state, data) {
            const m = {};
            data.customers.forEach((customer) => {
                m[customer.id] = customer;
            });
            state.customers = {
                data: m,
                pagination: data.pagination
            };
        },
        addCustomer(state, customer) {
            Vue.set(state.customers, customer.id, customer);
        },
        loadCustomer(state, customer) {
            Vue.set(state.customers, customer.id, customer);
        },
        loadCustomerPoints(state, customerId, points) {
            Vue.set(state.customers[customerId], "points", points);
        },
        addCustomerPoints(state, customerId, points) {
            Vue.set(state.customers[customerId], "points", state.customers[customerId].points + points);
        },
        setCustomerPoints(state, customerId, points) {
            Vue.set(state.customers[customerId], "points", points);
        },
        setSidebarEntries(state, sidebarEntries) {
            state.sidebarEntries = sidebarEntries;
        },
        loadDashboard(state, dashboard) {
            state.dashboard = dashboard;
        },
        loadPages(state, data) {
            const d = {};
            data.pages.forEach((page) => {
                d[page.id] = page;
            });
            const prevPages = state.pages.data;
            const newPages = Object.assign({}, prevPages, d);
            const prevCurrentPage = state.pages.pagination.current_page ? state.pages.pagination.current_page : 0;
            var pagiantionToUse = data.pagination;
            if (prevCurrentPage > data.pagination.current_page) {
                pagiantionToUse = state.pages.pagination;
            }
            state.pages = {
                data: newPages,
                pagination: pagiantionToUse,
            };
        },
        loadPage(state, page) {
            Vue.set(state.pages.data, page.id, page);
        },
        removePage(state, hotelId) {
            Vue.delete(state.pages.data, hotelId);
        },
    },
    actions: {
        login(context, data) {
            const { email, password } = data;
            const formData = new FormData();
            formData.append('email', email);
            formData.append('password', password);

            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath('/api/staff/login'), 'POST', formData, false).then((response) => {
                    console.log('LOGIN', response);
    
                    const data = response.data;
                    if (data.success) {
                        context.dispatch('setAuthToken', data.token.access_token).then(() => {
                            resolve();
                        });
                    }
                    else {
                        reject(data.message);
                    }
                })
                .catch((reason) => {
                    console.log("REASON", reason, reason.response);
                    reject(reason);
                });
            });
        },
        setAuthToken(context, token) {
            window.localStorage.setItem('AUTH_TOKEN', token);
            context.commit('setAuthToken', token);
        },
        removeAuthToken(context) {
            window.localStorage.removeItem('AUTH_TOKEN');
            context.commit('setAuthToken', null);
        },
        loadHotels(context, payload) {
            const {
                page
            } = payload;
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/hotels?page=${page}`), 'GET', {}).then((response) => {
                    if (response.status == 200) {
                        context.commit('loadHotels', {
                            hotels: response.data.hotels,
                            pagination: response.data.meta.pagination,
                        });
                    }
                    resolve();
                }).catch((error) => {
                    console.log('ERROR', "/api/hotels", error);
                    reject(error);
                });
            });
        },
        addHotel(context, hotel) {
            return new Promise((resolve, reject) => {
                try {
                    const formData = new FormData();
                    formData.append('access_code', hotel.access_code);
                    formData.append('ratio', hotel.ratio);
                    formData.append('location[lat]', hotel.location.lat);
                    formData.append('location[lng]', hotel.location.lng);
                    formData.append('hotel_currency_id', hotel.currency_id);
                    formData.append('lang_iso_code', hotel.lang_iso_code);

                    Object.values(hotel.images).forEach(async (imageFile, index) => {
                        if (imageFile instanceof File) {
                            formData.append(`images[${index}][name]`, FileUtils.getImageFilename(imageFile));
                            formData.append(`images[${index}][type]`, FileUtils.getImageType(imageFile));
                            formData.append(`images[${index}][file]`, imageFile);
                        }
                    });

                    WebUtils.request(WebUtils.getApiPath('/api/hotels'), 'post', formData, true)
                    .then((response) => {
                        context.commit('addHotel', response.data.hotel);
                        resolve(response.data.hotel);
                    })
                    .catch((error) => {
                        console.info(error)
                        reject(error);
                    });
                    
                }
                catch(ex) {
                    console.info(ex)
                    reject(ex);
                }
            });
        },
        loadHotel(context, hotelId) {
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/hotels/${hotelId}`), 'get', null, true)
                .then((response) => {
                    const hotel = response.data;
                    resolve(hotel);
                })
                .catch((error) => {
                    console.log('/api/hotels/:hotelId', error);
                    reject(error);
                });
            });
        },
        editHotel(context, data) {
            const {
                hotel,
                removedImages,
            } = data;
            return new Promise((resolve, reject) => {

                console.info('hotel', hotel)
                try {
                    const formData = new FormData();
                    formData.append('_method', 'PATCH');
                    formData.append('access_code', hotel.access_code);
                    formData.append('ratio', hotel.ratio);
                    formData.append('location[lat]', hotel.lat);
                    formData.append('location[lng]', hotel.lng);
                    formData.append('hotel_currency_id', hotel.currency_id);
                    formData.append('access_code', hotel.access_code);
                    removedImages.forEach((imageId, index) => {
                        formData.append(`removed_images[${index}]`, imageId);
                    });
    
                    Object.values(hotel.hotel_images).forEach(async (imageFile, index) => {
                        if (imageFile instanceof File) {
                            formData.append(`images[${index}][name]`, FileUtils.getImageFilename(imageFile));
                            formData.append(`images[${index}][type]`, FileUtils.getImageType(imageFile));
                            formData.append(`images[${index}][file]`, imageFile);
                        }
                    });

                    WebUtils.request(WebUtils.getApiPath(`/api/hotels/${hotel.id}`), 'post', formData, true)
                        .then((response) => {
                            console.log("/hotel/edit", response.data);
                            context.commit('loadHotel', response.data.hotel);
                            resolve(response.data.hotel);
                        })
                        .catch((error) => {
                            if (error.response) {
                                console.log("ERROR RESPONSE", error.response);
                            }
                            reject(error);
                        });
                }
                catch(ex) {
                    reject(ex);
                }
            });
        },
        removeHotel(context, hotelId) {
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/hotels/${hotelId}`), 'delete', null, true)
                .then((response) => {
                    context.commit('removeHotel', hotelId);
                    resolve(response.data.success);
                })
                .catch((error) => {
                    console.log('/api/hotels/:hotelId/delete', error);
                    reject(error);
                });
            });
        },
        loadHotelOutlets(context, payload) {
            const {
                hotelId,
                page
            } = payload;

            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotelId}/outlets?page=${page}`), 'get', null, true)
                    .then((response) => {
                        context.commit('loadHotelOutlets', {
                            hotelId, 
                            outlets: response.data.outlets,
                            pagination: response.data.meta.pagination,
                        });

                        resolve(response.data.outlets);
                    })
                    .catch((error) => {
                        console.log('/api/hotel/:hotelId/outlets error', error);
                        reject(error);
                    });
            });
        },
        addHotelOutlet(context, payload) {
            const {
                hotel,
                outlet,
            } = payload;
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append('access_code', outlet.access_code);

                outlet.schedules.forEach((schedule, index) => {
                    formData.append(`schedules[${index}][day]`, schedule.day);
                    formData.append(`schedules[${index}][time][start]`, schedule.time.start);
                    formData.append(`schedules[${index}][time][end]`, schedule.time.end);
                });
                
                outlet.images.forEach((imageFile, index) => {
                    formData.append(`images[${index}][name]`, FileUtils.getImageFilename(imageFile));
                    formData.append(`images[${index}][type]`, FileUtils.getImageType(imageFile));
                    formData.append(`images[${index}][file]`, imageFile);
                });

                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotel.id}/outlets`), 'post', formData, true)
                .then((response) => {
                    // context.commit('addHotelOutlet', {
                    //     outlet: response.data.outlet,
                    //     hotelId: hotel.id,
                    // });
                    resolve(response.data.outlet);
                })
                .catch((error) => {
                    reject(error);
                });

            });
        },
        editHotelOutlet(context, payload) {
            const {
                hotelId,
                outlet,
                removedSchedules,
                removedImages,
            } = payload;
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append('_method', 'PATCH');
                formData.append('id', outlet.id);
                formData.append('name', outlet.name);
                formData.append('description', outlet.description);
                formData.append('access_code', outlet.access_code);
                
                outlet.schedules.forEach((schedule, index) => {
                    if (schedule.id) {
                        formData.append(`schedules[${index}][id]`, schedule.id);
                    }
                    formData.append(`schedules[${index}][day]`, schedule.day);
                    formData.append(`schedules[${index}][time][start]`, schedule.time.start);
                    formData.append(`schedules[${index}][time][end]`, schedule.time.end);
                });

                removedSchedules.forEach((scheduleId, index) => {
                    formData.append(`removed_schedules[${index}]`, scheduleId);
                });
                
                outlet.images.forEach(async (imageFile, index) => {
                    if (imageFile instanceof File) {
                        formData.append(`images[${index}][name]`, FileUtils.getImageFilename(imageFile));
                        formData.append(`images[${index}][type]`, FileUtils.getImageType(imageFile));
                        formData.append(`images[${index}][file]`, imageFile);
                    }
                });

                removedImages.forEach((imageId, index) => {
                    formData.append(`removed_images[${index}]`, imageId);
                });

                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotelId}/outlets/${outlet.id}`), 'post', formData, true)
                        .then((response) => {
                            // context.commit('editHotelOutlet', {
                            //     outlet: response.data.outlet,
                            //     hotelId,
                            // });
                            resolve(response.data.outlet);
                        })
                        .catch((error) => {
                            reject(error);
                        });
            });
        },
        deleteHotelOutlet(context, payload) {
            const {
                hotelId,
                outletId,
            } = payload;

            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotelId}/outlets/${outletId}`), 'delete', null, true)
                    .then((response) => {
                        context.commit('deleteHotelOutlet', {
                            hotelId,
                            outletId,
                        });

                        resolve(response.data.success);
                    })
                    .catch((error) => {
                        console.log(`/api/hotel/${hotelId}/outlets/${outletId}:delete error`, error.response, error);
                        reject(error);
                    });
            });
        },
        loadHotelDeals(context, payload) {
            const {
                hotelId,
                page
            } = payload;
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotelId}/deals?all&page=${page}`), 'get', null, true)
                .then((response) => {
                    context.commit('loadHotelDeals', {
                        hotelId, 
                        deals: response.data.hotel_deals,
                        pagination: response.data.meta.pagination,
                    });

                    resolve(response.data.deals);
                })
                .catch((error) => {
                    console.log('/api/hotel/:hotelId/deals error', error);
                    reject(error);
                });
            });
        },
        addHotelDeal(context, payload) {
            const {
                hotelId,
                deal,
                selected_discount_value,
                discount_code,
                discount_code_link_phone
            } = payload;
            return new Promise((resolve, reject) => {
                let formData = new FormData();

                formData.append('date[start]', deal.date.start);
                formData.append('date[end]', deal.date.end);

                formData.append('discount[amount]', deal.discount.amount);

                formData.append('discount_code_option', selected_discount_value);
                formData.append('discount_code', discount_code);
                formData.append('discount_code_link_phone', discount_code_link_phone);

                formData.append('title_color', deal.title_color);
                formData.append('name_color', deal.name_color);
                formData.append('description_color', deal.description_color);
                formData.append('availability_color', deal.availability_color);

                formData.append('image[file]', deal.image);
                formData.append(`image[name]`, FileUtils.getImageFilename(deal.image));
                formData.append(`image[type]`, FileUtils.getImageType(deal.image));

                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotelId}/deals`), 'post', formData, true)
                .then((response) => {
                    // context.commit('addHotelDeal', {
                    //     deal: response.data.deal,
                    //     hotelId,
                    // });
                    resolve(response.data.deal);
                })
                .catch((error) => {
                    reject(error);
                });

            });
        },
        editHotelDeal(context, payload) {
            const {
                dealForId,
                dealForUrl,
                deal,
            } = payload;
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append('_method', 'PATCH');
                formData.append('name', deal.name);
                formData.append('description', deal.description);

                formData.append('date[id]', deal.date.id);
                formData.append('date[start]', deal.date.start);
                formData.append('date[end]', deal.date.end);

                formData.append('discount[amount]', deal.discountAmount);

                formData.append('discount_code_option', deal.selectedDiscountValue);
                formData.append('discount_code', deal.discountCode);
                formData.append('discount_code_link_phone', deal.discountCodeLinkPhone);

                formData.append('title_color', deal.titleColor);
                formData.append('name_color', deal.nameColor);
                formData.append('description_color', deal.descriptionColor);
                formData.append('availability_color', deal.availabilityColor);

                if(deal.dealImage){
                    formData.append('image[file]', deal.dealImage);
                    formData.append(`image[name]`, FileUtils.getImageFilename(deal.dealImage));
                    formData.append(`image[type]`, FileUtils.getImageType(deal.dealImage));
                }
                console.info('uri', dealForUrl)

                WebUtils.request(dealForUrl, 'post', formData, true)
                    .then((response) => {
                        // context.commit('editHotelDeal', {
                        //     deal: response.data.deal,
                        //     hotelId,
                        // });
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                });

            });
        },
        editDeal(context, payload) {
            const {
                dealForId,
                dealFor,
                deal,
            } = payload;
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append('_method', 'PATCH');

                formData.append('date[id]', deal.date.id);
                formData.append('date[start]', deal.date.start);
                formData.append('date[end]', deal.date.end);

                formData.append('discount[amount]', deal.discountAmount);

                formData.append('discount_code_option', deal.selectedDiscountValue);
                formData.append('discount_code', deal.discountCode);
                formData.append('discount_code_link_phone', deal.discountCodeLinkPhone);

                formData.append('title_color', deal.titleColor);
                formData.append('name_color', deal.nameColor);
                formData.append('description_color', deal.descriptionColor);
                formData.append('availability_color', deal.availabilityColor);

                FileUtils.encodeImageFileToBase64(deal.dealImage, true)
                    .then((base64) => {
                        if (base64) {
                            formData.append(`image[name]`, FileUtils.getImageFilename(deal.dealImage));
                            formData.append(`image[type]`, FileUtils.getImageType(deal.dealImage));
                            formData.append(`image[base64]`, base64);
                        }
                    })
                    .then(() => {
                        WebUtils.request(WebUtils.getApiPath(`/api/${dealFor}/${dealForId}/deals/${deal.id}`), 'post', formData, true)
                            .then((response) => {
                                context.commit('editHotelDeal', {
                                    deal: response.data.deal,
                                    hotelId,
                                });
                                resolve(response.data.deal);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    });

            });
        },
        deleteHotelDeal(context, payload) {
            const {
                hotelId,
                dealId,
            } = payload;

            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotelId}/deals/${dealId}`), 'delete', {}, true).then((response) => {
                    if (response.data.success) {
                        // context.commit('deleteHotelDeal', {
                        //     hotelId,
                        //     dealId,
                        // });
                    }
                    resolve(response.data.success);
                }).catch((error) => {
                    console.log('ERROR', `/api/hotel/${hotelId}/deal/${dealId}:delete`, error, error.response);
                    reject(error);
                });
            });
        },
        addHotelDealCarousel(context, payload) {
            const {
                hotel,
                deal,
            } = payload;
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotel.id}/deal/${deal.id}/carousels`), 'post', {}, true).then((response) => {
                    if (response.data.success) {
                        // context.commit('addHotelDealCarousel', {
                        //     hotelId: hotel.id,
                        //     dealId: deal.id,
                        // });
                    }
                    resolve(response.data.success);
                }).catch((error) => {
                    console.log('ERROR', `/api/hotel/${hotel.id}/deal/${deal.id}/carousels:post`, error, error.response);
                    reject(error);
                });
            });
        },
        removeHotelDealCarousel(context, payload) {
            const {
                hotel,
                deal,
            } = payload;
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotel.id}/deal/${deal.id}/carousels`), 'delete', {}, true).then((response) => {
                    if (response.data.success) {
                        // context.commit('removeHotelDealCarousel', {
                        //     hotelId: hotel.id,
                        //     dealId: deal.id,
                        // });
                    }
                    resolve(response.data.success);
                }).catch((error) => {
                    console.log('ERROR', `/api/hotel/${hotel.id}/deal/${deal.id}/carousels:delete`, error);
                    reject(error);
                });
            });
        },
        loadHotelOutlet(context, payload) {
            const {
                hotelId,
                outletId,
            } = payload;

            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotelId}/outlets/${outletId}`), 'get', null, true)
                    .then((response) => {
                        context.commit('loadHotelOutlet', {
                            hotelId,
                            outletId,
                            outlet: response.data,
                        });

                        resolve(response.data);
                    })
                    .catch((error) => {
                        console.log('/api/hotel/:hotelId/outlets/:outletId error', error);
                        reject(error);
                    });
            });
        },
        loadHotelOutletDeals(context, payload) {
            const {
                hotelId,
                outletId,
                page = 1,
            } = payload;
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotelId}/outlet/${outletId}/deals?all&page=${page}`), 'get', null, true)
                    .then((response) => {
                        console.log("loadHotelOutletDeals", response.data);

                        context.commit('loadHotelOutletDeals', {
                            hotelId, 
                            outletId,
                            deals: response.data.outlet_deals,
                            pagination: response.data.meta.pagination,
                        });

                        resolve(
                            context.getters.getHotelOutletDeals
                        );
                    })
                    .catch((error) => {
                        console.log('/api/hotel/:hotelId/outlet/:outletId/deals error', error, error.response);
                        reject(error);
                    });
            });
        },
        addHotelOutletDeal(context, payload) {
            const {
                hotel,
                outlet,
                deal,
                selected_discount_value,
                discount_code,
                discount_code_link_phone
            } = payload;
            return new Promise((resolve, reject) => {
                let formData = new FormData();

                formData.append('date[start]', deal.date.start);
                formData.append('date[end]', deal.date.end);

                formData.append('discount[amount]', deal.discount.amount);

                formData.append('discount_code_option', selected_discount_value);
                formData.append('discount_code', discount_code);
                formData.append('discount_code_link_phone', discount_code_link_phone);

                formData.append('title_color', deal.title_color);
                formData.append('name_color', deal.name_color);
                formData.append('description_color', deal.description_color);
                formData.append('availability_color', deal.availability_color);

                formData.append('image[file]', deal.image);
                formData.append(`image[name]`, FileUtils.getImageFilename(deal.image));
                formData.append(`image[type]`, FileUtils.getImageType(deal.image));

                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotel.id}/outlet/${outlet.id}/deals`), 'post', formData, true)
                .then((response) => {
                    console.log("/api/hotel/:hotelId/outlet/:outletId/deals:add", response.data);
                    // context.commit('addHotelOutletDeal', {
                    //     deal: response.data.deal,
                    //     hotelId: hotel.id,
                    //     outletId: outlet.id,
                    // });
                    resolve(response.data.deal);
                })
                .catch((error) => {
                    reject(error);
                });
                
            });
        },
        editHotelOutletDeal(context, payload) {
            const {
                hotelId,
                outletId,
                deal,
            } = payload;
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append('_method', 'PATCH');
                formData.append('name', deal.name);
                formData.append('description', deal.description);

                formData.append('date[id]', deal.date.id);
                formData.append('date[start]', deal.date.start);
                formData.append('date[end]', deal.date.end);

                formData.append('discount[amount]', deal.discount.amount);
                formData.append('discount[name]', deal.discount.name);

                formData.append('title_color', deal.title_color);
                formData.append('name_color', deal.name_color);
                formData.append('description_color', deal.description_color);
                formData.append('availability_color', deal.availability_color);

                FileUtils.encodeImageFileToBase64(deal.image, true)
                    .then((base64) => {
                        if (base64) {
                            formData.append(`image[name]`, FileUtils.getImageFilename(deal.image));
                            formData.append(`image[type]`, FileUtils.getImageType(deal.image));
                            formData.append(`image[base64]`, base64);
                        }
                    })
                    .then(() => {
                        WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotelId}/outlet/${outletId}/deals/${deal.deal_id}`), 'post', formData, true)
                            .then((response) => {
                                context.commit('editHotelOutletDeal', {
                                    deal: response.data.deal,
                                    hotelId,
                                    outletId,
                                });
                                resolve(response.data.deal);
                            })
                            .catch((error) => {
                                console.log(`/api/hotel/${hotelId}/outlet/${outletId}/deals/${deal.deal_id}:error error`, error.response.data);
                                reject(error);
                            });
                    });

            });
        },
        deleteHotelOutletDeal(context, payload) {
            const {
                hotelId,
                outletId,
                dealId,
                hotelOutletDealId,
            } = payload;

            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotelId}/outlet/${outletId}/deals/${dealId}`), 'delete', {}, true).then((response) => {
                    console.log(`/api/hotel/${hotelId}/outlet/${outletId}/deals/${dealId}`, response.data);
                    if (response.data.success) {
                        // context.commit('deleteHotelOutletDeal', {
                        //     hotelId,
                        //     outletId,
                        //     dealId,
                        //     hotelOutletDealId,
                        // });
                    }
                    resolve(response.data.success);
                }).catch((error) => {
                    console.log('ERROR', `/api/hotel/${hotelId}/outlet/${outletId}/deals/${dealId}:delete`, error, error.response);
                    reject(error);
                });
            });
        },
        addHotelOutletDealCarousel(context, payload) {
            const {
                hotel,
                outlet,
                deal,
            } = payload;
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotel.id}/outlet/${outlet.id}/deal/${deal.deal_id}/carousels`), 'post', {}, true).then((response) => {
                    console.log("addHotelOutletDealCarousel", response.data);
                    if (response.data.success) {
                        // context.commit('addHotelOutletDealCarousel', {
                        //     hotelId: hotel.id,
                        //     outletId: outlet.id,
                        //     dealId: deal.id,
                        // });
                    }
                    resolve(response.data.success);
                }).catch((error) => {
                    console.log('ERROR', `/api/hotel/${hotel.id}/outlet/${outlet.id}/deal/${deal.deal_id}/carousels:post`, error);
                    reject(error);
                });
            });
        },
        removeHotelOutletDealCarousel(context, payload) {
            const {
                hotel,
                outlet,
                deal,
            } = payload;
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/hotel/${hotel.id}/outlet/${outlet.id}/deal/${deal.deal_id}/carousels`), 'delete', {}, true).then((response) => {
                    if (response.data.success) {
                        // context.commit('removeHotelOutletDealCarousel', {
                        //     hotelId: hotel.id,
                        //     outletId: outlet.id,
                        //     dealId: deal.id,
                        // });
                    }
                    resolve(response.data.success);
                }).catch((error) => {
                    console.log('ERROR', `/api/hotel/${hotel.id}/outlet/${outlet.id}/deal/${deal.deal_id}/carousels:delete`, error);
                    reject(error);
                });
            });
        },
        loadCustomers(context, payload) {
            const {
                page
            } = payload;
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/customers?page=${page}`), 'GET', {}, true).then((response) => {
                    if (response.status == 200) {
                        context.commit('loadCustomers', {
                            customers: response.data.customer,
                            pagination: response.data.meta.pagination,
                        });
                    }
                    resolve();
                }).catch((error) => {
                    console.log('ERROR', "/api/customers", error.response.data);
                    reject(error);
                });
            });
        },
        loadCustomer(context, customerId) {
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/customers/${customerId}`), 'get', null, true)
                .then((response) => {
                    const customer = response.data.data;
                    context.commit('loadCustomer', customer);
                    resolve(customer);
                })
                .catch((error) => {
                    console.log('/api/customers/:customerId', error, error.response.data);
                    reject(error);
                });
            });
        },
        loadCustomerClaimPointsHistory(context, payload) {
            const {
                customerId,
                page,
            } = payload;
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/customer/${customerId}/history/claim?page=${page}`), 'get', null, true)
                .then((response) => {
                    const historyRaw = response.data.customer_claim_history;
                    const history = {};
                    historyRaw.forEach((h) => {
                        history[h.id] = h;
                    });
                    const pagination = response.data.meta.pagination;
                    const data = {
                        history,
                        pagination,
                    };
                    resolve(data);
                })
                .catch((error) => {
                    console.log('/api/customers/:customerId/history/claim', error, error.response.data);
                    reject(error);
                });
            });
        },
        loadCustomerRedeemPointsHistory(context, payload) {
            const {
                customerId,
                page,
            } = payload;
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/customer/${customerId}/history/redeem?page=${page}`), 'get', null, true)
                .then((response) => {
                    const historyRaw = response.data.customer_redeem_history;
                    const history = {};
                    historyRaw.forEach((h) => {
                        history[h.id] = h;
                    });
                    const pagination = response.data.meta.pagination;
                    const data = {
                        history,
                        pagination,
                    };
                    resolve(data);
                })
                .catch((error) => {
                    console.log('/api/customers/:customerId/history/claim', error, error.response.data);
                    reject(error);
                });
            });
        },
        loadCustomerPoints(context, cutomerId) {

        },
        addCustomerPoints(context, cutomerId, points) {

        },
        setCustomerPoints(context, customerId, points) {
            
        },
        loadHotelsReports(context, payload) {
            let params = Object.keys(payload).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(payload[key])).join('&');

            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/reports/hotels?${params}`), 'get', null, true)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(reject);
            });
        },
        loadCustomersReports(context, payload) {
            let params = Object.keys(payload).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(payload[key])).join('&');

            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/reports/customers?${params}`), 'get', null, true)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(reject);
            });
        },
        loadDashboard(context) {
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath('/api/dashboard', 'get', null, true))
                .then((response) => {
                    const dashboard = response.data;
                    context.commit('loadDashboard', dashboard);
                    resolve();
                })
                .catch(reject);
            });
        },
        loadPages(context, payload) {
            const {
                page
            } = payload;
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/pages?page=${page}`), 'GET', {}, false).then((response) => {
                    if (response.status == 200) {
                        context.commit('loadPages', {
                            pages: response.data.pages,
                            pagination: response.data.meta.pagination,
                        });
                    }
                    resolve();
                }).catch((error) => {
                    console.log('ERROR', "/api/pages", error);
                    reject(error);
                });
            });
        },
        loadPage(context, pageId) {
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/pages/${pageId}`), 'get', null, true)
                .then((response) => {
                    const page = response.data;
                    context.commit('loadPage', page);
                    resolve(page);
                })
                .catch((error) => {
                    console.log('/api/page/:pageId', error, error.response.data);
                    reject(error);
                });
            });
        },
        addPage(context, page) {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append('endpoint', page.endpoint);
                formData.append('title', page.title);
                formData.append('contents', page.contents);

                WebUtils.request(WebUtils.getApiPath('/api/pages'), 'post', formData, true)
                .then((response) => {
                    context.commit('loadPage', response.data.page);
                    resolve(response.data.page);
                })
                .catch(reject);
            });
        },
        editPage(context, page) {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append('_method', 'PATCH');
                formData.append('endpoint', page.endpoint);
                formData.append('title', page.title);
                formData.append('contents', page.contents);

                WebUtils.request(WebUtils.getApiPath(`/api/pages/${page.id}`), 'post', formData, true)
                .then((response) => {
                    context.commit('loadPage', response.data.page);
                    resolve(response.data.page);
                })
                .catch(reject);
            });
        },
        removePage(context, pageId) {
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/pages/${pageId}`), 'delete', null, true)
                .then((response) => {
                    context.commit('removePage', pageId);
                    resolve(response.data.success);
                })
                .catch((error) => {
                    console.log('/api/pages/:pageId/delete', error);
                    reject(error);
                });
            });
        },

        loadSetting(context, settingId) {
            return new Promise((resolve, reject) => {
                WebUtils.request(WebUtils.getApiPath(`/api/settings/1`), 'get', null, true)
                .then((response) => {
                    const setting = response.data;
                    context.commit('loadHotel', setting);
                    resolve(setting);
                })
                .catch((error) => {
                    console.log('/api/settings/:hotelId', error);
                    reject(error);
                });
            });
        },

        editSetting(context, data) {
            const {
                setting,
                removedImages,
                removedLogoImage,
                appColor,
                selectedLanguage,
                pointsExpiryMonth,
            } = data;
            return new Promise((resolve, reject) => {
                try {
                    console.info('setting_datax', removedLogoImage)
                    const formData = new FormData();
                    formData.append('_method', 'PATCH');

                    removedImages.forEach((imageId, index) => {
                        formData.append(`removed_images[${index}]`, imageId);
                    });

                    removedLogoImage.forEach((imageId, index) => {
                        formData.append(`removed_logo_image[${index}]`, imageId);
                    });
    
                    Object.values(setting.images.data).forEach(async (imageFile, index) => {
                        if (imageFile instanceof File) {
                            formData.append(`images[${index}][name]`, FileUtils.getImageFilename(imageFile));
                            formData.append(`images[${index}][type]`, FileUtils.getImageType(imageFile));
                            formData.append(`images[${index}][file]`, imageFile);
                        }
                    });

                    Object.values(setting.logoImage.data).forEach(async (imageFile, index) => {
                        if (imageFile instanceof File) {
                            formData.append(`logoImage[${index}][name]`, FileUtils.getImageFilename(imageFile));
                            formData.append(`logoImage[${index}][type]`, FileUtils.getImageType(imageFile));
                            formData.append(`logoImage[${index}][file]`, imageFile);
                        }
                    });

                    formData.append('app_color', appColor)
                    formData.append('default_lang_iso_code', selectedLanguage)
                    formData.append('points_expiry_month', pointsExpiryMonth)

                    for (var pair of formData.entries())
                    {
                        console.log(pair) 
                    }

                    WebUtils.request(WebUtils.getApiPath(`/api/settings/1`), 'post', formData, true)
                        .then((response) => {
                            console.info('settings_response', response.data)
                            context.commit('loadSetting', response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                console.log("ERROR RESPONSE", error.response);
                            }
                            reject(error);
                        });
                }
                catch(ex) {
                    reject(ex);
                }
            });
        },

    },
});

export default store;