<template>
    <modal :name="modalName" classes="v--modal my-4" height="auto" :scrollable="true" :clickToClose="false" @before-open="beforeOpen">
        <div class="col-sm-12 py-4" ref="content">
            <a class="link float-right" @click="close"> <i class="fas fa-times-circle"></i> </a>
            <h3>{{title}}</h3>
            <div class="row">
                <div class="col-sm-12">
                    <form ref="form" @submit="submit">
                        <fieldset>
                            <div class="form-group required">
                                <label for="name">Name:</label>
                                <input type="text" id="name" class="form-control" v-model="deal.name"/>
                            </div>
                            <div class="form-group">
                                <label for="name">Discount Name:</label>
                                <input type="text" id="discount_name" class="form-control" v-model="deal.discount.name"/>
                            </div>
                            <div class="form-group">
                                <label for="name">Discount Amount:</label>
                                <input type="text" id="discount_amount" class="form-control" v-model="deal.discount.amount"/>
                            </div>
                            <div class="form-group">
                                <label for="description">Description:</label>
                                <textarea id="description" class="form-control" v-model="deal.description"/>
                            </div>
                            <div class="form-group">
                                <label for="selected_discount_code">Discount Code Option:</label>
                                <select id="selected_discount_code" v-model="selected_discount_code" class="form-control" @change="(e) => onDiscountCodeOptionChange(e)">
                                    <option :value="selected_discount_code" disabled hidden>{{selected_discount_code}}</option>
                                    <option v-for="item in discount_code_select_option" :value="item.name" :key="item.id">{{item.name}}</option>
                                </select>
                            </div>
                            <div v-if="selected_discount_code !== 'none'" class="form-group">
                                <label for="discount_code">Discount Code:</label>
                                <input id="discount_code" class="form-control" v-model="discount_code"/>
                            </div>
                            <div v-if="selected_discount_code !== 'none'" class="form-group">
                                <label for="discount_code_link_phone">{{discount_code_link_phone_label}}</label>
                                <input id="discount_code_link_phone" class="form-control" v-model="discount_code_link_phone"/>
                            </div>

                            
                            <div class="row mb-3">
                                <div class="col-sm-12 required">
                                    <label>Date:</label>
                                </div>
                                <div class="col-sm-6 required">
                                    <label for="date_start">Start</label>
                                    <date-picker format="YYYY-MM-DD" valueType="format" :disabled-date="disabledDateStart" v-model="deal.date.start" :input-attr="{ required: true }"></date-picker>
                                </div>
                                <div class="col-sm-6 required">
                                    <label for="date_end">End</label>
                                    <date-picker format="YYYY-MM-DD" valueType="format" :disabled-date="disabledDateEnd" v-model="deal.date.end" :input-attr="{ required: true }"></date-picker>
                                </div>
                            </div>

                            <div class="form-group required">
                                <label for="image">Image:</label>
                                <input type="file" id="image" class="form-control d-none" accept="image/*" ref="imageSelector" @change="processSelected"/>
                                <button type="button" class="btn btn-secondary" @click="selectImage">Select Image</button>
                                <small class="form-text text-muted">Minimum image size is 640 width and 260 height with ideal content ratio is 5:2.</small>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 offset-md-3 text-center my-3" >
                                        <img :src="image" width="100%"/>
                                    </div>
                                </div>
                            </div>

                            <template v-if="image">
                            <div class="form-row mb-2">
                                <div class="col-sm-4">
                                    <label for="name_color">Name Text Color:</label>
                                </div>
                                <div class="col-sm-8">
                                    <input type="color" id="name_color" class="form-control" v-model="deal.name_color"/>
                                </div>
                            </div>

                            <div class="form-row mb-2">
                                <div class="col-sm-4">
                                    <label for="title_color">Discount Text Color:</label>
                                </div>
                                <div class="col-sm-8">
                                    <input type="color" id="title_color" class="form-control" v-model="deal.title_color"/>
                                </div>
                            </div>

                            <div class="form-row mb-2">
                                <div class="col-sm-4">
                                    <label for="description_color">Description Text Color:</label>
                                </div>
                                <div class="col-sm-8">
                                    <input type="color" id="description_color" class="form-control" v-model="deal.description_color"/>
                                </div>
                            </div>

                            <div class="form-row mb-2">
                                <div class="col-sm-4">
                                    <label for="availability_color">Availability Text Color:</label>
                                </div>
                                <div class="col-sm-8">
                                    <input type="color" id="availability_color" class="form-control" v-model="deal.availability_color"/>
                                </div>
                            </div>
                            </template>
                        </fieldset>
                    </form>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <button type="button" class="btn btn-primary" @click="triggerSubmit">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Vue from "vue";
import DatePicker from 'vue2-datepicker';
import moment from "moment";

export default {
    components: {
        DatePicker,
    },

    props: {
        hotel: {
            required: true,
        }
    },

    data() {
        return {
            title: 'Add Deal',
            modalName: 'deal-add-form',
            discount_code_select_option: [
                    {
                        id: 1,
                        value: 'none',
                        name: 'none'
                    },
                    {
                        id: 2,
                        value: 'url',
                        name: 'Booking url',
                    },
                    {
                        id: 3,
                        value: 'phone_num',
                        name: 'Booking Phone Number',
                    },
            ],

            deal: {
                name: '',
                description: '',
                date: {
                    start: null,
                    end: null,
                },
                discount: {
                    amount: "",
                    name: "",
                },
                image: null,
                title_color: '#FF5F58',
                name_color: '#FFFFFF',
                description_color: '#FFFFFF',
                availability_color: '#FFFFFF',
                
            },

            selected_discount_code: 'none',
            selected_discount_value: 'none',
            discount_code: '',
            discount_code_link_phone_label: '',
            discount_code_link_phone: '',
        
        };
    },

    mounted() {
    },

    methods: {
        
        onDiscountCodeOptionChange(e){
            this.selected_discount_value = this.discount_code_select_option.find(item => item.name === e.target.value).value

            if(e.target.value !== 'none'){
                this.discount_code_link_phone_label = e.target.value
            }else{
                this.discount_code = ''
                this.discount_code_link_phone = ''
            }

        },

        selectImage() {
            this.$refs.imageSelector.click();
        },
        processSelected(evt) {
            Vue.set(this.deal, "image", Object.values(evt.target.files)[0]);
        },
        triggerSubmit() {

            if(this.image == null){
                return
            }

            if(this.deal.name === ''){
                return
            }


            if (this.$refs.form.reportValidity()) {
                this.submit();
            }
        },
        submit(evt) {
            console.log("default submit")
        },
        close() {
            this.$modal.hide(this.modalName);
        },
        beforeOpen(event) {
            this.clear();
        },
        clear() {
            this.deal = {
                name: '',
                description: '',
                date: {
                    start: null,
                    end: null,
                },
                discount: {
                    amount: "",
                    name: "",
                },
                image: null,
                title_color: '#FF5F58',
                name_color: '#FFFFFF',
                description_color: '#FFFFFF',
                availability_color: '#FFFFFF',
            };

            this.selected_discount_code = 'none'
            this.discount_code = ''
            this.discount_code_link_phone_label =  ''
            this.discount_code_link_phone = ''

        },
        disabledDateStart(date) {
            const dateMoment = moment(date);
            const nowMoment = moment().startOf('day');
            var retVal = false;
            if (this.deal.date.end) {
                const endMoment = moment(this.deal.date.end);
                retVal = !(dateMoment.isSameOrAfter(nowMoment) && dateMoment.isSameOrBefore(endMoment));
            }
            else {
                retVal = dateMoment.isBefore(nowMoment);
            }
            return retVal;
        },
        disabledDateEnd(date) {
            const dateMoment = moment(date);
            const nowMoment = moment().startOf('day');
            var retVal = false;
            if (this.deal.date.start) {
                const startMoment = moment(this.deal.date.start);
                retVal = !dateMoment.isSameOrAfter(startMoment);
            }
            else {
                retVal = dateMoment.isBefore(nowMoment);
            }
            return retVal;
        }
    },
    computed: {
        image() {
            try {
                return URL.createObjectURL(this.deal.image);
            }
            catch (ex) {}

            return null;
        },
    }
}
</script>