<template>
    <div class="col-sm-12">
        <h2>{{pageHeader}}</h2>
        <form @submit="submit">
            <div class="form-group" :class="{required: isCreateMode}">
                <label for="endpoint">Endpoint:</label>
                <input type="text" required id="endpoint" class="form-control" @keypress="processEndpoint" v-model="page.endpoint"/>
            </div>
            <div class="form-group required">
                <label for="title">Title:</label>
                <input type="text" required id="title" class="form-control" v-model="page.title"/>
            </div>
            <div class="form-group required">
                <label for="contents">Contents:</label>
                <VueEditor required id="contents" v-model="page.contents"></VueEditor>
            </div>

            <button type="submit" class="button">Submit</button>
        </form>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";

export default {
    components: {
        VueEditor,
    },

    data() {
        return {
            page: {},
            mode: 'create',
            pageHeader: "Add new Page",
        };
    },

    mounted() {
        const routeName = this.$route.name;

        if (routeName == "page_edit") {
            this.mode = "edit";
            this.pageHeader = "Edit Page";
            
            let page = this.getPage;

            if (page == null) {
                const loader = this.$loading.show({
                    container: this.$el,
                    canCancel: false,
                });
                
                this.$store.dispatch('loadPage', this.$route.params.pageId)
                .then((page) => {
                    this.page = {...page};
                })
                .finally(() => {
                    loader.hide();
                });
            }
            else {
                this.page = {...page};
            }
        }
    },

    methods: {
        processEndpoint(evt) {
            if (!evt.key.match(/^[a-z0-9-_]$/)) {
                if (evt.key.match(/^[A-Z]$/)) {
                    this.page.endpoint += evt.key.toLowerCase();
                }
                evt.preventDefault();
                return false;
            }
        },
        submit(evt) {
            var action = 'addPage';
            if (!this.isCreateMode) {
                action = 'editPage';
            }

            const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            this.$store.dispatch(action, this.page)
                .then((page) => {
                    console.log('success', action, page);
                    this.$router.push({ name: 'page_view', params: {
                        pageId: page.id
                    }});
                })
                .catch((error) => {
                    console.log('failed', action, error, error.response);
                })
                .finally(() => {
                    loader.hide();
                });

            evt.preventDefault();
            return false;
        },
    },

    computed: {
        ...mapGetters(['getPage']),
        isCreateMode() {
            return this.mode == "create";
        },
    },

}
</script>