<template>
  <div class="col-sm-12 py-4" ref="content">
      <h2>Edit Deal</h2>
    <div style="margin-top: 10px" class="form-group">
      <label for="selectedLanguage" class="has-text-weight-bold">Default language:</label>
      <input type="text" class="form-control"  readonly v-model="defaultLanguage"/>
     </div>
     <div style="margin-top: 10px" class="card">
    <header class="card-header">
      <p class="card-header-title">
      Multi Language
      </p>
      <button v-if="!viewMode" type="button" class="button pull-right"  @click="onAddNewLangModalClick">Support new language</button>
    </header>
    <div class="card-content">
      <div class="content">
       <b-table
      :data="supporttedMultiLangs"
      :paginated="false"
      per-page="10"
      current-page.sync="1"
      :pagination-simple="false"
      pagination-position="bottom"
      default-sort-direction="asc"
      sort-icon="chevron-up"
      sort-icon-size="is-small"
      :striped="true"
      :hoverable="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :mobile-cards="false"
    >
    <b-table-column
      field="lang_description"
      label="Language"
      sortable
      v-slot="props"
    >
      {{ props.row.lang_description }}
    </b-table-column>

      <b-table-column
      field="name"
      label="Name"
      sortable
      v-slot="props"
    >
      {{ props.row.name }}
    </b-table-column>

      <b-table-column
      field="discount_title"
      label="Discount Name"
      sortable
      v-slot="props"
    >
      {{ props.row.discount_title }}
    </b-table-column>


    <b-table-column
      field="description"
      label="Description"
      sortable
      v-slot="props"
    >
      {{ props.row.description }}
    </b-table-column>

    <b-table-column :visible="!viewMode" centered label="Actions"  v-slot="props">
      <div class="buttons is-centered is-inline-block">

       <div class="row"> 

       <!-- Edit icon -->
      <button class="button is-shadowless" @click="editModalItem(props.row)">
        <span class="icon">
        <i class="fas fa-edit has-text-primary"></i>
        </span>
      </button>

       <!-- Delete icon -->
       <div v-if="defaultLanguage !== props.row.lang_description">
        <button class="button is-shadowless" @click="deleteModalItem(props.row)">
        <span class="icon">
        <i class="fas fa-trash has-text-danger"></i>
        </span>
      </button>
       </div>

       </div>

      </div>
    </b-table-column>

  </b-table>
      </div>
    </div>
    </div>

      <div class="row">
        <div class="col-sm-12">
          <form ref="form" @submit="submit">
            <fieldset>
              <div class="form-group">
                <label for="name">Discount Amount:</label>
                <input type="text" id="discount_amount" class="form-control" v-model="discountAmount" :readonly="viewMode"/>
              </div>
              <div class="form-group">
                <label for="selectedDiscountCode">Discount Code Option:</label>
                <select id="selectedDiscountCode" v-model="selectedDiscountCode" :disabled="viewMode" class="form-control" @change="(e) => onDiscountCodeOptionChange(e)">
                  <option :value="selectedDiscountCode" disabled>{{selectedDiscountCode}}</option>
                  <option v-for="item in discountCodeSelectOption" :value="item.name" :key="item.id">{{item.name}}</option>
                </select>
              </div>
              <div v-if="selectedDiscountCode !== 'none'" class="form-group">
                <label for="discountCode">Discount Code:</label>
                <input id="discountCode" class="form-control" v-model="discountCode"/>
              </div>
              <div v-if="selectedDiscountCode !== 'none'" class="form-group">
                <label for="discountCodeLinkPhone">{{discountCodeLinkPhoneLabel}}</label>
                <input id="discountCodeLinkPhone" class="form-control" v-model="discountCodeLinkPhone"/>
              </div>

              
              <div class="row mb-3">
                <div class="col-sm-12 required">
                  <label>Date:</label>
                </div>
                <div class="col-sm-6 required">
                  <label for="date_start">Start</label>
                  <date-picker format="YYYY-MM-DD" valueType="format" :disabled-date="disabledDateStart" v-model="date.start" :input-attr="{ required: true }"></date-picker>
                </div>
                <div class="col-sm-6 required">
                  <label for="date_end">End</label>
                  <date-picker format="YYYY-MM-DD" valueType="format" :disabled-date="disabledDateEnd" v-model="date.end" :input-attr="{ required: true }"></date-picker>
                </div>
              </div>

              <div class="form-group">
                <label for="image">Image:</label>
                <input type="file" id="image" class="form-control d-none" accept="image/*" ref="imageSelector" @change="processSelected"/>
                <button v-if="!viewMode" type="button" class="btn btn-secondary" @click="selectImage">Select Image</button>
                <small v-if="!viewMode" class="form-text text-muted">Minimum image size is 640 width and 260 height with ideal content ratio is 5:2.</small>
                <div class="row">
                  <div class="col-sm-12 col-md-6 offset-md-3 text-center my-3" >
                    <img :src="image" width="100%"/>
                  </div>
                </div>
              </div>

              <template v-if="image">
              <div class="form-row mb-2">
                <div class="col-sm-4">
                  <label for="nameColor">Name Text Color:</label>
                </div>
                <div class="col-sm-8">
                  <input :disabled="viewMode" type="color" id="nameColor" class="form-control" v-model="nameColor"/>
                </div>
              </div>

              <div class="form-row mb-2">
                <div class="col-sm-4">
                  <label for="titleColor">Discount Text Color:</label>
                </div>
                <div class="col-sm-8">
                  <input :disabled="viewMode" type="color" id="titleColor" class="form-control" v-model="titleColor"/>
                </div>
              </div>

              <div class="form-row mb-2">
                <div class="col-sm-4">
                  <label for="descriptionColor">Description Text Color:</label>
                </div>
                <div class="col-sm-8">
                  <input :disabled="viewMode" type="color" id="descriptionColor" class="form-control" v-model="descriptionColor"/>
                </div>
              </div>

              <div class="form-row mb-2">
                <div class="col-sm-4">
                  <label for="availabilityColor">Availability Text Color:</label>
                </div>
                <div class="col-sm-8">
                  <input :disabled="viewMode" type="color" id="availabilityColor" class="form-control" v-model="availabilityColor"/>
                </div>
              </div>
              </template>
            </fieldset>
          </form>

          <!-- Modal -->
          <div>
            <b-modal  ref="newLangModal" 
              id="addNewLangModal" 
              hide-backdrop content-class="shadow" 
              title="Support new language"
              @ok="saveModalItem">
              
              <div v-if="isMSelectedLanguageVisible" class="form-group required">
              <label for="name">Language:</label>
                <select class="form-control required" v-model="mSelectedLanguage" @change="onMSelectedLanguageChange">
                <option :value="mSelectedLanguage" disabled >{{ mSelectedLanguage }}</option>
                <option v-for="(item, key) in filteredMultiLangItems" :value="item.description" :key="key">{{item.description}} </option>
                </select>
              </div>
               <div class="form-group required">
                <label for="name">Name:</label>
                <input type="text" id="name" class="form-control" v-model="mName"/>
              </div>
              <div class="form-group">
                <label for="name">Discount Name:</label>
                <input type="text" id="discount_name" class="form-control" v-model="mDiscountName"/>
              </div>
              <div class="form-group">
              <label for="description">Description:</label>
              <textarea required class="form-control" v-model="mDescription"/>
              </div>
            </b-modal>
          </div>
          
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <button v-if="!viewMode" type="button" class="btn btn-primary" @click="triggerSubmit">
            Save
          </button>
        </div>
      </div>
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import { mapGetters } from "vuex";
import axios from "axios"
import OutletSchedule from "../components/OutletSchedule";
import RemovableImage from "../components/RemovableImage";
import moment from "moment";
import Vue from "vue";

export default {
  components: {
    OutletSchedule,
    RemovableImage,
    DatePicker,
  },

  data() {
  return {
    removedSchedules: [],
    schedules: [],

    dealForId: '',
    dealFor: '',
    dealForUrl: '',

    discountAmount: '',
    mName: '',
    mDiscountName: '',
    mDescription: '',
    date: {
      start: null,
      end: null,
    },
    discount: {
      amount: "",
      name: "",
    },

    dealImage: null,
    dealImageUrl: null,

    titleColor: '#FF5F58',
    nameColor: '#FFFFFF',
    descriptionColor: '#FFFFFF',
    availabilityColor: '#FFFFFF',
    selectedDiscountCode: 'none',
    selectedDiscountValue: 'none',
    discountCode: '',
    discountCodeLinkPhoneLabel: '',
    discountCodeLinkPhone: '',

    discountCodeSelectOption: [
      {
        id: 1,
        value: 'none',
        name: 'none'
      },
      {
        id: 2,
        value: 'url',
        name: 'Booking url',
      },
      {
        id: 3,
        value: 'phone_num',
        name: 'Booking Phone Number',
      },
    ],




    hotelCodeId: '',
    
    removedImages: [],
    selectedCurrency: 'Choose Option',
    hotelsCurrency: [],

    defaultLanguage: '',
    supportedLanguages: [],

    mItemId: '',
    mLangIsoCode: '',
    mSelectedLanguage: '',
    

    isEditModalItem: false,
    isMSelectedLanguageVisible: true,

    supporttedMultiLangs: [],
    tempSupportedLanguages: [],

    viewMode: false,

  };
  },

   computed: {
  ...mapGetters(['getHotel',]),

   image() {
            try {
                if (this.dealImageUrl) {
                    return this.dealImageUrl;
                }else{
                   return URL.createObjectURL(this.dealImage);
                }
            }
            catch (ex) {
                console.log("no image url");
            }
            return null;
        },

  filteredMultiLangItems(){
     _.each(this.supporttedMultiLangs, (item) => {
      _.remove(this.tempSupportedLanguages, (n) => {
      return n.iso_code === item.iso_code
      })
    })

    return this.tempSupportedLanguages
  }

  },

  methods: {

  clear(){
    this.name = ''
    this.description = ''
    this.images = []

    this.selectedDiscountCode = 'none'
    this.discountCode = ''
    this.discountCodeLinkPhoneLabel =  ''
    this.discountCodeLinkPhone = ''
  },

  clearModalItem(){
    this.isMSelectedLanguageVisible = true
    this.mSelectedLanguage = 'Choose Option'
    this.mItemId = ''
    this.mLangIsoCode = ''
    this.mName = ''
    this.mDescription = ''
    this.mDiscountName = ''
  },

  submit(evt) {
      const loader = this.$loading.show({
        container: this.$refs.content,
        canCancel: false,
      });

      this.$store.dispatch('editHotelDeal', {
        dealForId: this.dealForId,
        dealFor: this.dealFor,
        dealForUrl: this.dealForUrl,
        deal: {
          id: this.$route.params.dealId,
          date: this.date,
          discountAmount: this.discountAmount,
          selectedDiscountValue: this.selectedDiscountValue,
          discountCode: this.discountCode,
          discountCodeLinkPhone: this.discountCodeLinkPhone,
          titleColor: this.titleColor,
          nameColor: this.nameColor,
          descriptionColor: this.descriptionColor,
          availabilityColor: this.availabilityColor,
          dealImage: this.dealImage,
        },
      })
        .then((deal) => {
          this.$swal.fire(
          'Saved!',
          'Changes successfully updated',
          'success'
          )
        })
        .catch((error) => {
          console.log("/api/hotel/:hotelId/deals:edit error", error, error.response);
        })
        .finally(() => {
          loader.hide();
        });

      if (evt) {
        evt.preventDefault();
        return false;
      }
    },
    
  onDiscountCodeOptionChange(e){
      this.selectedDiscountValue = this.discountCodeSelectOption.find(item => item.name === e.target.value).value

      if(e.target.value !== 'none'){
        this.discountCodeLinkPhoneLabel = e.target.value
      }else{
        this.discountCode = ''
        this.discountCodeLinkPhone = ''
      }

    },

    selectImage() {
      this.$refs.imageSelector.click();
    },
    processSelected(evt) {
      this.dealImage = Object.values(evt.target.files)[0]

      if(this.dealImage){
       this.dealImageUrl = null
      }
    },
    triggerSubmit() {
      if (this.$refs.form.reportValidity()) {
        this.submit();
      }
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen(event) {
      this.clear();
      
            this.deal = {
                id,
                name,
                description,
                date: {...date},
                discount: {...discount},
                image: {...image},
                title_color,
                name_color,
                description_color,
                availability_color,
            };
            
            this.selected_discount_code = this.discount_code_select_option.find(item => item.value === discount_code_option).name
            this.discount_code = discount_code
            this.discount_code_link_phone = discount_code_link_phone

    },

    getDeal(){
      axios.get(this.dealForUrl)
        .then(response => {

          const {
            data
          } = response

          console.info(data)

          this.discountAmount = data.discount.amount
          this.selectedDiscountCode = this.discountCodeSelectOption.find(item => item.value === data.discount_code_option).name 
          this.selectedDiscountValue = data.discount_code_option
          this.discountCode = data.discount_code
          this.discountCodeLinkPhone = data.discount_code_link_phone
          this.date = data.date
          this.dealImageUrl = data.image.url

          this.titleColor = data.title_color
          this.nameColor = data.name_color
          this.descriptionColor = data.description_color
          this.availabilityColor = data.availability_color

        })
    },

    // clear() {
    //     this.deal = {
    //         name: '',
    //         description: '',
    //         date: {
    //             start: null,
    //             end: null,
    //         },
    //         discount: {
    //             amount: "",
    //             name: "",
    //         },
    //         image: null,
    //         titleColor: '#FF5F58',
    //         nameColor: '#FFFFFF',
    //         descriptionColor: '#FFFFFF',
    //         availabilityColor: '#FFFFFF',
    //     };

       

    // },

    disabledDateStart(date) {
      const dateMoment = moment(date);
      const nowMoment = moment().startOf('day');
      var retVal = false;
      if (this.date.end) {
        const endMoment = moment(this.date.end);
        retVal = !(dateMoment.isSameOrAfter(nowMoment) && dateMoment.isSameOrBefore(endMoment));
      }
      else {
        retVal = dateMoment.isBefore(nowMoment);
      }
      return retVal;
    },
    disabledDateEnd(date) {
      const dateMoment = moment(date);
      const nowMoment = moment().startOf('day');
      var retVal = false;
      if (this.date.start) {
        const startMoment = moment(this.date.start);
        retVal = !dateMoment.isSameOrAfter(startMoment);
      }
      else {
        retVal = dateMoment.isBefore(nowMoment);
      }
      return retVal;
    },


  getSupportedLanguages(){

    const loader = this.$loading.show({
        container: this.$el,
        canCancel: false,
    });

    axios.get(`${process.env.MIX_API_SERVER_PATH}/api/languages`)
    .then(response => {
    this.supportedLanguages = response.data.items
    this.tempSupportedLanguages = _.clone(this.supportedLanguages);

    this.getSettings()

    loader.hide()

    })
  },

  getSettings() {
    axios.get(`${process.env.MIX_API_SERVER_PATH}/api/settings/1`)
    .then(response => {
    if(response.data.default_lang_iso_code !== ''){
    this.defaultLanguage = this.supportedLanguages.find(item => item.iso_code === response.data.default_lang_iso_code).description
    this.langIsoCode = response.data.default_lang_iso_code
    }
    })
  },

  getSelectedLangHotel(item){
    axios.post(`${process.env.MIX_API_SERVER_PATH}/api/find-hotel-lang`, item)
    .then(response => {
    
    console.info(response.data)

    this.name = response.data.name
    this.description = response.data.description

     console.info('langxx', this.langIsoCode)

    })
  },
  
  getSupportedMultiLangs(){
    axios.get(`${process.env.MIX_API_SERVER_PATH}/api/deal-multi-langs`, {
    params: {
      deal_id: this.$route.params.dealId
    }
    })
    .then(response => {
    this.supporttedMultiLangs = response.data.items
    console.info('xx', response.data.items)

    })
  },

  onSelectedLanguageChange(e){
    this.clear()

    let lang = _.find(this.supportedLanguages, ['description', e.target.value]).iso_code

    this.langIsoCode = lang

    console.info(this.hotelCodeId)
    console.info('lang', lang)

    this.getSelectedLangHotel({
    hotel_code_id: this.hotelCodeId,
    lang_iso_code: lang,
    fall_back: 0,
    })
  },

  removeTempSupportedLangs(isoCode){
    this.tempSupportedLanguages.splice(this.supportedLanguages.find(items => items.iso_code === isoCode), 1)
  },

  addtempSupportedLangs(isoCode){
    let item = this.supportedLanguages.find(item => item.iso_code === isoCode)
    this.tempSupportedLanguages.push(item)
  },

  saveModalItem(e){

    if(this.mName.trim().length < 1){
      e.preventDefault()
      return
    }

    if(this.mSelectedLanguage.trim().length < 1 || this.mSelectedLanguage === 'Choose Option'){
      e.preventDefault()
      return
    }
    
    let item = {
      deal_id: this.$route.params.dealId,
      iso_code: this.supportedLanguages.find(item => item.description === this.mSelectedLanguage).iso_code,
      discount_title: this.mDiscountName,
      name: this.mName,
      description: this.mDescription,
    }

    if(!this.isEditModalItem){
    axios.post(`${process.env.MIX_API_SERVER_PATH}/api/deal-multi-langs`, item)
    .then((response) => {
      if(!response.data.success){
      alert('unable to save item')
      return
      }
      this.supporttedMultiLangs.push(response.data.item)
      
      this.removeTempSupportedLangs(item.iso_code)

      this.$swal.fire(
      'Saved!',
      'Language and contents added',
      'success'
      )
    })
    }else{
    axios.patch(`${process.env.MIX_API_SERVER_PATH}/api/deal-multi-langs/${this.mItemId}`, item)
    .then((response) => {
      if(!response.data.success){
       alert('unable to save item')
      return
      }
      let itemIndex = this.supporttedMultiLangs.findIndex(item => item.id === this.mItemId)
      this.supporttedMultiLangs.splice(itemIndex, 1, response.data.item)

       this.$swal.fire(
      'Updated!',
      'Language and contents updated',
      'success'
      )

    })
    }
  },

  onMSelectedLanguageChange(item){
    this.mSelectedLanguage = item.target.value
  },

  editModalItem(item){
    this.clearModalItem()

    this.isEditModalItem = true
    this.mSelectedLanguage = item.lang_description
    this.mItemId = item.id
    this.mLangIsoCode = item.lang_iso_code
    this.mName = item.name
    this.mDescription = item.description
    this.mDiscountName = item.discount_title

    if(this.defaultLanguage === item.lang_description){
    this.isMSelectedLanguageVisible = false
    }

    this.$refs["newLangModal"].show();
  },

  deleteModalItem(item){
    this.$swal.fire({
    title: 'Are you sure?',
    text: 'Supported language will be gone.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
    }).then((result) => {
    if (result.value) {
      axios.delete(`${process.env.MIX_API_SERVER_PATH}/api/deal-multi-langs/${item.id}`)
      .then((response) => {
        if(!response.data.success){
        alert('Something went wrong.')
        return
        }

        let itemIndex = this.supporttedMultiLangs.findIndex(items => items.id === item.id)

        this.supporttedMultiLangs.splice(itemIndex, 1)

        console.info('deleted', item)

        this.addtempSupportedLangs(item.iso_code)

        this.$swal.fire(
        'Deleted!',
        'Language and contents deleted',
        'success'
        )
      })
    }
    })
  },

  onAddNewLangModalClick(){
    this.clearModalItem()
    this.isEditModalItem = false
    this.$refs["newLangModal"].show();
  }


  },

  mounted() {

  console.info('query', this.$route.query.edit)

  if(this.$route.query.edit === 'hotel-deal' || this.$route.query.view === 'hotel-deal'){
    this.dealForId = this.$route.params.hotelId
    this.dealFor = 'hotel'
    this.dealForUrl = `${process.env.MIX_API_SERVER_PATH}/api/${this.dealFor}/${this.dealForId}/deals/${this.$route.params.dealId}`

    if(this.$route.query.view !== undefined){
      this.viewMode = true
    }

  }else{
    this.dealForId = this.$route.params.outletId
    this.dealFor = 'outlet'
    this.dealForUrl = `${process.env.MIX_API_SERVER_PATH}/api/hotel/${this.$route.params.hotelId}/${this.dealFor}/${this.dealForId}/deals/${this.$route.params.dealId}`

    if(this.$route.query.view !== undefined){
      this.viewMode = true
    }
  }

  console.info({
    deal_for_id: this.dealForId,
    deal_for: this.dealFor,
  })

  this.getSupportedLanguages()

  this.getSupportedMultiLangs()

  this.getDeal()

  },

}
</script>