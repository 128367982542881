<template>
    <div class="container">
        <template v-if="!reportsMode">
        <div class="row">
            <div v-if="showNavBar" class="col-sm-12">
                <NavBar></NavBar>
            </div>
        </div>
        <div class="row">
            <div class="d-sm-none d-lg-block col-lg-2" v-if="showSidebar">
                <Sidebar></sidebar>
            </div>
            <div class="col-sm-12" :class="{ 'col-lg-10': showSidebar, 'col-lg-12': !showSidebar }">
                <div class="row">
                    <router-view></router-view>
                </div>
            </div>
        </div>
        </template>
        <template v-else>
            <router-view></router-view>
        </template>
    </div>
</template>

<script>
import WebUtils from "./utils/WebUtils";
import NavBar from "./components/NavBar";
import Sidebar from "./components/Sidebar";
import 'vue2-datepicker/index.css';
import 'vue-swatches/dist/vue-swatches.css'

export default {
    components: {
        NavBar,
        Sidebar,
    },
    computed: {
         showNavBar() {
            try {
                if(this.$route.meta.NavBar === false){
                    return false
                }
                
                return true
            }
            catch (ex) {
                return true;
            }
        },
        showSidebar() {
            try {
                return this.$route.meta.sidebarEntries.length > 0;
            }
            catch (ex) {
                return false;
            }
        },
        reportsMode() {
            try {
                return this.$route.meta.reportsMode;
            }
            catch (ex) {
                return false;
            }
        },
    },
}
</script>

<style lang="scss">
    .no-borders {
        border: 0;
        border-top: 0;
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
    }
    .table.borderless {
        > tr {
            > td, > th {
                @extend .no-borders;
            }
        }

        > thead {
            > tr {
                > td, > th {
                    @extend .no-borders;
                }
            }
        }

        > tbody {
            > tr {
                > td, > th {
                    @extend .no-borders;
                }
            }
        }

        > tfoot {
            > tr {
                > td, > th {
                    @extend .no-borders;
                }
            }
        }
    }
</style>