<template>
    <div class="col-sm-12">
        <form :action="actionUrl" target="_blank">
            <fieldset>
                <div class="form-group">
                    <label for="sort">Sort:</label>
                    <select name="sort" class="form-control">
                        <option value="id">ID</option>
                        <option value="name">Name</option>
                        <option value="outlets">Number of outlets</option>
                        <option value="deals">Number of deals</option>
                        <option value="claimed">Points Claimed</option>
                        <option value="redeemed">Points Redeemed</option>
                    </select>
                    <select name="direction" class="form-control">
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Date Range:</label>
                    <date-picker format="YYYY-MM-DD" valueType="format" :disabled-date="disabledDateStart" v-model="date.start" :input-attr="{ name: 'date-start' }"></date-picker>
                    <date-picker format="YYYY-MM-DD" valueType="format" :disabled-date="disabledDateEnd" v-model="date.end" :input-attr="{ name: 'date-end' }"></date-picker>
                </div>

            </fieldset>
            <button type="submit" class="button primary">Submit</button>
        </form>
    </div>
</template>

<script>
import router from "../../Router";
import DatePicker from 'vue2-datepicker';
import moment from "moment";

export default {
    components: {
        DatePicker,
    },

    data() {
        return {
            date: {
                start: null,
                end: null,
            },
        };
    },

    methods: {
        disabledDateStart(date) {
            const dateMoment = moment(date);
            const nowMoment = moment().startOf('day');
            var retVal = false;
            if (this.date.end) {
                const endMoment = moment(this.date.end);
                retVal = dateMoment.isAfter(endMoment);
            }
            else {
                retVal = dateMoment.isAfter(nowMoment);
            }
            return retVal;
        },

        disabledDateEnd(date) {
            const dateMoment = moment(date);
            const nowMoment = moment().startOf('day');
            var retVal = false;
            if (this.date.start) {
                const startMoment = moment(this.date.start);
                retVal = dateMoment.isBefore(startMoment) || dateMoment.isAfter(nowMoment);
            }
            else {
                retVal = dateMoment.isAfter(nowMoment);
            }
            return retVal;
        }
    },
    computed: {
        actionUrl() {
            return router.resolve({ name: 'hotels_report'}).href;
        }
    }
}
</script>