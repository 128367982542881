<script>
import HotelViewDealRow from "./HotelViewDealRow";

export default {
    extends: HotelViewDealRow,
    
    props: {
        outlet: {
            required: true,
        },
    },

    methods: {
         viewDeal(evt) {
            evt.preventDefault();

            this.$router.push({ name: 'outlet_deal_edit', params: {
                hotelId: this.$route.params.hotelId,
                outletId: this.outlet.id,
                dealId: this.deal.deal_id,
            },
                query: {view: 'outlet-deal'},
            });
        },
        addToCarousel(evt) {
            const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            this.$store.dispatch('addHotelOutletDealCarousel', {
                hotel: this.hotel,
                outlet: this.outlet,
                deal: this.deal,
            })
                .then((success) => {
                    console.log("added to carousel", success, this.deal);
                })
                .catch((error) => {
                    console.log("addHotelOutletDealCarousel", error.response);
                })
                .finally(() => {
                    loader.hide();
                });

            evt.preventDefault(); 
            return false;
        },
        removeFromCarousel(evt) {
            const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            this.$store.dispatch('removeHotelOutletDealCarousel', {
                hotel: this.hotel,
                outlet: this.outlet,
                deal: this.deal,
            })
                .then((success) => {
                    console.log("removed from carousel", success, this.deal);
                })
                .catch((error) => {
                    console.log("removeHotelOutletDealCarousel", error.response);
                })
                .finally(() => {
                    loader.hide();
                });

            evt.preventDefault(); 
            return false;
        },
        editDeal(evt) {
            evt.preventDefault();

            this.$router.push({ name: 'outlet_deal_edit', params: {
                 hotelId: this.$route.params.hotelId,
                 outletId: this.outlet.id,
                 dealId: this.deal.deal_id,
                },
                query: {edit: 'outlet-deal'},
            });
        },
        deleteDeal(evt) {
            const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            this.$store.dispatch('deleteHotelOutletDeal', {
                hotelId: this.hotel.id,
                outletId: this.outlet.id,
                dealId: this.deal.deal_id,
                hotelOutletDealId: this.deal.id,
            })
                .finally(() => {
                    loader.hide();
                    this.$emit('get-deals')
                });
            evt.preventDefault();
            return false;
        },
    }
}
</script>