<template>
    <div class="col-sm-12">
        <h2>Currency Settings</h2>
        <br>
        <form @submit="submit">
            <fieldset>
                <div class="form-group col-sm-4">
                    <label for="images">Select Base Currency</label>
                    <select v-model="selectedBaseCurrency"  class="form-control" @change="onBaseCurrencyChange">
                        <option v-for="(item, key) in worldCurrency" :key="key" :value="item.iso_code">{{item.currency}} - {{item.iso_code}}</option>
                    </select>
                </div>
                <div class="form-group required col-sm-4">
                    <label for="ratio">Currency:</label>
                    <select v-model="selectedCurrency"  class="form-control" @change="onCurrencyChange">
                        <option v-for="(item, key) in worldCurrency" :key="key" :value="item.iso_code">{{item.currency}} - {{item.iso_code}}</option>
                    </select>
                </div>

                <div class="form-group col-sm-4">
                    <label for="ratio"> Note: <b> {{this.note}} </b> </label>
                </div>

                <div class="form-group col-sm-4">
                   <button type="button" class="button" @click="onAddCurrencyClick">Add currency</button>
                </div>
                    
                <div style="margin-top: 30px" class="col-sm-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">id</th>
                                <th scope="col">Currency</th>
                                <th scope="col">ISO Code</th>
                                <th scope="col">Symbol</th>
                                <th scope="col">Actions</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                             <tr v-for="item in hotelsCurrency" :key="item.id">
                                <td> {{item.id}} </td>
                                <td> {{item.currency}} </td>
                                <td> {{item.iso_code}} </td>
                                <td> {{item.symbol}} </td>
                                <td> <button type="button" :disabled="item.is_base_currency === 1 ? true : false" class="button" @click="onRemoveCurrencyClick(item)">Remove</button> </td>
                                <td> <button type="button" :disabled="item.is_base_currency === 1 ? true : false" class="button" @click="onSetBaseCurrencyClick(item)">Set as base currency</button> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </fieldset>
        </form>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import RemovableImage from "../components/RemovableImage";
import VSwatches from 'vue-swatches'
import axios from "axios"

// import "vue-swatches/dist/vue-swatches.min.css"

export default {

    components: {
        RemovableImage,
        VSwatches
    },

    mounted() {

        var setting = this.getSetting;

        if (!setting) {
            // const settingId = this.$route.params.settingId;
            const settingId = 1;
            const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            this.$store.dispatch('loadSetting', settingId)
                .then((setting) => {
                    this.assignSetting(setting);

                })
                .catch(() => {})
                .finally(() => {
                    loader.hide();
                });
        }
        else {
            this.assignSetting(hotel);
        }
    },
    
    data() {
        return {
            worldCurrency: [
            {
                "id": 248,
                "country": "UNITED ARAB EMIRATES (THE)",
                "currency": "UAE Dirham",
                "iso_code": "AED",
                "symbol": "DH",
            },    
            {
                "id": 5,
                "country": "AMERICAN",
                "currency": "US Dollar",
                "iso_code": "USD",
                "symbol": "$",
            },
            {
                "id": 208,
                "country": "SAUDI ARABIA",
                "currency": "Saudi Riyal",
                "iso_code": "SAR",
                "symbol": "SR",
            },
            {
                "id": 63,
                "country": "CYPRUS",
                "currency": "Euro",
                "iso_code": "EUR",
                "symbol": "€",
            },
            {
                "id": 249,
                "country": "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)",
                "currency": "Pound Sterling",
                "iso_code": "GBP",
                "symbol": "£",
            },
            {
                "id": 228,
                "country": "SWITZERLAND",
                "currency": "Swiss Franc",
                "iso_code": "CHF",
                "symbol": "CHf",
            },
            {
                "id": 1280,
                "country": "OMAN",
                "currency": "Rial Omani",
                "iso_code": "OMR",
                "symbol": "ر.ع.",
            }
            ],

            hotelsCurrency: [],
            
            selectedBaseCurrency: 'Choose Option',
            selectedCurrency: 'Choose Option',
            note: '',

            testId: 0

        };
    },
    methods: {

        onBaseCurrencyChange(e){
            this.selectedBaseCurrency = e.target.value
        },

        onCurrencyChange(e){
            this.selectedCurrency = e.target.value

            if(this.selectedBaseCurrency !== 'Choose Option'){
                axios.get(`https://api.exchangerate.host/convert?from=${this.selectedBaseCurrency}&to=${e.target.value}`)
                .then(response => {
                    if(response.data.success){
                        this.note = `One ${this.selectedBaseCurrency} is ${Math.round(response.data.result * 100 + Number.EPSILON ) / 100} ${this.selectedCurrency}`
                    }
                    console.info(response)
                }) 

            }
        },

        async getHotelCurrency(){
            axios.get(`${process.env.MIX_API_SERVER_PATH}/api/currency-settings`)
            .then(response => {
                this.hotelsCurrency = response.data.items
            })
        },

        async onAddCurrencyClick(){
            if(this.hotelsCurrency.length > 5){
                alert('You can only add 6 currency')
                return
            }

            if(this.selectedBaseCurrency !== 'Choose Option' && this.selectedCurrency !== 'Choose Option'){
                axios.post(`${process.env.MIX_API_SERVER_PATH}/api/currency-settings`, {
                    'currency': this.worldCurrency.find(item => item.iso_code === this.selectedCurrency).currency,
                    'iso_code': this.selectedCurrency,
                    'symbol': this.worldCurrency.find(item => item.iso_code === this.selectedCurrency).symbol,
                })
                .then(response => {
                    if(response.data.success){
                        this.hotelsCurrency.push(response.data.item)
                    }
                })
            }
        },

        async onRemoveCurrencyClick(item){
            axios.delete(`${process.env.MIX_API_SERVER_PATH}/api/currency-settings/${item.id}`)
            .then(response => {
                this.hotelsCurrency.splice(this.hotelsCurrency.findIndex(items => items.id === item.id), 1)
            })
        },

        onSetBaseCurrencyClick(item){
            axios.post(`${process.env.MIX_API_SERVER_PATH}/api/set-base-currency?id=${item.id}`)
                .then(response => {
                    if(response.data.success){
                        this.hotelsCurrency.map((items) => {
                            if(items.id !== item.id){
                                items.is_base_currency = 0
                            }else{
                                items.is_base_currency = 1
                            }
                        })
                    }
                })
        },

        assignSetting(setting) {
            this.setting.images.data = [
                {
                    id: 1,
                    url: setting.app_bg_url
                }
            ]

            this.setting.logoImage.data = [
                {
                    id: 1,
                    url: setting.app_logo_url
                }
            ]

            // if(setting.app_color.type !== 'solid'){
            //     this.isAppColorSolid = false
            // }else{
            //     this.isAppColorSolid = true
            // }

            this.isAppColorSolid =  setting.app_color.type !== 'solid' ? false : true

            this.selectedColorType = setting.app_color.type
            this.colorStart = setting.app_color.color.start
            this.colorEnd = setting.app_color.color.end
        },
        
        selectImages() {
            this.$refs.imageSelector.click();
        },

        selectLogoImage() {
            this.$refs.logoImageSelector.click();
        },

        processSelected(evt) {
            var images = Object.values(evt.target.files);
            images.forEach((image) => {
                if(this.setting.images.data.length < 0){
                    this.setting.images.data.push(image);
                }else{
                    this.setting.images.data.splice(0, 1, image)
                }
            });
        },

         processLogoImage(evt) {
            var images = Object.values(evt.target.files);
            images.forEach((image) => {
                if(this.setting.logoImage.data.length < 0){
                    this.setting.logoImage.data.push(image);
                }else{
                    this.setting.logoImage.data.splice(0, 1, image)
                }
            });
        },

        onAppColorChange(e){
            this.selectedColorType = e.target.value

            if(e.target.value === 'solid'){
                this.isAppColorSolid = true
            }else{
                this.isAppColorSolid = false
            }
        },

        onSaveAppColorClick(){
            let data = new FormData()
            data.append('app_color', JSON.stringify({
                type: 'gradient',
                color: {
                    start: this.colorStart,
                    end: this.colorEnd
                }
            }))
           
        },

        submit(evt) {
            const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            this.$store.dispatch('editSetting', {
                setting: this.setting,
                removedImages: this.removedImages,
                removedLogoImage: this.removedLogoImage,
                appColor: JSON.stringify({
                    type: this.selectedColorType,
                    color: {
                        start: this.colorStart,
                        end: this.colorEnd
                    }
                })
            })
                .then((setting) => {
                    // this.$router.push({ name: 'hotel_view', params: {
                    //     hotelId: hotel.id,
                    //     hotel: hotel
                    // }});
                    // alert('success')
                    console.info('wtfff')
                })
                .catch(() => {})
                .finally(() => {
                    loader.hide();
                });

            evt.preventDefault();
            return false;
        },
        
        removeImage(index) {
            console.info('index', index)
            const image = this.setting.images.data[index];
            if (!(image instanceof File)) {
                this.removedImages.push(image.id);
            }
            this.setting.images.data.splice(index, 1);
        },

        removeLogoImage(index) {
            const image = this.setting.logoImage.data[index];
            if (!(image instanceof File)) {
                this.removedLogoImage.push(image.id);
                console.info('indexxx', image.id)

            }
            this.setting.logoImage.data.splice(index, 1);
        },

    },

    mounted(){
        this.getHotelCurrency()
    }

}
</script>