<template>
    <div class="col-sm-12">
        <h2>
            Pages
        </h2>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">
                        ID
                    </th>
                    <th scope="col">
                        Endpoint
                    </th>
                    <th scope="col">
                        Title
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="page in pages.data" :key="page.id">
                    <td>{{ page.id }}</td>
                    <td>{{ page.endpoint }}</td>
                    <td><router-link :to="{ name: 'page_view', params: { pageId: page.id } }">{{ page.title }}</router-link></td>
                    <td>
                        <router-link :to="{ name: 'page_edit', params: { pageId: page.id, } }"><i class="far fa-edit"></i></router-link>
                        <router-link :to="{ name: 'page_delete', params: { pageId: page.id, } }"><i class="far fa-trash-alt"></i></router-link>
                        <a :href="`${apiServerPath}/page/${page.endpoint}`" target="_BLANK"><i class="fas fa-external-link-alt"></i></a>
                    </td>
                </tr>
                <tr v-if="!hasEntries">
                    <td colspan="5" class="text-center">
                        -- No pages yet --
                    </td>
                </tr>
                <tr class="paginator" v-if="canLoadMore">
                    <td colspan="5" class="text-center">
                        <router-link class="link" v-if="currentPage != totalPages" :to="{ name: 'page_list', query: { page: currentPage + 1 } }"> Load More </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "../Store";
import WebUtils from '../utils/WebUtils';
import LoadingOverlay from "vue-loading-overlay";
import Config from "vue"

export default {

    data() {
        return {
            pages: {
                data: {},
                pagination: {},
            },
            pageParam: 1,
        };
    },

    mounted() {
        if (this.$route.query.page) {
           this.pageParam = this.$route.query.page;
        }

        const pages = this.getPages;
        const cachedPagination = pages.pagination;
        if (cachedPagination) {
            if (cachedPagination.current_page == this.pageParam) {
                this.pages = pages;
            }
        }

        this.goToPage(1);
    },

    methods: {
        goToPage(page) {
            if (page <= this.totalPages || page == 1) {
                const loader = this.$loading.show({
                    container: this.$el,
                    canCancel: false,
                });
                this.$store.dispatch('loadPages', {
                    page
                })
                    .then(() => {
                        if (page < this.pageParam) {
                            this.goToPage(page+1);
                        }
                    })
                    .finally(() => {
                        loader.hide();
                    });
            }
        },
    },

    computed: {
        ...mapGetters([
            'getPages',
        ]),
        currentPage() {
            try {
                return this.pages.pagination.current_page;
            }
            catch (ex) {}

            return 1;
        },
        totalPages() {
            try {
                return this.pages.pagination.total_pages;
            }
            catch (ex) {}

            return 1;
        },
        hasEntries() {
            try {
                return Object.keys(this.pages.data).length > 0;
            }
            catch(ex) {
                return false;
            }
        },
        canLoadMore() {
            return this.currentPage < this.totalPages;
        },
        apiServerPath() {
            return process.env.MIX_API_SERVER_PATH;
        },
    },

    watch: {
        getPages(newValue, oldValue) {
            this.pages = newValue;
        },
        '$route.query.page'(newValue, oldValue) {
            if (!newValue) {
                newValue = 1;
            }
            this.goToPage(newValue);
        },
    }
}
</script>