<template>
  <div class="col-sm-12">
    <br>
    <div class="row">
      <div class="col-sm-12">
        <div v-if="visible" style="margin: auto;" class="box col-sm-5">
          <div v-if="!success">
            <fieldset>
              <div class="required">
                <label for="password">New Password</label>
                <input type="password" class="form-control" v-model="password"/>
              </div>
              <div style="margin-top: 10px;" class="required">
                <label for="password">Confirm new password</label>
                <input type="password" class="form-control" v-model="confirmPassword"/>
              </div>
              <div style="margin-top: 10px;">
                <button type="button" class="btn btn-primary" @click="onChangePasswordClick">Change Password</button>
              </div>
              <br>
              <br>
           </fieldset>
          </div>

          <div v-if="success" style="text-align: center">
            <i style="color: #008000" class="fas fa-5x fa-check-circle"></i>
            <p style="margin-top: 10px; font-size: 18px; color: #00000;"> Password Changed! </p>
            <p style="font-size: 18px"> Your password has been changed successfully. </p>
            <br>
            <br>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>

import axios from "axios"
import { saveAs } from 'file-saver';
import DatePicker from 'vue2-datepicker';
import moment from "moment";

export default {
   components: {
    DatePicker,
  },

  data() {
    return {
      visible: false,
      success: false,

      password: '',
      confirmPassword: '',
    };
  },
  methods: {

    resetPassword(){
      const loader = this.$loading.show({
        container: this.$refs.content,
        canCancel: true,
      });

      axios.post(`${process.env.MIX_API_SERVER_PATH}/api/password-reset`, {
        token: this.$route.query.token,
        password: this.password
      }).then(response => {

        console.info(response.data)

        if(response.data.success){
          this.success = true
        }

        this.password = ''
        this.confirmPassword = ''

        loader.hide()

        alert(response.data.message)

      })
    },

    onChangePasswordClick(){
      if(this.password === '' || this.confirmPassword === ''){
        alert('Password is required.')
        return
      }

      if(this.password !== '' && this.confirmPassword !== '' && this.password !== this.confirmPassword){
        alert('Password does not match')
        return
      }

      if(this.password.length < 6 || this.confirmPassword < 6){
        alert('Password required minumum of 6 characters')
        return
      }

      this.resetPassword()

    }

  },

  mounted(){

    if(this.$route.query.token){
      this.visible = true
    }
    
  }

}
</script>