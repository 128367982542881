<template>
    <tr class="deal-entry">
        <td>{{ deal.id }}</td>
        <td>
            <a href="#" class="link" @click="viewDeal"> {{ deal.name }} </a>
        </td>
        <td>
            {{ deal.description }}
        </td>
        <td>
            <a href="#" class="link" title="Add to carousel" v-if="deal.isInCarousel == false" @click="addToCarousel"> <i class="fas fa-film"></i> </a>
            <a href="#" class="link" title="Remove from carousel" v-else-if="deal.isInCarousel == true" @click="removeFromCarousel"> <i class="fas fa-minus-circle"></i> </a>
            <a href="#" class="link" title="Edit Deal" @click="editDeal"> <i class="far fa-edit"></i> </a>
            <a href="#" class="link" title="Delete Deal" @click="deleteDeal"> <i class="far fa-trash-alt"></i> </a>
        </td>
    </tr>
</template>

<script>
export default {
    props: {
        hotel: {
            required: true,
        },
        deal: {
            required: true,
        },
    },
    data() {
        return {
        };
    },
    methods: {
        viewDeal(evt) {
            evt.preventDefault();

            this.$router.push({ name: 'hotel_deal_edit', params: {
                 hotelId: this.$route.params.hotelId,
                 dealId: this.deal.id,
                },
                query: {view: 'hotel-deal'},
            });
        },
        addToCarousel(evt) {
            const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            this.$store.dispatch('addHotelDealCarousel', {
                hotel: this.hotel,
                deal: this.deal,
            })
                .then((success) => {
                    console.log("added to carousel", success, this.deal);
                    this.deal.isInCarousel = true
                })
                .catch((error) => {
                    console.log("addHotelDealCarousel", error.response);
                })
                .finally(() => {
                    loader.hide();
                });

            evt.preventDefault(); 
            return false;
        },
        removeFromCarousel(evt) {
            const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            this.$store.dispatch('removeHotelDealCarousel', {
                hotel: this.hotel,
                deal: this.deal,
            })
                .then((success) => {
                    console.log("removed from carousel", success, this.deal);
                    this.deal.isInCarousel = false
                })
                .catch((error) => {
                    console.log("removeHotelDealCarousel", error.response);
                })
                .finally(() => {
                    loader.hide()
                });

            evt.preventDefault(); 
            return false;
        },
        editDeal(evt) {
            evt.preventDefault();

            this.$router.push({ name: 'hotel_deal_edit', params: {
                 hotelId: this.$route.params.hotelId,
                 dealId: this.deal.id,
                },
                query: {edit: 'hotel-deal'},
            });

        },
        deleteDeal(evt) {
            const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            this.$store.dispatch('deleteHotelDeal', {
                hotelId: this.hotel.id,
                dealId: this.deal.id,
            })
            .finally(() => {
                loader.hide();
                this.$emit('get-deals')
            });
            evt.preventDefault();
            return false;
        },
    }
}
</script>