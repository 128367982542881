<script>
import HotelDealEdit from "./HotelDealEdit";

export default {
    extends: HotelDealEdit,

    props: {
        outlet: {
            required: true,
        },
    },

    data() {
        return {
            title: 'Edit Deal',
            modalName: 'hotel-outlet-deal-edit',
        };
    },

    methods: {
        beforeOpen(event) {
            const {
                id,
                deal_id,
                name,
                description,
                date,
                discount,
                image,
                title_color,
                name_color,
                description_color,
                availability_color,
            } = event.params.deal;

            this.deal = {
                id,
                deal_id,
                name,
                description,
                date: {...date},
                discount: {...discount},
                image: {...image},
                title_color,
                name_color,
                description_color,
                availability_color,
            };
        },
        submit(evt) {
            const loader = this.$loading.show({
                container: this.$refs.content,
                canCancel: false,
            });

            this.$store.dispatch('editHotelOutletDeal', {
                hotelId: this.hotel.id,
                outletId: this.outlet.id,
                deal: this.deal,
            })
                .then((deal) => {
                    this.$emit('dealCreated', deal);
                    setTimeout(() => {
                        this.$modal.hide('hotel-outlet-deal-edit');
                    }, 1);
                })
                .catch((error) => {
                    console.log("/api/hotel/:hotelId/outlets/:dealId/deals:edit error", error.response);
                })
                .finally(() => {
                    loader.hide();
                });

            if (evt) {
                evt.preventDefault();
                return false;
            }
        },
    },
}
</script>