<template>
    <tr>
        <td class="align-middle">
            <select required v-model="schedule.day">
                <option value="" disabled>Day</option>
                <option v-for="(day, key) in days" :value="key" :key="key">{{ day }}</option>
            </select>
        </td>
        <td class="align-middle">
            <date-picker format="HH:mm:00" type="time" valueType="format" :tabindex="index" :minute-step="5" :input-attr="{ required: true }" input-class="form-input" v-model="schedule.time.start" placeholder="Time Start" ></date-picker>
        </td>
        <td class="align-middle">
            <date-picker format="HH:mm:00" type="time" valueType="format" :tabindex="index" :minute-step="5" :input-attr="{ required: true }" input-class="form-input" v-model="schedule.time.end" placeholder="Time End" ></date-picker>
        </td>
        <td class="align-middle">
            <a class="link" @click="remove">&times;</a>
        </td>
    </tr>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import moment from "moment";

export default {
    props: {
        schedule: {
            required: true,
            type: Object,
        },
        index: {
            required: true,
        },
    },
    components: {
        DatePicker,
    },
    computed: {
        days() {
            return {
                monday: 'Monday',
                tuesday: 'Tuesday',
                wednesday: 'Wednesday',
                thursday: 'Thursday',
                friday: 'Friday',
                saturday: 'Saturday',
                sunday: 'Sunday',
            };
        }
    },
    methods: {
        remove() {
            this.$emit('remove', this.index);
        },
    },
}
</script>