<template>

    <div class="container">

        <h2 style="font-size: 24px; margin-top: 20px">
            Customer Report
        </h2>
        <div style="margin-top: 10px" v-html="dateRange"></div>

    <div style="margin-top: 30px" class="field">
        <p class="control has-icons-left">
            <input
                class="input is-shadowless"
                type="text"
                placeholder="Search Customer"
                v-model="search"
            />
            <span class="icon is-small is-left">
            <i class="fas fa-search"></i>
            </span>
        </p>
    </div>

    <b-table
      :data="filtered"
      :paginated="true"
      per-page="15"
      current-page.sync="1"
      :pagination-simple="false"
      pagination-position="bottom"
      default-sort-direction="asc"
      sort-icon="chevron-up"
      sort-icon-size="is-small"
      :striped="true"
      :hoverable="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :mobile-cards="false"
    >
        <b-table-column
          field="id"
          label="ID"
          sortable
          v-slot="props"
        >
          {{ props.row.id }}
        </b-table-column>

        <b-table-column
          field="name"
          label="Name"
          sortable
          v-slot="props"
        >
          {{ props.row.name }}
        </b-table-column>

        <b-table-column
          field="email"
          label="Email"
          sortable
          v-slot="props"
        >
          {{ props.row.email }}
        </b-table-column>

        <b-table-column
          field="membership.number"
          label="Membership Number"
          sortable
          v-slot="props"
        >
          {{ props.row.membership.number }}
        </b-table-column>

        <b-table-column
          field="membership.since"
          label="Membership Since"
          sortable
          v-slot="props"
        >
          {{ props.row.membership.since }}
        </b-table-column>

        <b-table-column
          field="claimed"
          label="Points Claimed"
          sortable
          v-slot="props"
        >
          {{ props.row.claimed }}
        </b-table-column>

         <b-table-column
          field="redeemed"
          label="Points Redeemed"
          sortable
          v-slot="props"
        >
          {{ props.row.redeemed }}
        </b-table-column>

        <template slot="footer" >
            <div style="margin-top: 10px">
                Total claimed: {{ totalClaimed }}
            </div>
            <div>
                Total redeemed: {{ totalRedeemed  }}
            </div>
        </template>
        
    </b-table>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    data() {
        return {
            customers: [],
            search: '',
        };
    },

    mounted() {
        const {
            sort = 'id',
            direction = 'asc',
        } = this.$route.query;
        const params = {
            sort, direction,
        };
        if (this.$route.query['date-start']) {
            params['date-start'] = this.$route.query['date-start'];
        }
        if (this.$route.query['date-end']) {
            params['date-end'] = this.$route.query['date-end'];
        }
        this.$store.dispatch('loadCustomersReports', params).then((customers) => {
            this.customers = customers;
        });
    },

    computed: {
        dateRange() {
            var dateStart = this.$route.query['date-start'];
            var dateEnd = this.$route.query['date-end'];

            if (dateStart) {
                dateStart = moment(dateStart).format('DD MMM YYYY');
            }
            if (dateEnd) {
                dateEnd = moment(dateEnd).format('DD MMM YYYY');
            }

            if (dateStart && dateEnd) {
                if (dateStart == dateEnd) {
                    return `<strong>Date Range:</strong> ${dateStart}`;
                }
                else {
                    return `<strong>Date Range:</strong> ${dateStart} to ${dateEnd}`;
                }
            }
            else if (dateStart) {
                return `<strong>Date Range:</strong> ${dateStart} onwards`;
            }
            else if (dateEnd) {
                return `<strong>Date Range:</strong> up to ${dateEnd}`;
            }
            return '<strong>Date Range:</strong> All';
        },
        totalClaimed() {
            return this.customers.map(item => parseInt(item.claimed)).reduce((a, b) => a + b, 0);
        },
        totalRedeemed() {
            return this.customers.map(item => parseInt(item.redeemed)).reduce((a, b) => a + b, 0);
        },

        filtered() {
            if (!this.search.trim()) {
                return this.customers
            }
            return this.customers.filter(data =>
                Object.values(data).some(val =>
                String(val)
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
                )
            )
        },
    },
}
</script>