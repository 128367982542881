<template>
  <div class="col-sm-12">
    <h2>Add Hotel</h2>
    <form @submit="submit">
      <fieldset>
        <div style="margin-top: 10px" class="form-group">
          <label for="defaultLanguage" class="has-text-weight-bold">Default language:</label>
          <input type="text" class="form-control"  readonly v-model="defaultLanguage"/>
        </div>

        <div class="form-group required">
          <label for="name">Name:</label>
          <input type="text" required id="name" class="form-control" v-model="hotel.name"/>
        </div>

         <div class="form-group required">
          <label for="description" class="has-text-weight-bold">Description:</label>
          <textarea required id="description" class="form-control" v-model="hotel.description"/>
         </div>

        <div class="form-group required">
          <label for="access_code">Authorization Code:</label>
          <input type="number" required id="access_code" step="1" class="form-control" v-model="hotel.access_code" min="0" max="9999" pattern="\d{4}"/>
        </div>
        <div class="form-group required">
          <label for="ratio">Points Ratio:</label>
          <input type="number" required step="any" id="ratio" class="form-control" min="1" v-model="hotel.ratio"/>
        </div>
        <div class="form-group required">
          <label for="location_address">Location Address:</label>
          <input type="text" required id="location_address" class="form-control" v-model="hotel.location_address"/>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group required">
              <label for="location_lat">Latitude:</label>
              <input type="number" required step="any" id="location_lat" class="form-control" v-model="hotel.location.lat"/>
            </div>
          </div>
          <div class="col-sm-6 required">
            <div class="form-group">
              <label for="location_lng">Longitude:</label>
              <input type="number" required step="any" id="location_lng" class="form-control" v-model="hotel.location.lng"/>
            </div>
          </div>
        </div>
         <div class="form-group required">
          <label for="location_address">Currency</label>
          <select v-model="selectedCurrency"  class="form-control" @change="onCurrencyChange">
            <option v-for="(item, key) in hotelsCurrency" :key="key" :value="item.iso_code">{{item.currency}} - {{item.iso_code}}</option>
          </select>
        </div>
        <div class="form-group required">
          <label for="images">Images:</label>
          <input type="file" id="images" class="form-control d-none" accept="image/*" ref="imageSelector" multiple @change="processSelected"/>
          <button type="button" class="button" @click="selectImages">Select Images</button>
          <small class="form-text text-muted">Minimum image size is 640 width and 260 height with ideal content ratio is 5:2.</small>
          <div class="row">
            <RemovableImage v-for="(image, index) in images" :image="image" :key="index" :index="index" @removeImage="removeImage">
            </RemovableImage>
          </div>
        </div>
        <button type="submit" class="button">Submit</button>
      </fieldset>
    </form>
  </div>
</template>
<script>
import RemovableImage from "../components/RemovableImage";
import axios from "axios"

export default {
  components: {
    RemovableImage,
  },
  data() {
    return {
      hotel: {
        name: '',
        description: '',
        access_code: '',
        location_address: '',
        location: {
          lat: 25,
          lng: 55,
        },
        images: [],
        currency_id: '',
        lang_iso_code: '',
      },
      selectedCurrency: 'Choose Option',
      hotelsCurrency: [],
      defaultLanguage: '',
      supportedLanguages: [],
    };
  },

  computed: {
    images() {
      const images = [];
      
      this.hotel.images.forEach((imageFile) => {
        images.push(URL.createObjectURL(imageFile));
      });

      return images;
    },
  },
  
  methods: {
    selectImages() {
      this.$refs.imageSelector.click();
    },
    processSelected(evt) {
      var images = Object.values(evt.target.files);
      images.forEach((image) => {
        this.hotel.images.push(image);
      });
    },
    submit(evt) {

      evt.preventDefault()

      if(this.hotel.name.trim().length < 1){
        return
      }

      if(this.hotel.description.trim().length < 1){
        return
      }

      if(this.hotel.images.length < 1){
        return
      }

      if(this.hotel.currency_id.length < 1){
        return
      }

      if(this.selectedCurrency === 'Choose Option'){
        return
      }


      const loader = this.$loading.show({
        container: this.$el,
        canCancel: false,
      });

      this.$store.dispatch('addHotel', this.hotel)
      .then((hotel) => {

        axios.post(`${process.env.MIX_API_SERVER_PATH}/api/hotel-multi-langs`, {
          hotel_id: hotel.id,
          iso_code: this.supportedLanguages.find(item => item.description === this.defaultLanguage).iso_code,
          name: this.hotel.name,
          description: this.hotel.description,
          location_address: this.hotel.location_address,
        })
          .then((response) => {
          if(!response.data.success){
            alert('unable to save item')
            return
          }

          this.$swal.fire(
            'Saved!',
            'Language and contents added',
            'success'
          )

          this.$router.push({ name: 'hotel_view', params: {
            hotelId: hotel.id,
            hotel: hotel
          }});
        })

      })
      .catch((error) => {
        console.log("hotel:add error", error.response);
      })
      .finally(() => {
        loader.hide();
      })

      evt.preventDefault();
      return false;
    },
    removeImage(index) {
      this.hotel.images.splice(index, 1);
    },

    async getHotelCurrency(){
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/currency-settings`)
      .then(response => {
        this.hotelsCurrency = response.data.items
      })
    },

    onCurrencyChange(e){
      this.selectedCurrency = e.target.value
      this.hotel.currency_id = this.hotelsCurrency.find(item => item.iso_code === this.selectedCurrency).id
    },


    getSupportedLanguages(){
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/languages`)
      .then(response => {
        this.supportedLanguages = response.data.items
        this.getSettings()
      })
    },

    getSettings(context, settingId) {
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/settings/1`)
      .then(response => {
        if(response.data.default_lang_iso_code !== ''){
        this.defaultLanguage = this.supportedLanguages.find(item => item.iso_code === response.data.default_lang_iso_code).description
        this.hotel.lang_iso_code = response.data.default_lang_iso_code
        }
      })
    },

  },
  mounted(){
     this.getHotelCurrency()
     this.getSupportedLanguages()
  }
}
</script>