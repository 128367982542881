<template>
    <div class="col-sm-12">
        <h2 style="font-size: 24px; margin-top: 20px">
            Customers
        </h2>

    <div style="margin-top: 30px" class="field">
        <p class="control has-icons-left">
            <input
                class="input is-shadowless"
                type="text"
                placeholder="Search Customer"
                v-model="search"
            />
            <span class="icon is-small is-left">
            <i class="fas fa-search"></i>
            </span>
        </p>
    </div>

     <b-table
      :data="filtered"
      :paginated="true"
      per-page="10"
      current-page.sync="1"
      :pagination-simple="false"
      pagination-position="bottom"
      default-sort-direction="asc"
      sort-icon="chevron-up"
      sort-icon-size="is-small"
      :striped="true"
      :hoverable="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :mobile-cards="false"
    >
        <b-table-column
          field="id"
          label="ID"
          sortable
          v-slot="props"
        >
          {{ props.row.id }}
        </b-table-column>

        <b-table-column
          field="user_account.email"
          label="Email"
          sortable
          v-slot="props"
        >
          {{ props.row.user_account.email }}
        </b-table-column>

        <b-table-column
          field="first_name"
          label="Full Name"
          sortable
          v-slot="props"
        >
          {{ props.row.first_name }} {{ props.row.last_name }}
        </b-table-column>

        <b-table-column
          field="phone_number"
          label="Phone Number"
          sortable
          v-slot="props"
        >
          {{ props.row.phone_number }}
        </b-table-column>

        <b-table-column
          field="membership.number"
          label="Membership Number"
          sortable
          v-slot="props"
        >
          {{ props.row.membership.number }}
        </b-table-column>

        <b-table-column
          field="membership.member_since"
          label="Membership Since"
          sortable
          v-slot="props"
        >
          {{ props.row.membership.member_since }}
        </b-table-column>


        <b-table-column centered width="5" label="Actions"  v-slot="props">
          <div class="buttons is-centered is-inline-block">
            <!-- View icon -->
            <button class="button is-shadowless" @click="viewProfile(props.row)">
              <span class="icon">
                <i class="fas fa-eye has-text-success"></i>
              </span>
            </button>
          </div>
        </b-table-column>
    </b-table>

      
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "../Store";
import WebUtils from '../utils/WebUtils';
import LoadingOverlay from "vue-loading-overlay";
import axios from "axios"

export default {
    data() {
        return {
            customers: [],
            search: '',
        };
    },

    computed: {
        filtered() {
            if (!this.search.trim()) {
                return this.customers
            }
            return this.customers.filter(data =>
                Object.values(data).some(val =>
                String(val)
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
                )
            )
        },
       
    },

    methods: {
        getCustomers(){
            axios.get(`${process.env.MIX_API_SERVER_PATH}/api/customers`)
            .then(response => {
                this.customers = response.data.customer
                console.info('customers', this.customers)
            })
        },

        viewProfile(item){
            this.$router.push({ name: 'customer_view', params: { customerId: item.id, customer: item } })
        }
    },

    mounted() {
        this.getCustomers()
    },
    
}
</script>