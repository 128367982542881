<template>
  <modal name="hotel-outlet-add" classes="v--modal my-4" height="auto" :scrollable="true" :clickToClose="false" @before-open="clear">
    <div class="col-sm-12 py-4" ref="content">
      <a class="link float-right" @click="close"> <i class="fas fa-times-circle"></i> </a>
      <h3>Add Outlet</h3>
      <div class="row">
        <div class="col-sm-12">
          <form ref="form" @submit="submit">
            <fieldset>
              <div class="form-group required">
                <label for="name">Name:</label>
                <input type="text" required id="name" class="form-control" v-model="outlet.name"/>
              </div>
              <div class="form-group required">
                <label for="description">Description:</label>
                <textarea required id="description" class="form-control" v-model="outlet.description"/>
              </div>
              <div class="form-group required">
                <label for="access_code">Authorization Code:</label>
                <input type="number" required id="access_code" step="1" class="form-control" v-model="outlet.access_code" min="0" max="9999" pattern="\d{4}"/>
              </div>
              <div class="form-group required">
                <label>Schedule(s)</label>
                <table class="table">
                  <tbody>
                    <outlet-schedule v-for="(schedule, index) in outlet.schedules" :key="index" :index="index"
                      :schedule="schedule" @remove="removeOutletSchedule"
                      ></outlet-schedule>
                    <tr>
                      <td colspan="4" class="text-center">
                        <button type="button" class="btn btn-secondary" @click="addSchedule">Add Schedule</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="form-group required">
                <label for="images">Images:</label>
                <input type="file" id="images" class="form-control d-none" accept="image/*" ref="imageSelector" multiple @change="processSelected"/>
                <button type="button" class="btn btn-secondary" @click="selectImages">Select Images</button>
                <small class="form-text text-muted">Minimum image size is 640 width and 260 height with ideal content ratio is 5:2.</small>
                <div class="row">
                  <RemovableImage v-for="(image, index) in images" :image="image" :key="index" :index="index" @removeImage="removeImage">
                  </RemovableImage>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <button type="button" class="btn btn-primary" @click="triggerSubmit">
            Save
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import OutletSchedule from "./OutletSchedule";
import RemovableImage from "../components/RemovableImage";
import axios from "axios"

export default {
  components: {
    OutletSchedule,
    RemovableImage,
  },

  props: {
    hotel: {
      required: true,
    }
  },

  data() {
    return {
      outlet: {
        name: '',
        description: '',
        access_code: '',
        schedules: [],
        images: [],
      },

      defaultLanguage: '',
      supportedLanguages: [],
    };
  },

  computed: {
    validateSchedules() {
      var valid = true;
      let regex = /[0-9]{2}:[0-9]{2}:00/;
      this.outlet.schedules.forEach((schedule) => {
        valid = regex.test(schedule.time.start) && regex.test(schedule.time.end) && valid;
      });
      return valid;
    },
    images() {
      const images = [];
      
      this.outlet.images.forEach(imageFile => {
        images.push(URL.createObjectURL(imageFile));
      });

      return images;
    },
  },

  methods: {

    getSupportedLanguages(){
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/languages`)
      .then(response => {
        this.supportedLanguages = response.data.items
        this.getSettings()

      })
    },

    getSettings() {
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/settings/1`)
      .then(response => {
        if(response.data.default_lang_iso_code !== ''){
        this.defaultLanguage = this.supportedLanguages.find(item => item.iso_code === response.data.default_lang_iso_code).description
        }
      })
    },

    selectImages() {
      this.$refs.imageSelector.click();
    },
    processSelected(evt) {
      var images = Object.values(evt.target.files);
      images.forEach((image) => {
        this.outlet.images.push(image);
      });
    },
    triggerSubmit() {

      if(this.outlet.schedules.length < 1){
        return
      }

      if(this.outlet.images.length < 1){
        return
      }
      
      if (this.$refs.form.reportValidity()) {
        this.submit();
      }
    },
    addMultiLang(outlet){
      axios.post(`${process.env.MIX_API_SERVER_PATH}/api/hotel-outlet-multi-langs`, {
        outlet_id: outlet.id,
        iso_code: this.supportedLanguages.find(item => item.description === this.defaultLanguage).iso_code,
        name: this.outlet.name,
        description: this.outlet.description,
      })
      .then((response) => {
        if(!response.data.success){
        alert('unable to save item')
        return
        }

        this.$emit('outlet-created', {
          id: outlet.id,
          name: this.outlet.name,
          description: this.outlet.description,
          access_code: this.outlet.access_code,
        });

        this.$swal.fire(
        'Saved!',
        'Language and contents added',
        'success'
        )

      })
    },
    submit(evt) {
      if(this.validateSchedules) {
        const loader = this.$loading.show({
          container: this.$refs.content,
          canCancel: false,
        });
        
        this.$store.dispatch('addHotelOutlet', {
          hotel: this.hotel,
          outlet: this.outlet,
        })
          .then((outlet) => {
            setTimeout(() => {
              this.$modal.hide('hotel-outlet-add');
              this.addMultiLang(outlet)
            }, 1);
          })
          .catch((error) => {
            console.log(`/api/hotel/${this.hotel.id}/outlets:add error`, error.response);
          })
          .finally(() => {
            loader.hide();
          });
      }

      if (evt) {
        evt.preventDefault();
        return false;
      }
    },
    close() {
      this.$modal.hide('hotel-outlet-add');
    },
    clear() {
      this.outlet = {
        name: '',
        description: '',
        access_code: '',
        schedules: [{
          day: "",
          time: {
            start: "08:00:00",
            end: "20:00:00",
          },
        }],
        images: [],
      };
    },
    addSchedule() {
      this.outlet.schedules.push({
        day: "",
        time: {
          start: "08:00:00",
          end: "20:00:00",
        },
      });
    },
    removeOutletSchedule(index) {
      this.outlet.schedules.splice(index, 1);
    },
    removeImage(index) {
      this.outlet.images.splice(index, 1);
    },
  },

  mounted() {
    this.getSupportedLanguages()
  },

}
</script>