<template>
  <div class="col-sm-12">
    <h2 style="font-size: 24px; margin-top: 20px">
      Notifications
    </h2>

    <br>
      <fieldset>
        <div class="row">
        <div class="col-md-6">
          <div class="form-group required ">
            <label for="notifName">Notification name:</label>
            <input type="text" required v-model="notifName" class="form-control"  />
          </div>
          <div class="box">
          <div class="form-group block">
            <label> <b> Image options (optional) </b> </label>
            <b-radio v-model="imageUrlOrUpload"
                name="name"
                value="url">
                Image Link
            </b-radio>
            <b-radio v-model="imageUrlOrUpload"
                name="name"
                value="upload">
                Upload Image
            </b-radio>
          </div>
          <div v-if="imageUrlOrUpload === 'url'" class="form-group ">
            <label for="imageUrl">Image url:</label>
            <input type="text" class="form-control" v-model="imageUrl"/>
          </div>
          <div v-if="imageUrlOrUpload === 'upload'" class="form-group">
              <input type="file" id="image" class="form-control d-none" accept="image/*" ref="imageSelector" @change="processSelectedImage"/>
              <button type="button" class="btn btn-secondary" @click="selectImage">Select Image</button>
              <small class="form-text text-muted">Minimum image size is 640 width and 260 height with ideal content ratio is 5:2.</small>
              <div class="row">
                <div class="col-sm-12 col-md-6 offset-md-3 text-center my-3" >
                  <img :src="image" width="100%"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group required">
            <label for="selectedAppScreen">App screen</label>
            <select v-model="selectedAppScreen"  class="form-control" @change="onSelectedAppScreenChange">
              <option :value="selectedAppScreen" disabled >{{ selectedAppScreen }}</option>
              <option v-for="(item, key) in appScreens" :key="key" :value="item.value">{{item.name}}</option>
            </select>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div v-if="isSelectHotelVisible" class="form-group required">
                <label for="selectedHotel">Select Hotel:</label>
                <select v-model="selectedHotel"  class="form-control" @change="onSelectedHotelChange">
                  <option v-for="(item, key) in hotels" :key="key" :value="item.id">{{item.name}}</option>
                </select>
              </div> 
            </div>
            <div class="col-sm-6">
              <div v-if="isSelectOutletVisible" class="form-group required">
                <label for="selectedOutlet">Select Hotel Outlet:</label>
                <select v-model="selectedOutlet"  class="form-control" @change="onSelectedOutletChange">
                  <option v-for="(item, key) in outlets" :key="key" :value="item.id">{{item.name}}</option>
                </select>
              </div>
            </div>  
          </div>
        </div>
        </div>

      <div style="margin-top: 20px" class="row">
        <div class="col-md-6">
          <label for="name">Language:</label>
          <select class="form-control required" v-model="selectedLanguage" @change="onSelectedLanguageChange">
            <option v-for="(item, key) in tempSupportedLanguages" :value="item.description" :key="key">{{item.description}} </option>
          </select>
          <button style="margin-bottom: 20px; margin-top: 20px" class="button is-primary" @click="onAddNewClick">Add new language for notification</button>
        </div>
      </div>

      <div class="grid-wrapper">
        <div v-for="(item, key) in multiLangNotifItems" :key="key" class="box">
          <label for="title"> Language: <b> {{item.lang}} </b> </label>
          <div style="margin-top: 10px" class="form-group required">
            <label for="title">Title:</label>
            <input type="text" required class="form-control" v-model="item.title" />
          </div>
          <div class="form-group required">
            <label for="message">Message:</label>
            <textarea required class="form-control" v-model="item.message"/>
          </div>
          <button class="button is-danger" @click="onRemoveItemClick(item)">Remove</button>
        </div>
      </div>

      <div style="margin-top: 30px" class="form-group required col-sm-6">
           <button class="button is-primary pull-right" @click="onCreateNotificationClick"> Send Notification </button>
        </div>

      </fieldset>

  <div style="margin-top: 30px" class="field box">

     <label style="margin-bottom: 20px"> <b> Sent notification history </b> </label>

    <b-table
      :data="filtered"
      :paginated="true"
      per-page="5"
      current-page.sync="1"
      :pagination-simple="false"
      pagination-position="bottom"
      default-sort-direction="asc"
      sort-icon="chevron-up"
      sort-icon-size="is-small"
      :striped="true"
      :hoverable="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :mobile-cards="false"

      detailed
      detail-key="id"
      :show-detail-icon="true"
      icon-pack="fas"
    >
        <b-table-column
          field="notif_name"
          label="Notification name"
          sortable
          v-slot="props"
        >
          {{ props.row.notif_name }}
        </b-table-column>

        <b-table-column
          field="app_screen"
          label="App screen"
          sortable
          v-slot="props"
        >
          {{ props.row.app_screen }}
        </b-table-column>

        <b-table-column centered width="5" label="Actions"  v-slot="props">
          <div class="buttons is-centered is-inline-block">
           <button class="button is-primary pull-right" @click="onDuplicateClick(props)"> Duplicate </button>
          </div>
        </b-table-column>

           <template #detail="props">
                <article class="media">
                    <div class="media-content">
                        <div class="content ">
                          <div class="box grid-wrapper">
                            <div class="box" v-for="(item, key) in props.row.multi_langs" :key=key>
                               <label for="title"> Language: <b> {{isoCodeDescription(item.iso_code)}} </b> </label>
                                <div style="margin-top: 10px" class="form-group required">
                                  <label for="title">Title:</label>
                                  <input type="text" required class="form-control" disabled="true" v-model="item.title" />
                                </div>
                                <div class="form-group required">
                                  <label for="message">Message:</label>
                                  <textarea required class="form-control"  disabled="true" v-model="item.description"/>
                                </div>
                            </div>
                          </div>
                            
                        </div>
                        
                    </div>
                </article>
            </template>

    </b-table>
  
  </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "../Store";
import WebUtils from '../utils/WebUtils';
import LoadingOverlay from "vue-loading-overlay";
import axios from "axios"

export default {
  data() {
    return {
      notifications: [],
      search: '',
      notifName: '',
      title: '',
      message: '',
      imageUrl: '',
      selectedAppScreen: 'Choose option',
      selectedHotel: 'Choose option',
      selectedOutlet: 'Choose option',

      appScreens: [
        {
          id: 1,
          name: 'Home Screen',
          value: 'HomeScreen',
        },
        {
          id: 2,
          name: 'Hotel Screen',
          value: 'HotelScreen',
        },
        {
          id: 3,
          name: 'Outlet Screen',
          value: 'OutletScreen',
        },
      ],

      hotels: [],
      outlets: [],
      multiLangNotifItems: [],

      defaultLang: '',
      supportedLanguages: [],
      tempSupportedLanguages: [],

      isSelectHotelVisible: false,
      isSelectOutletVisible: false,

      imageUrlOrUpload: '',
      selectedLanguage: '',
      notifImage: null,

    };
  },

  computed: {
    filtered() {
      if (!this.search.trim()) {
        return this.notifications
      }
      return this.notifications.filter(data =>
        Object.values(data).some(val =>
        String(val)
          .toLowerCase()
          .includes(this.search.toLowerCase())
        )
      )
    },

    image() {
      try {
        if (this.notifImage) {
           return URL.createObjectURL(this.notifImage);
        }
      }
      catch (ex) {
        console.log("no image url");
      }

      return null;
    },
     
  },

  methods: {

    selectImage() {
      this.$refs.imageSelector.click();
    },

    processSelectedImage(evt) {
      this.notifImage = Object.values(evt.target.files)[0]
      console.info(this.notifImage)
    },

    isoCodeDescription(isoCode){
      let description = _.find(this.supportedLanguages, ['iso_code', isoCode]).description

      if(description !== null){
        return description
      }

      return ''
    },
    
    getSettings(context, settingId) {
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/settings/1`)
      .then(response => {
        if(response.data.default_lang_iso_code !== ''){
        this.defaultLang = response.data.default_lang_iso_code
        }
      })
    },

    getSupportedLanguages(){
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/languages`)
      .then(response => {
        this.supportedLanguages = response.data.items
        this.tempSupportedLanguages = _.clone(this.supportedLanguages);

        this.getSettings()

        this.multiLangNotifItems.push({
          lang: _.find(this.supportedLanguages, ['iso_code', this.defaultLang]).description,
          iso_code: this.defaultLang,
          title: '',
          message: '',
        })

        this.tempSupportedLanguages.splice(_.findIndex(this.tempSupportedLanguages, ['iso_code', this.defaultLang]), 1)

      })
    },
     
    getHotels(){
       const loader = this.$loading.show({
        container: this.$el,
        canCancel: false,
      });

      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/hotels`)
      .then(response => {

        let hotels = []

        _.each(response.data.items, (item) => {

        let multiLang = item.multi_langs.find(items => items.iso_code === this.defaultLang)

        if(!_.isEmpty(multiLang)){
          hotels.push({
          id: item.id,
          name: multiLang.name,
          description: multiLang.description,
          access_code: item.access_code,
          ratio: item.ratio,
          location:{
            lat: item.location.lat,
            lng: item.location.lng,
            location_address: multiLang.location_address
          },
          hotel_currency: item.hotel_currency,
          currency_symbol: item.currency_symbol,
          })
        }
        })

        this.hotels = hotels

        loader.hide()
      })
    },

    getOutlets(hotelId){
       const loader = this.$loading.show({
        container: this.$el,
        canCancel: false,
      });

      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/hotel/${hotelId}/outlets`)
      .then(response => {

        console.info(response.data)

        let outlets = []

        _.each(response.data.items, (item) => {

        let multiLang = item.multi_langs.find(items => items.iso_code === this.defaultLang)

        if(!_.isEmpty(multiLang)){
          outlets.push({
          id: item.id,
          name: multiLang.name,
          description: multiLang.description,
          access_code: item.access_code,
          })
        }
        })

        this.outlets = outlets

       console.info('outlets', outlets)


        loader.hide()
      })
    },  

    getNotifications(){
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/sent-notifications`)
      .then(response => {
        this.notifications = response.data.items
        console.info('notifications', this.notifications)
      })
    },

    onSelectedAppScreenChange(e){
      this.selectedAppScreen = e.target.value

      switch(e.target.value){

        case "HomeScreen" :
        this.isSelectHotelVisible = false
        this.isSelectOutletVisible = false
        break;

        case "HotelScreen" :
        this.isSelectHotelVisible = true
        this.isSelectOutletVisible = false
        break

        case "OutletScreen" :
        this.isSelectHotelVisible = true
        this.isSelectOutletVisible = true
        break  

      }
    },

    onSelectedHotelChange(e){
      this.selectedHotel = e.target.value

      this.getOutlets(e.target.value)
    },

    onSelectedOutletChange(e){
      this.selectedOutlet = e.target.value
    },

    onSelectedLanguageChange(e){
      this.selectedLanguage  = e.target.value
    },

    onAddNewClick(){
      if(this.selectedLanguage === '') return
      
      const isoCode =  _.find(this.supportedLanguages, ['description', this.selectedLanguage]).iso_code

      this.multiLangNotifItems.push({
        lang: this.selectedLanguage,
        iso_code: isoCode,
        title: '',
        message: '',
      })

      this.tempSupportedLanguages.splice(_.findIndex(this.tempSupportedLanguages, ['iso_code', isoCode]), 1)

      this.selectedLanguage = ''

    },

    onRemoveItemClick(item){
      this.multiLangNotifItems.splice(_.findIndex(this.multiLangNotifItems, ['iso_code', item.iso_code]), 1)

      this.tempSupportedLanguages.push(_.find(this.supportedLanguages, ['iso_code', item.iso_code]))
    },

    onCreateNotificationClick(){

      if(this.notifName == ''){
        return
      }

      if(this.selectedAppScreen === 'Choose option'){
        return
      }

      if(this.selectedAppScreen === 'HotelScreen'){
        if(this.selectedHotel === 'Choose option'){
          return
        }
      }

      if(this.selectedAppScreen === 'OutletScreen'){
        if(this.selectedHotel === 'Choose option' || this.selectedOutlet === 'Choose option'){
          return
        }
      }

      if(_.size(this.multiLangNotifItems) == 0){
        return
      }else{
        let isDefaultItemEmpty =  _.findIndex(this.multiLangNotifItems, ['iso_code', this.defaultLang])

        if(isDefaultItemEmpty == -1){
          return
        }else{
          let item = _.find(this.multiLangNotifItems, ['iso_code', this.defaultLang])

          if(item.title == '' || item.message == ''){
            return
          }
        }
      }

     

      // let isDefaultItemEmpty =  _.findIndex(this.multiLangNotifItems, ['iso_code', this.defaultLang])

      // // if()

      // console.info(_.size(this.multiLangNotifItems))
      // console.info(this.defaultLang)

      // console.info('isDefaultItemEmpty', isDefaultItemEmpty)

      // return

      const loader = this.$loading.show({
        container: this.$el,
        canCancel: false,
      });

      let data = new FormData();
      let imageType = ''

      if(this.imageUrlOrUpload === 'url' && this.imageUrl !== ''){
        imageType = 'url'
        data.append('image_url', this.imageUrl)
      }

      if(this.imageUrlOrUpload === 'upload' && this.notifImage !== undefined){
        imageType = 'upload'
        data.append('image', this.notifImage)
      }
      
      data.append('image_type', imageType)
      data.append('notif_name', this.notifName)
      data.append('app_screen', this.selectedAppScreen)
      data.append('hotel_id', this.selectedHotel)
      data.append('outlet_id', this.selectedOutlet)
      data.append('notif_multi_lang', JSON.stringify(this.multiLangNotifItems))

      for (var pair of data.entries())
      {
        console.log(pair) 
      }

      axios.post(`${process.env.MIX_API_SERVER_PATH}/api/firebase-messaging`, data, {'Content-Type': 'multipart/form-data' })
      .then(response => {
      loader.hide()

      if(response.data.success){
        this.$swal.fire(
        'Sent!',
        response.data.message,
        'success'
        )
      }
      })
    },

    onDuplicateClick(props){
      this.notifName = props.row.notif_name
      this.selectedAppScreen = _.find(this.appScreens, ['value', props.row.app_screen]).name

      this.tempSupportedLanguages = _.clone(this.supportedLanguages)

      let multiLangItem = []

      _.each(props.row.multi_langs, (item) => {
        multiLangItem.push({
          lang: this.isoCodeDescription(item.iso_code),
          iso_code: item.iso_code,
          title: item.title,
          message: item.description,
        })
        
        this.tempSupportedLanguages.splice(_.findIndex(this.tempSupportedLanguages, ['iso_code', item.iso_code]), 1)
      })

      this.multiLangNotifItems = multiLangItem
      
    }

  },

  mounted() {
    this.getSettings()
    this.getSupportedLanguages()
    this.getHotels()
    this.getNotifications()

  },
  
}
</script>

<style scoped>
.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

</style>