<template>
    <nav class="nav navbar-expand-lg navbar-light bg-light">
        <router-link class="navbar-brand" to="/">Loyalty App</router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto" v-if="isLoggedIn">
                <li class="navbar-item">
                    <router-link class="nav-link" to="/">Home</router-link>
                </li>
                <li class="navbar-item">
                    <router-link class="nav-link" to="/hotels">Hotels</router-link>
                </li>
                <li class="navbar-item">
                    <router-link class="nav-link" to="/customers">Customers</router-link>
                </li>
                <li class="navbar-item">
                    <router-link class="nav-link" to="/pages">Pages</router-link>
                </li>
                <li class="navbar-item">
                    <router-link class="nav-link" to="/settings">Settings</router-link>
                </li>
                <li class="navbar-item">
                    <router-link class="nav-link" to="/currency-settings">Currency Settings</router-link>
                </li>
                <li class="navbar-item">
                    <router-link class="nav-link" to="/export-data">Export Data</router-link>
                </li>
                <li class="navbar-item">
                    <router-link class="nav-link" to="/notifications">Notifications</router-link>
                </li>
            </ul>
            <div class="mr-auto" v-if="!isLoggedIn"></div>
            <ul class="navbar-nav">
                <li class="navbar-item" v-if="!isLoggedIn">
                    <router-link class="nav-link" to="/login">Login</router-link>
                </li>
                <li class="navbar-item" v-if="isLoggedIn">
                    <router-link class="nav-link" to="/logout">Logout</router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters([
            'isLoggedIn',
        ]),
    }
}
</script>