<template>
    <div class="col-sm-12 py-3">
        <div class="row" v-if="images.length > 0">
            <div class="col-sm-6 col-md-3 my-3" v-for="image in images" :key="image.id">
                <img :src="image.url" width="100%"/>
            </div>
        </div>
         <div class="row" v-else>
             <div class="col-sm-12">
                 <p class="text-center"> -- No Images -- </p>
             </div>
         </div>
    </div>
</template>

<script>
export default {
    props: {
        hotel: null,
    },

    computed: {
        images() {
            if (this.hotel) {
                return this.hotel.images.data;
            }
            return [];
        }
    },
}
</script>z