<template>
    <div class="main-content col-sm-12">
        <h2>Hotel Details</h2>
        <div v-if="hotel">
            <dl class="row">
                <dt class="col-sm-3">Name:</dt>
                <dd class="col-sm-9">{{ hotel.name }}</dd>
                <dt class="col-sm-3">Description:</dt>
                <dd class="col-sm-9">{{ hotel.description }}</dd>
                <dt class="col-sm-3">Authorization Code:</dt>
                <dd class="col-sm-9">{{ hotel.access_code }}</dd>
                <dt class="col-sm-3">Points Ratio:</dt>
                <dd class="col-sm-9">{{ hotel.ratio }}</dd>
                <dt class="col-sm-3">Currency:</dt>
                <dd class="col-sm-9">{{ hotel.hotel_currency }}</dd>
                <dt class="col-sm-12">Location:</dt>
                <dd class="col-sm-12">
                    <dl class="row ml-3">
                        <dt class="col-sm-12">Address:</dt>
                        <dd class="col-sm-12">{{ hotel.location.location_address }}</dd>
                        <dd class="col-sm-12">
                            <dl class="row">
                                <dt class="col-sm-3">Latitude:</dt>
                                <dd class="col-sm-9">{{ hotel.location.lat }}</dd>
                                <dt class="col-sm-3">Longitude:</dt>
                                <dd class="col-sm-9">{{ hotel.location.lng }}</dd>
                            </dl>
                        </dd>
                    </dl>
                </dd>
            </dl>
            <div class="btn-group d-flex tab-menu" role="group">
                <router-link class="btn btn-secondary" :class="{'current-tab': $route.name == 'hotel_view'}" type="button" :to="{ name: 'hotel_view', params: ['hotelId'] }">Images</router-link>
                <router-link class="btn btn-secondary" :class="{'current-tab': $route.name == 'hotel_view_outlets'}" type="button" :to="{ name: 'hotel_view_outlets', params: ['hotelId'] }">Outlets</router-link>
                <router-link class="btn btn-secondary" :class="{'current-tab': $route.name == 'hotel_view_deals'}" type="button" :to="{ name: 'hotel_view_deals', params: ['hotelId'] }">Room Deals</router-link>
            </div>
            <router-view :hotel="hotel"></router-view>
        </div>
        <DealView></DealView>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DealView from "../components/DealView";
import LoadingOverlay from "vue-loading-overlay";
import axios from "axios"

export default {
    components: {
        DealView,
    },

    data() {
        return {
            hotel: null,
            defaultLang: '',
        };
    },

    methods: {

        getSettings(context, settingId) {
          axios.get(`${process.env.MIX_API_SERVER_PATH}/api/settings/1`)
            .then(response => {
              if(response.data.default_lang_iso_code !== ''){
                this.defaultLang = response.data.default_lang_iso_code
              }
            })
        },
    },

    mounted() {

        const loader = this.$loading.show({
            container: this.$el,
            canCancel: false,
        });

        this.getSettings()

        var hotel = this.getHotel;

        const hotelId = this.$route.params.hotelId;
        
        if (!hotel) {
            
            this.$store.dispatch('loadHotel', hotelId)
                .then((item) => {

                    let multiLang = item.multi_langs.find(items => items.iso_code === this.defaultLang)

                    if(!_.isEmpty(multiLang)){
                        this.hotel = {
                            id: item.id,
                            name: multiLang.name,
                            description: multiLang.description,
                            access_code: item.access_code,
                            ratio: item.ratio,
                            location:{
                            lat: item.location.lat,
                            lng: item.location.lng,
                            location_address: multiLang.location_address
                            },
                            hotel_currency: item.hotel_currency,
                            currency_symbol: item.currency_symbol,
                            images: item.images
                        }
                    }
                })
                .catch(() => {})
                .finally(() => {
                    loader.hide();
                });
        }
        else {
           this.$store.dispatch('loadHotel', hotelId)
                .then((item) => {

                    let multiLang = item.multi_langs.find(items => items.iso_code === this.defaultLang)

                    if(!_.isEmpty(multiLang)){
                        this.hotel = {
                            id: item.id,
                            name: multiLang.name,
                            description: multiLang.description,
                            access_code: item.access_code,
                            ratio: item.ratio,
                            location:{
                            lat: item.location.lat,
                            lng: item.location.lng,
                            location_address: multiLang.location_address
                            },
                            hotel_currency: item.hotel_currency,
                            currency_symbol: item.currency_symbol,
                            images: item.images
                        }
                    }
                })
                .catch(() => {})
                .finally(() => {
                    loader.hide();
                });
        }
    },

    computed: {
        ...mapGetters(['getHotel'])
    }
}
</script>
<style scoped>
div.main-content {
    min-height: 480px;
}
</style>