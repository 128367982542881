<template>
    <div class="col-sm-12 py-3">
        <div class="row">
            <div class="col-sm-12 mb-3 text-right">
                <button type="button" class="btn btn-primary" @click="showAddModal"> <i class="fas fa-plus-circle"></i> Add Outlet </button>
            </div>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Description</th>
                        <th scope="col"> </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="outlet-entry" v-for="outlet in outlets" :key="outlet.id">
                        <td>{{ outlet.id }}</td>
                        <td>
                            <router-link :to="{ name: 'hotel_outlet_view', params: { hotelId: hotel.id, outletId: outlet.id, outlet } }"> {{ outlet.name }} </router-link>
                        </td>
                        <td>
                            {{ outlet.description }}
                        </td>
                        <td>
                            <a href="#" class="link" title="Edit Outlet" @click="(evt) => { editOutlet(outlet); evt.preventDefault(); return false; } "> <i class="far fa-edit"></i> </a>
                            <a href="#" class="link" title="Delete Outlet" @click="(evt) => { deleteOutlet(outlet); evt.preventDefault(); return false; } "> <i class="far fa-trash-alt"></i> </a>
                        </td>
                    </tr>
                    <tr v-if="!hasEntries">
                        <td colspan="4" class="text-center">
                            -- No outlets yet --
                        </td>
                    </tr>
                    <tr class="paginator" v-if="totalPages > 1">
                        <td colspan="4" class="text-center">
                            <router-link class="link" v-if="currentPage > 1" :to="{ name: 'hotel_view_outlets', params: [':hotelId'], query: { page: 1 } }">&lt;&lt;</router-link>
                            <router-link class="link" v-if="currentPage > 1" :to="{ name: 'hotel_view_outlets', params: [':hotelId'], query: { page: currentPage - 1 } }"> &lt; </router-link>
                            <router-link class="link" v-for="page in getPages" :key="page" :to="{ name: 'hotel_view_outlets', params: [':hotelId'], query: { page } }">{{ page }}</router-link>
                            <router-link class="link" v-if="currentPage != totalPages" :to="{ name: 'hotel_view_outlets', params: [':hotelId'], query: { page: currentPage + 1 } }"> &gt; </router-link>
                            <router-link class="link" v-if="currentPage != totalPages" :to="{ name: 'hotel_view_outlets', params: [':hotelId'], query: { page: totalPages } }">&gt;&gt;</router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <HotelOutletAdd :hotel="hotel" @outlet-created="outletCreated"></HotelOutletAdd>
        <HotelOutletEdit :baseOutlet="editableOutlet" @outletEdited="outletEdited"></HotelOutletEdit>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import HotelOutletAdd from "./HotelOutletAdd";
import HotelOutletEdit from "../components/HotelOutletEdit";
import axios from "axios"

export default {
    props: {
        hotel: null,
    },

    data() {
        return {
            outlets: [],
            defaultLang: '',
            editableOutlet: null,
        };
    },

    components: {
        HotelOutletAdd,
        HotelOutletEdit,
    },

     mounted() {
        this.getSettings()
        this.getOutlets()
    },

    methods: {
         getOutlets(){
           const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            axios.get(`${process.env.MIX_API_SERVER_PATH}/api/hotel/${this.hotel.id}/outlets`)
            .then(response => {

                console.info(response.data)

              let outlets = []

              _.each(response.data.items, (item) => {

                let multiLang = item.multi_langs.find(items => items.iso_code === this.defaultLang)

                if(!_.isEmpty(multiLang)){
                  outlets.push({
                    id: item.id,
                    name: multiLang.name,
                    description: multiLang.description,
                    access_code: item.access_code,
                  })
                }
              })

              this.outlets = outlets

             console.info('outlets', outlets)


              loader.hide()
            })
        },

        getSettings(context, settingId) {
          axios.get(`${process.env.MIX_API_SERVER_PATH}/api/settings/1`)
            .then(response => {
              if(response.data.default_lang_iso_code !== ''){
                this.defaultLang = response.data.default_lang_iso_code
              }
            })
        },

        loadOutlets(page = 1) {
            const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            this.$store.dispatch('loadHotelOutlets', {
                hotelId: this.hotel.id,
                page
            })
                .then((response) => {
                    console.log("received outlets", page, this.getHotelOutlets);
                    this.outlets = this.getHotelOutlets;
                })
                .finally(() => {
                    loader.hide();
                });
        },
        showAddModal() {
            this.$modal.show('hotel-outlet-add');
        },
        outletCreated(outlet) {
            console.log("emit:outlet-created", outlet);
            this.outlets.push(outlet);
        },
        outletEdited(outlet) {
            this.editableOutlet = null;
        },
        viewOutlet(outlet) {
            this.$router.push({ name: 'hotel-outlet-view', params: { hotelId: hotel.id, outletId: outlet.id, outlet }});
        },
        editOutlet(outlet) {
            // this.editableOutlet = outlet;
            // setTimeout(() => {
            //     this.$modal.show('hotel-outlet-edit');
            // }, 1);

            this.$router.push({ name: 'hotel_outlet_edit', params: { hotelId: this.$route.params.hotelId, outletId: outlet.id,} })

            
        },
        deleteOutlet(outlet) {
            if (confirm(`Are you sure you want to delete outlet: ${outlet.name}?`)) {
                const loader = this.$loading.show({
                    container: this.$el,
                    canCancel: false,
                });
                
                this.$store.dispatch("deleteHotelOutlet", {
                    hotelId: this.hotel.id,
                    outletId: outlet.id,
                })
                    .then((success) => {

                    })
                    .catch((error) => {

                    })
                    .finally(() => {
                        loader.hide();
                    });
            }
        },

        viewItem(item){
             this.$router.push({ name: 'hotel_view', params: { hotelId: item.id, hotel: item } })
        },

        editItem(item){
             this.$router.push({ name: 'hotel_outlet_edit', params: { hotelId: this.$route.params.hotelId } })
        },

        deleteItem(item){
            axios.delete(`${process.env.MIX_API_SERVER_PATH}/api/hotels/${item.id}`)
            .then(response => {
                this.hotels.splice(this.hotels.findIndex(items => items.id === item.id), 1);
                alert('Hotel deleted')
            })
        },
    },

    computed: {
        ...mapGetters([
            'getHotelOutlets'
        ]),
        getPages() {
            try {
                const currentPage = this.outlets.pagination.current_page;
                const totalPages = this.outlets.pagination.total_pages;
                const pages = [];
                for (var x = Math.max(1, currentPage - 2); x <= Math.min(totalPages, currentPage + 2); x++) {
                    pages.push(x);
                }
                return pages;
            }
            catch(ex) {
                return [];
            }
        },
        currentPage() {
            try {
                return this.outlets.pagination.current_page;
            }
            catch(ex) {
                return 1;
            }
        },
        totalPages() {
            try {
                return this.outlets.pagination.total_pages;
            }
            catch(ex) {
                return 1;
            }
        },
        hasEntries() {
            try {
                return Object.keys(this.outlets).length > 0;
            }
            catch(ex) {
                return false;
            }
        }
    },

    watch: {
        hotel(newValue, oldValue) {
            this.hotel = newValue;
        },
       
        '$route.query.page'(newValue, oldValue) {
            if (!newValue) {
                newValue = 1;
            }
            this.getOutlets();
        },
    }
}
</script>