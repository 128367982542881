<template>
  <div>
    <div class="is-right buttons">
      <button v-if="tab === 1" type="button" class="button is-primary" @click="showClaimOrRedeemModal">Add claim points</button>
      <button v-if="tab === 2" type="button" class="button is-primary" @click="showClaimOrRedeemModal">Add redeem points</button>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            ID
          </th>
          <th scope="col">
            Name
          </th>
          <th scope="col">
            Currency ISO Code
          </th>
          <th scope="col">
            Amount Spent
          </th>
          <th scope="col">
            Points
          </th>
          <th scope="col">
            Transaction Date
          </th>
          <th v-if="this.tab === 1" scope="col">
            Expiry Date
          </th>
          <th scope="col">
            Points Collection Type
          </th>
        </tr>
      </thead>
      <tbody v-if="this.tab === 1">
        <tr v-for="data in history" :key="data.id">
          <td>{{ data.id }}</td>
          <td>{{ data.name }}</td>
          <td>{{ data.currency_iso_code }}</td>
          <td >{{ data.currency_symbol + data.amount_spent }}</td>
          <td >{{ data.points_value }}</td>
          <td> {{ data.claimed_date }}</td>
          <td >{{ data.expiry_date }}</td>
          <td> {{ data.transaction_type}}</td>
        </tr>
        <tr v-if="!hasEntries">
          <td colspan="6" class="text-center">
            -- No data yet --
          </td>
        </tr>
        <tr class="paginator" v-if="canLoadMore">
          <td colspan="5" class="text-center">
            <router-link class="link" v-if="currentPage != totalPages" :to="{ name: 'customer_view', query: { tab, page: currentPage + 1 } }"> Load More </router-link>
          </td>
        </tr>
      </tbody>
      <tbody v-if="this.tab === 2">
        <tr v-for="data in history" :key="data.id">
          <td>{{ data.id }}</td>
          <td>{{ data.name }}</td>
          <td>{{ data.currency_iso_code }}</td>
          <td >{{ data.currency_symbol + data.amount_spent }}</td>
          <td >{{ data.points_value }}</td>
          <td> {{ data.redeemed_date }}</td>
          <td> {{ data.transaction_type}}</td>
        </tr>
        <tr v-if="!hasEntries">
          <td colspan="6" class="text-center">
            -- No data yet --
          </td>
        </tr>
        <tr class="paginator" v-if="canLoadMore">
          <td colspan="5" class="text-center">
            <router-link class="link" v-if="currentPage != totalPages" :to="{ name: 'customer_view', query: { tab, page: currentPage + 1 } }"> Load More </router-link>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
      <div>
      <b-modal  
        ref="claimOrRedeemModal" 
        id="claimOrRedeemModal" 
        hide-backdrop content-class="shadow" 
        title="Add claim points"
        ok-title="Save"
        @ok="saveClaimOrRedeemPoints">

        <div class="form-group required">
        <label for="name">Date:</label>
        <date-picker format="YYYY-MM-DD" valueType="format" v-model="mDate" :input-attr="{ required: true }"></date-picker>
        </div>
        <div class="form-group required">
        <label for="selectedAppScreen">Points from</label>
        <select v-model="selectedAppScreen"  class="form-control" @change="onSelectedAppScreenChange">
        <option :value="selectedAppScreen" disabled >{{ selectedAppScreen }}</option>
        <option v-for="(item, key) in appScreens" :key="key" :value="item.value">{{item.name}}</option>
        </select>
        </div>

         <div class="form-group required">
        <label for="selectedHotel">Select Hotel:</label>
        <select v-model="selectedHotel"  class="form-control" @change="onSelectedHotelChange">
          <option v-for="(item, key) in hotels" :key="key" :value="item.id">{{item.name}}</option>
        </select>
         </div> 

         <div v-if="isSelectOutletVisible" class="form-group required">
        <label for="selectedOutlet">Select Hotel Outlet:</label>
        <select v-model="selectedOutlet"  class="form-control" @change="onSelectedOutletChange">
          <option v-for="(item, key) in outlets" :key="key" :value="item.id">{{item.name}}</option>
        </select>
        </div>
         <div v-if="tab == 1" class="form-group required">
        <label for="name">Receipt Number:</label>
        <input type="number" class="form-control" v-model="mReceiptNumber"/>
        </div>
        <div class="form-group required">
        <label for="name">Bill Amount:</label>
        <input type="number" class="form-control" v-model="mBillAmount"/>
        </div>
        <div class="form-group required">
        <label for="description">Staff Authorization Code:</label>
        <input type="text" class="form-control" v-model="mStaffAuthCode"/>
        </div>
      </b-modal>
      </div>

      
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from "moment";
import axios from "axios"

export default {
  props: {
    tab: {
      required: true,
    },
    action: {
      required: true,
    },
  },

  components: {
    DatePicker,
  },

  data() {
    return {
      customerId: null,
      history: {},
      pagination: {},
      pageParam: 1,
      date: {
        start: null,
        end: null,
      },

      mReceiptNumber: '',
      mDate: '',
      mBillAmount: '',
      mStaffAuthCode: '',
      mSelectedCurrency: 'Choose Option',
      hotels: [],
      outlets: [],
      selectedHotel: 'Choose option',
      selectedOutlet: 'Choose option',
      isSelectOutletVisible: false,
      defaultLang: '',

      appScreens: [
        {
          id: 1,
          name: 'Hotel Screen',
          value: 'HotelScreen',
        },
        {
          id: 2,
          name: 'Outlet Screen',
          value: 'OutletScreen',
        },
      ],

      selectedAppScreen: 'Choose option',
      hotelOutlet: '',
      hotelOutletId: '',
      


    };
  },

  async mounted() {
    const {
      customerId,
    } = this.$route.params;

    this.customerId = customerId;

    const {
      tab = 0,
      page = 1,
    } = this.$route.query;

    if (this.tab == tab) {
      this.pageParam = page;

      this.goToPage(1);
    }

    this.goToPage(1);

    await this.getSettings()
    this.getHotels()
    this.getOutlets()

  },

  methods: {
     disabledDateStart(date) {
      const dateMoment = moment(date);
      const nowMoment = moment().startOf('day');
      var retVal = false;
      if (this.date.end) {
        const endMoment = moment(this.date.end);
        retVal = dateMoment.isAfter(endMoment);
      }
      else {
        retVal = dateMoment.isAfter(nowMoment);
      }
      return retVal;
    },

    disabledDateEnd(date) {
      const dateMoment = moment(date);
      const nowMoment = moment().startOf('day');
      var retVal = false;
      if (this.date.start) {
        const startMoment = moment(this.date.start);
        retVal = dateMoment.isBefore(startMoment) || dateMoment.isAfter(nowMoment);
      }
      else {
        retVal = dateMoment.isAfter(nowMoment);
      }
      return retVal;
    },

    goToPage(page) {
      const loader = this.$loading.show({
          container: this.$el,
          canCancel: false,
        });
        this.$store.dispatch(this.action, {
          customerId: this.customerId,
          page
        })
          .then((data) => {
            this.history = Object.assign({}, this.history, data.history);
          })
          .finally(() => {
            loader.hide();
      });
    },

    async getSettings() {
    axios.get(`${process.env.MIX_API_SERVER_PATH}/api/settings/1`)
    .then(response => {
      if(response.data.default_lang_iso_code !== ''){
      this.defaultLang = response.data.default_lang_iso_code
      }
    })
    },
    
    getHotels(){
    const loader = this.$loading.show({
      container: this.$el,
      canCancel: false,
    });

    axios.get(`${process.env.MIX_API_SERVER_PATH}/api/hotels`)
    .then(response => {

      let hotels = []

      _.each(response.data.items, (item) => {

      let multiLang = item.multi_langs.find(items => items.iso_code === this.defaultLang)

      if(!_.isEmpty(multiLang)){
      hotels.push({
      id: item.id,
      name: multiLang.name,
      description: multiLang.description,
      access_code: item.access_code,
      ratio: item.ratio,
      location:{
        lat: item.location.lat,
        lng: item.location.lng,
        location_address: multiLang.location_address
      },
      hotel_currency: item.hotel_currency,
      currency_symbol: item.currency_symbol,
      })
      }
      })

      this.hotels = hotels
      console.info('hotels', hotels)

      loader.hide()
    })
    },

    getOutlets(hotelId){
    const loader = this.$loading.show({
      container: this.$el,
      canCancel: false,
    });

    axios.get(`${process.env.MIX_API_SERVER_PATH}/api/hotel/${hotelId}/outlets`)
    .then(response => {

      console.info(response.data)

      let outlets = []

      _.each(response.data.items, (item) => {

      let multiLang = item.multi_langs.find(items => items.iso_code === this.defaultLang)

      if(!_.isEmpty(multiLang)){
      outlets.push({
      id: item.id,
      name: multiLang.name,
      description: multiLang.description,
      access_code: item.access_code,
      })
      }
      })

      this.outlets = outlets

    console.info('outlets', outlets)


      loader.hide()
    })
    },  

    onSelectedAppScreenChange(e){
      this.selectedAppScreen = e.target.value
      switch(e.target.value){
        case "HotelScreen" :
        this.isSelectOutletVisible = false
        break

        case "OutletScreen" :
        this.isSelectOutletVisible = true
        break  
      }
    },

    onSelectedHotelChange(e){
      this.selectedHotel = e.target.value
      this.getOutlets(e.target.value)
    },

    onSelectedOutletChange(e){
      this.selectedOutlet = e.target.value
    },

    showClaimOrRedeemModal(){
      this.clearModal()
      this.$refs["claimOrRedeemModal"].show();
    },

    clearModal(){
      this.selectedAppScreen = 'Choose option'
      this.selectedHotel = 'Choose option'
      this.selectedOutlet = 'Choose option'
      this.mReceiptNumber = ''
      this.mDate = ''
      this.mBillAmount = ''
      this.mStaffAuthCode = ''
      this.hotelOutlet = ''
      this.hotelOutletId = ''
    },

    saveClaimOrRedeemPoints(e){

      if(this.selectedAppScreen === 'Choose option'){
         e.preventDefault()
        return
      }

      if(this.selectedHotel === 'Choose option'){
         e.preventDefault()
        return
      }

      if(this.tab == 1){
        if(this.mReceiptNumber === ''){
          e.preventDefault()
          return
        }
      }

      if(this.mDate === ''){
        e.preventDefault()
        return
      }
      if(this.mBillAmount === ''){
        e.preventDefault()
        return
      }
      if(this.mStaffAuthCode === ''){
        e.preventDefault()
        return
      }

      if(this.selectedAppScreen === 'HotelScreen'){
        this.hotelOutlet = 'hotel'
        this.hotelOutletId = this.selectedHotel
      }else{

        if(this.selectedOutlet === 'Choose option'){
          e.preventDefault()
          return
        }

        this.hotelOutlet = 'outlet'
        this.hotelOutletId = this.selectedOutlet
      }

      const item = {
        'date': this.mDate,
        'receipt_number': this.mReceiptNumber,
        'amount_spent': this.mBillAmount,
        'access_code': this.mStaffAuthCode,
        'transaction_type': 'manual'
      }

      if(this.tab == 1){
        axios.post(`${process.env.MIX_API_SERVER_PATH}/api/customer/${this.customerId}/${this.hotelOutlet}/${this.hotelOutletId}/points/claim`, item)
        .then((response) => {
          
          console.info(response.data)

          if(!response.data.success){
            this.$swal.fire(
              'Oops...',
              'Something went wrong.',
              'error'
            )
            return
          }

          this.$swal.fire(
            'Claim Points',
            `Customer successfully collected ${response.data.data.points_collected} points.`,
            'success'
          )
        }).catch(error => {
          if(error.response.status == '422'){
            this.$swal.fire(
              'Sorry!',
              'Authorization code is incorrect.',
              'error'
            )
          }
          console.info('error', error.response)
        })
      }

      if(this.tab == 2){
        axios.post(`${process.env.MIX_API_SERVER_PATH}/api/customer/${this.customerId}/${this.hotelOutlet}/${this.hotelOutletId}/points/redeem`, item)
        .then((response) => {
          
          console.info(response.data)

          if(!response.data.success){
            if(response.data.error_code === 'no_points_available'){
              this.$swal.fire(
              'Sorry!',
              'Customer have no points available for redemption.',
              'error'
            )
            }
            return
          }

          let remainingMessage = `Redemption code: ${response.data.redemption_code}`

          if(response.data.amount_to_pay > 0){
            remainingMessage = `${response.data.currency_symbol}${response.data.amount_to_pay} is still payable. Redemption code: ${response.data.redemption_code}`
          }

          this.$swal.fire(
            'Redeem Points',
            `Customer has redeemed ${response.data.redeemed_points} points. ${remainingMessage}`,
            'success'
          )
        }).catch(error => {
          if(error.response.status == '422'){
            this.$swal.fire(
              'Sorry!',
              'Authorization code is incorrect.',
              'error'
            )
          }
          console.info('error', error.response)
        })
      }

      this.goToPage(1);

      
    },
    
  },
  
  computed: {
    getPages() {
      try {
        if (this.pagination) {
          const currentPage = this.pagination.current_page;
          const totalPages = this.pagination.total_pages;
          const pages = [];
          for (var x = Math.max(1, currentPage - 2); x <= Math.min(totalPages, currentPage + 2); x++) {
            pages.push(x);
          }
          return pages;
        }
      }
      catch(ex) {}
      
      return [];
    },
    currentPage() {
      try {
        return this.pagination.current_page;
      }
      catch (ex) {}

      return 1;
    },
    totalPages() {
      try {
        return this.pagination.total_pages;
      }
      catch (ex) {}

      return 1;
    },
    hasEntries() {
      try {
        return Object.keys(this.history).length > 0;
      }
      catch(ex) {
        return false;
      }
    },
    canLoadMore() {
      return this.currentPage < this.totalPages;
    },
  },

  watch: {
    '$route.query.page'(newValue, oldValue) {
      if (!newValue) {
        newValue = 1;
      }
      if (this.$route.query.tab == this.tab) {
        this.goToPage(newValue);
      }
    },
  }
}
</script>