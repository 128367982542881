<template>
    <modal name="hotel-outlet-edit" classes="v--modal my-4" height="auto" :scrollable="true" :clickToClose="false" @before-open="beforeOpen">
        <div class="col-sm-12 py-4" ref="content">
            <a class="link float-right" @click="close"> <i class="fas fa-times-circle"></i> </a>
            <h3>Edit Outlet</h3>
            <div class="row">
                <div class="col-sm-12">
                    <form ref="form" @submit="submit">
                        <fieldset>
                            <div class="form-group required">
                                <label for="name">Name:</label>
                                <input type="text" required id="name" class="form-control" v-model="outlet.name"/>
                            </div>
                            <div class="form-group required">
                                <label for="description">Description:</label>
                                <textarea required id="description" class="form-control" v-model="outlet.description"/>
                            </div>
                            <div class="form-group">
                                <label for="access_code">Authorization Code:</label>
                                <input type="number" id="access_code" step="1" class="form-control" v-model="outlet.access_code" min="0" max="9999" pattern="\d{4}"/>
                            </div>

                            <div class="form-group required">
                                <label>Schedule(s)</label>
                                <table class="table">
                                    <tbody>
                                        <outlet-schedule v-for="(schedule, index) in outlet.schedules" :key="index" :index="index"
                                            :schedule="schedule" @remove="removeOutletSchedule"
                                            ></outlet-schedule>
                                        <tr>
                                            <td colspan="4" class="text-center">
                                                <button type="button" class="btn btn-secondary" @click="addSchedule">Add Schedule</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="form-group">
                                <label for="images">Images:</label>
                                <input type="file" id="images" class="form-control d-none" accept="image/*" ref="imageSelector" multiple @change="processSelected"/>
                                <button type="button" class="btn btn-secondary" @click="selectImages">Add Images</button>
                                <small class="form-text text-muted">Minimum image size is 640 width and 260 height with ideal content ratio is 5:2.</small>
                                <div class="row">
                                    <RemovableImage v-for="(image, index) in images" :image="image" :key="index" :index="index" @removeImage="removeImage">
                                    </RemovableImage>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <button type="button" class="btn btn-primary" @click="triggerSubmit">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import OutletSchedule from "./OutletSchedule";
import RemovableImage from "../components/RemovableImage";
import moment from "moment";

export default {
    components: {
        OutletSchedule,
        RemovableImage,
    },

    props: {
        baseOutlet: {
            required: true,
        },
    },

    data() {
        return {
            outlet: {
                name: '',
                description: '',
                access_code: '',
                schedules: [],
                images: [],
            },
            removedSchedules: [],
            removedImages: [],
        };
    },

    mounted() {


    },

    methods: {
        beforeOpen() {
            const {
                id,
                name,
                description,
                access_code,
                schedules,
                images,
            } = this.baseOutlet;

            this.outlet = {
                id,
                name,
                description,
                access_code,
                schedules: [],
                images: [...images.data],
            };
            this.removedSchedules = [];
            this.removedImages = [];

            schedules.data.forEach((schedule) => {
                const {
                    id,
                    day,
                    time,
                } = schedule;

                this.outlet.schedules.push({
                    id,
                    day,
                    time: {
                        start: moment(time.start, "H:mma").format("HH:mm:ss"),
                        end: moment(time.end, "H:mma").format("HH:mm:ss"),
                    },
                });
            });
        },
        selectImages() {
            this.$refs.imageSelector.click();
        },
        processSelected(evt) {
            var images = Object.values(evt.target.files);
            images.forEach((image) => {
                this.outlet.images.push(image);
            });
        },
        triggerSubmit() {
            if (this.$refs.form.reportValidity()) {
                this.submit();
            }
        },
        submit(evt) {
            const loader = this.$loading.show({
                container: this.$refs.content,
                canCancel: false,
            });
            
            this.$store.dispatch('editHotelOutlet', {
                hotelId: this.$route.params.hotelId,
                outlet: this.outlet,
                removedSchedules: this.removedSchedules,
                removedImages: this.removedImages,
            })
                .then((outlet) => {
                    this.$emit('outletEdited', outlet);
                    setTimeout(() => {
                        this.$modal.hide('hotel-outlet-edit');
                    }, 1);
                })
                .catch((error) => {
                    console.log("/api/hotel/:hotelId/outlets:edit error", error, error.response);
                })
                .finally(() => {
                    loader.hide();
                });

            if (evt) {
                evt.preventDefault();
                return false;
            }
        },
        close() {
            this.$modal.hide('hotel-outlet-edit');
        },
        addSchedule() {
            this.outlet.schedules.push({
                day: "",
                time: {
                    start: "08:00:00",
                    end: "20:00:00",
                },
            });
        },
        removeOutletSchedule(index) {
            const removedSchedule = this.outlet.schedules[index];
            this.outlet.schedules.splice(index, 1);

            if (removedSchedule.id) {
                this.removedSchedules.push(removedSchedule.id);
            }
        },
        removeImage(index) {
            const image = this.outlet.images[index];
            if (!(image instanceof File)) {
                this.removedImages.push(image.id);
            }
            this.outlet.images.splice(index, 1);
        },
    },
    computed: {
        images() {
            const images = [];
            
            this.outlet.images.forEach(imageFile => {
                if (imageFile instanceof File) {
                    images.push(URL.createObjectURL(imageFile));
                }
                else {
                    images.push(imageFile.url);
                }
            });

            return images;
        },
    },
}
</script>