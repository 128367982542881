<template>
    <div class="col-sm-12">
        <div class="row">
            <div class="col-sm-12 mt-2">
                <button type="button" class="btn btn-primary float-right" @click="showEditModal"> <i class="fas fa-edit"></i> Edit Outlet </button>
                <h3>View Outlet</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <dl class="row">
                    <dt class="col-sm-3">Name:</dt>
                    <dd class="col-sm-9">{{ outletName }}</dd>
                    <dt class="col-sm-3">Description:</dt>
                    <dd class="col-sm-9">{{ outletDescription }}</dd>
                    <dd class="col-sm-12">
                        <b-card no-body>
                            <b-tabs pills card v-model="tab" @activate-tab="tabChanged">
                                <b-tab title="Images">
                                    <div class="row">
                                        <div class="col-sm-6 col-md-3 my-3" v-for="image in outlet.images.data" :key="image.id">
                                            <img :src="image.url" width="100%" />
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Schedules">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Day</th>
                                                <th scope="col">Time Start</th>
                                                <th scope="col">Time End</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="schedule in outlet.schedules.data" :key="schedule.id">
                                                <td>
                                                    {{ schedule.day }}
                                                </td>
                                                <td class="text-right">
                                                    {{ schedule.time.start }}
                                                </td>
                                                <td class="text-right">
                                                    {{ schedule.time.end }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </b-tab>
                                <b-tab title="Deals">
                                    <DealsTable @addClicked="showAddModal" :hasDeals="hasDeals" ref="dealsTable">
                                        <template v-slot:rows>
                                            <HotelOutletViewDealRow v-for="deal in deals" :key="deal.id" :hotel="hotel" :outlet="outlet" :deal="deal" @clickedDeal="viewDeal" v-on:get-deals="getDeals"></HotelOutletViewDealRow>
                                        </template>

                                        <template v-slot:footer>
                                            <tr class="paginator" v-if="totalPages > 1">
                                                <td colspan="4" class="text-center">
                                                    <router-link class="link" v-if="currentPage > 1" :to="{ name: 'hotel_outlet_view', params: [':hotelId', ':outletId'], query: { tab: 2, page: 1 } }">&lt;&lt;</router-link>
                                                    <router-link class="link" v-if="currentPage > 1" :to="{ name: 'hotel_outlet_view', params: [':hotelId', ':outletId'], query: { tab: 2, page: currentPage - 1 } }"> &lt; </router-link>
                                                    <router-link class="link" v-for="page in getPages" :key="page" :to="{ name: 'hotel_outlet_view', params: [':hotelId', ':outletId'], query: { tab: 2, page } }">{{ page }}</router-link>
                                                    <router-link class="link" v-if="currentPage != totalPages" :to="{ name: 'hotel_outlet_view', params: [':hotelId', ':outletId'], query: { tab: 2, page: currentPage + 1 } }"> &gt; </router-link>
                                                    <router-link class="link" v-if="currentPage != totalPages" :to="{ name: 'hotel_outlet_view', params: [':hotelId', ':outletId'], query: { tab: 2, page: totalPages } }">&gt;&gt;</router-link>
                                                </td>
                                            </tr>
                                        </template>
                                    </DealsTable>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </dd>
                </dl>
            </div>
        </div>
        <HotelOutletDealAdd :hotel="hotel" :outlet="outlet" @deal-created="dealCreated"></HotelOutletDealAdd>
        <HotelOutletDealEdit :hotel="hotel" :outlet="outlet"></HotelOutletDealEdit>
        <HotelOutletEdit :baseOutlet="outlet" @outletEdited="outletEdited"></HotelOutletEdit>
        <DealView></DealView>
    </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import DealsTable from "../components/DealsTable";
import HotelOutletDealAdd from "../components/HotelOutletDealAdd";
import HotelOutletDealEdit from "../components/HotelOutletDealEdit";
import HotelOutletViewDealRow from "../components/HotelOutletViewDealRow";
import HotelOutletEdit from "../components/HotelOutletEdit";
import DealView from "../components/DealView";
import axios from "axios"

export default {
    components: {
        DealsTable,
        HotelOutletDealAdd,
        HotelOutletDealEdit,
        HotelOutletViewDealRow,
        HotelOutletEdit,
        DealView,
    },
    data() {
        return {
            outlet: {
                name: '',
                description: '',
                images: {
                    data: [],
                },
                schedules: {
                    data: [],
                },
                deals: {
                    data: {},
                }
            },
            hotel: {},
            tab: 0,
            dealPage: 1,

            deals: [],
            defaultLang: '',

            outletName: '',
            outletDescription: '',
        };
    },
    async mounted() {
        const {
            hotelId,
            outletId,
            outlet,
        } = this.$route.params;

        const {
            tab = 0,
            page = 1,
        } = this.$route.query;

        await this.getSettings()

        this.tab = parseInt(tab);
        this.dealPage = parseInt(page);

        await this.getHotel(hotelId)

        await this.getHotelOutlet(hotelId, outletId)

        this.getDeals()

    },
    methods: {
        async getSettings() {
          axios.get(`${process.env.MIX_API_SERVER_PATH}/api/settings/1`)
            .then(response => {
              if(response.data.default_lang_iso_code !== ''){
                this.defaultLang = response.data.default_lang_iso_code
              }
            })
        },

        async getHotel(hotelId) {
          axios.get(`${process.env.MIX_API_SERVER_PATH}/api/hotels/${hotelId}`)
            .then(response => {
                console.info('hotel', response.data)
                this.hotel = response.data
            })
        },

        async getHotelOutlet(hotelId, outletId) {
          axios.get(`${process.env.MIX_API_SERVER_PATH}/api/hotel/${hotelId}/outlets/${outletId}`)
            .then(response => {
                console.info('outlet', response.data)
                this.outlet = response.data

                this.outletName = response.data.multi_langs[0].name
                this.outletDescription = response.data.multi_langs[0].description
            })
        },

        async getDeals(){
           const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            axios.get(`${process.env.MIX_API_SERVER_PATH}/api/hotel/${this.$route.params.hotelId}/outlet/${this.$route.params.outletId}/deals?all`)
            .then(response => {

              let deals = []

              _.each(response.data.items, (item) => {

                let multiLang = item.multi_langs.find(items => items.iso_code === this.defaultLang)

                if(!_.isEmpty(multiLang)){
                  deals.push({
                    id: item.id,
                    deal_id: item.deal_id,
                    name: multiLang.name,
                    discount_title: multiLang.discount_title,
                    description: multiLang.description,

                    discount:{
                      amount: item.discount.amount,
                    },
                    image:{
                      url: item.image.url,
                    },

                    title_color: item.title_color,
                    name_color: item.name_color,
                    description_color: item.description_color,
                    availability_color: item.availability_color,
                    isInCarousel: item.isInCarousel,
                    discount_code_option: item.discount_code_option,
                    discount_code: item.discount_code,
                    discount_code_link_phone: item.discount_code_link_phone,
                  })
                }
              })

              this.deals = deals

              console.info('dealsx', deals)

              loader.hide()
            })
        },

        loadDeals(page = 1) {
            const loader = this.$loading.show({
                el: this.$refs.dealsTable
            });
            this.$store.dispatch('loadHotelOutletDeals', {
                hotelId: this.hotel.id,
                outletId: this.outlet.id,
                page,
            })
                .then((deals) => {
                    this.deals = deals;
                })
                .catch((error) => {

                })
                .finally(() => {
                    loader.hide();
                });
        },
        showAddModal() {
            this.$modal.show('hotel-outlet-deal-add');
        },
        showEditModal() {
            this.$router.push({ name: 'hotel_outlet_edit', params: { hotelId: this.$route.params.hotelId, outletId: this.$route.params.outletId,} })
        },
        outletEdited(outlet) {
            this.outlet = outlet;
        },
        dealCreated(deal) {
            console.log("emit:dealCreated", deal);
            this.deals.push(deal);
        },
        viewDeal(deal) {
            const modalShowed = this.$modal.show('deal-view', {
                deal
            });
        },
        tabChanged(newTabIndex, prevTabIndex) {
            const {
                name,
                hash,
                query,
                params,
            } = this.$route;
            const newQuery = {
                tab: newTabIndex,
            };
            if (newTabIndex == 2 && query.page) {
                newQuery.page = query.page;
            }
            this.$router.replace({
                name, hash, query: newQuery, params,
            })
                .then(() => {
                  this.getDeals()
                })
                .catch((error) => {});
        },
    },
    computed: {
        ...mapGetters(['getHotelOutletDeals']),
        getPages() {
            try {
                const currentPage = this.deals.pagination.current_page;
                const totalPages = this.deals.pagination.total_pages;
                const pages = [];
                for (var x = Math.max(1, currentPage - 2); x <= Math.min(totalPages, currentPage + 2); x++) {
                    pages.push(x);
                }
                return pages;
            }
            catch(ex) {
                return [];
            }
        },
        currentPage() {
            try {
                return this.deals.pagination.current_page;
            }
            catch(ex) {
                return 1;
            }
        },
        totalPages() {
            try {
                return this.deals.pagination.total_pages;
            }
            catch(ex) {
                return 1;
            }
        },
        hasDeals() {
            try {
                return Object.keys(this.deals).length > 0;
            }
            catch(ex) {}
            return false;
        },
    },
    watch: {
        getHotelOutlet: {
            handler(newValue, oldValue) {
                if (newValue) {
                    this.outlet = newValue;
                }
            },
            deep: true,
        },
        getHotelOutletDeals: {
            handler(newValue, oldValue) {
                if (newValue) {
                    this.deals = newValue;
                }
            },
            deep: true,
        },
        '$route.query.page'(newValue, oldValue) {
            try {
                if (this.$route.query.tab == 2) {
                    this.dealPage = newValue != null ? parseInt(newValue) : 1;

                    this.getDeals()
                }
            }
            catch(err) {
                console.log(err);
            }
        },
    },
}
</script>