<template>
  <div class="col-sm-12">
    <h2>Settings</h2>
    <form @submit="submit">
      <fieldset>
        <div class="row">
          <div class="col-md-5">
           <div style="margin-top: 10px" class="form-group">
            <label for="selectedLanguage" class="has-text-weight-bold">Default Language:</label>
            <div class="form-group">
              <select v-model="selectedLanguage"  class="form-control">
                <option disabled>{{selectedLanguage}} </option>
                <option v-for="(item, key) in supportedLanguages" :key="key" :value="item.description">{{item.description}} </option>
              </select>
            </div>
         </div>
         <div>
          <div class="form__label">
            <strong>App Color:</strong>
          </div>
          <div style="margin-top: 10px" class="form-group" >
            <select v-model="selectedColorType"  class="form-control" @change="onAppColorChange">
              <option value="solid" >Solid</option>
              <option value="gradient" >Gradient</option>
            </select>
          </div>
          <div class="form-group">
            <v-swatches
              v-model="colorStart"
              show-fallback
              fallback-input-type="color"
              popover-x="left">
            </v-swatches>
            <v-swatches
              v-if="!isAppColorSolid"
              v-model="colorEnd"
              show-fallback
              fallback-input-type="color"
              popover-x="left">
            </v-swatches>
          </div>
        </div>

        <div style="margin-bottom: 10px" class="box">
          <div style="margin-bottom: 10px"> <strong>Points Expiry:</strong> </div>
          <div class="form-group required">
            <label for="pointsExpiryMonth">Months:</label>
            <input type="number" required step="1"  min="0" max="100" pattern="\d{4}" class="form-control" v-model="pointsExpiryMonth" />
          </div>
        </div>

          </div>
          <div class="col-md-7">
            <div class="form-group">
              <label for="images">App Logo:</label>
              <input type="file" id="logoImage" class="form-control d-none" accept="image/*" ref="logoImageSelector"  @change="processLogoImage"/>
              <button type="button" class="button" @click="selectLogoImage">Select Logo</button>
              <RemovableImage v-for="(image, index) in logoImage" :image="image" :key="index" :index="index" @removeImage="removeLogoImage">
              </RemovableImage>
            </div>

            <div class="form-group">
              <label for="images">App Background Image:</label>
              <input type="file" id="images" class="form-control d-none" accept="image/*" ref="imageSelector"  @change="processSelected"/>
              <button type="button" class="button" @click="selectImages">Select Image</button>
              <RemovableImage v-for="(image, index) in images" :image="image" :key="index" :index="index" @removeImage="removeImage">
              </RemovableImage>
            </div>
          </div>

          <button style="margin-top: 20px" type="submit" class="button is-primary"> Save Settings </button>

        </div>
      </fieldset>
    </form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import RemovableImage from "../components/RemovableImage";
import VSwatches from 'vue-swatches'
import axios from "axios"

// import "vue-swatches/dist/vue-swatches.min.css"

export default {

  components: {
    RemovableImage,
    VSwatches
  },
  
  data() {
    return {
      setting: {
        images: {
          data: [],
        },
        logoImage: {
          data: [],
        },
      },
      removedImages: [],
      removedLogoImage: [],
      selectedColorType: 'solid',
      isAppColorSolid: true,

      colorStart: '',
      colorEnd: '',

      selectedLanguage: '',
      supportedLanguages: [],

      pointsExpiryMonth: '', 

    };
  },

  computed: {
    ...mapGetters(['getSetting',]),
    
    images() {
      const images = [];
      this.setting.images.data.forEach((imageFile) => {
        if (imageFile instanceof File) {
          images.push(URL.createObjectURL(imageFile));
        }
        else {
          if(imageFile.url !== ''){
            images.push(imageFile.url);
          }
        }
      });
      return images;
    },

    logoImage() {
      const images = [];
      this.setting.logoImage.data.forEach((imageFile) => {
        if (imageFile instanceof File) {
          images.push(URL.createObjectURL(imageFile));
        }
        else {
          if(imageFile.url !== ''){
            images.push(imageFile.url);
          }
        }
      });
      return images;
    },

  },

  methods: {
    assignSetting(setting) {
      this.setting.images.data = [
        {
          id: 1,
          url: setting.app_bg_url
        }
      ]

      this.setting.logoImage.data = [
        {
          id: 1,
          url: setting.app_logo_url
        }
      ]

      this.isAppColorSolid =  setting.app_color.type !== 'solid' ? false : true

      this.selectedColorType = setting.app_color.type
      this.colorStart = setting.app_color.color.start
      this.colorEnd = setting.app_color.color.end

      if(setting.default_lang_iso_code !== ''){
        this.selectedLanguage = this.supportedLanguages.find(item => item.iso_code === setting.default_lang_iso_code).description
      }

      this.pointsExpiryMonth = setting.points_expiry_month

    },
    
    selectImages() {
      this.$refs.imageSelector.click();
    },

    selectLogoImage() {
      this.$refs.logoImageSelector.click();
    },

    processSelected(evt) {
      var images = Object.values(evt.target.files);
      images.forEach((image) => {
        if(this.setting.images.data.length < 0){
          this.setting.images.data.push(image);
        }else{
          this.setting.images.data.splice(0, 1, image)
        }
      });
    },

     processLogoImage(evt) {
      var images = Object.values(evt.target.files);
      images.forEach((image) => {
        if(this.setting.logoImage.data.length < 0){
          this.setting.logoImage.data.push(image);
        }else{
          this.setting.logoImage.data.splice(0, 1, image)
        }
      });
    },

    onAppColorChange(e){
      this.selectedColorType = e.target.value

      if(e.target.value === 'solid'){
        this.isAppColorSolid = true
      }else{
        this.isAppColorSolid = false
      }
    },

    onSaveAppColorClick(){
      let data = new FormData()
      data.append('app_color', JSON.stringify({
        type: 'gradient',
        color: {
          start: this.colorStart,
          end: this.colorEnd
        }
      }))
       
    },

    submit(evt) {
      const loader = this.$loading.show({
        container: this.$el,
        canCancel: false,
      });

      this.$store.dispatch('editSetting', {
        setting: this.setting,
        removedImages: this.removedImages,
        removedLogoImage: this.removedLogoImage,
        appColor: JSON.stringify({
          type: this.selectedColorType,
          color: {
            start: this.colorStart,
            end: this.colorEnd
          }
        }),
        selectedLanguage: this.supportedLanguages.find(item => item.description === this.selectedLanguage).iso_code,
        pointsExpiryMonth: this.pointsExpiryMonth
      })
        .then((setting) => {
          // this.$router.push({ name: 'hotel_view', params: {
          //     hotelId: hotel.id,
          //     hotel: hotel
          // }});
          // alert('success')
        })
        .catch(() => {})
        .finally(() => {
          loader.hide();
        });

      evt.preventDefault();
      return false;
    },
    
    removeImage(index) {
      console.info('index', index)
      const image = this.setting.images.data[index];
      if (!(image instanceof File)) {
        this.removedImages.push(image.id);
      }
      this.setting.images.data.splice(index, 1);
    },

    removeLogoImage(index) {
      const image = this.setting.logoImage.data[index];
      if (!(image instanceof File)) {
        this.removedLogoImage.push(image.id);
        console.info('indexxx', image.id)

      }
      this.setting.logoImage.data.splice(index, 1);
    },

    getSupportedLanguages(){
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/languages`)
      .then(response => {
        this.supportedLanguages = response.data.items
      })
    }

  },
  mounted() {

    this.getSupportedLanguages()

    var setting = this.getSetting;

    if (!setting) {
      // const settingId = this.$route.params.settingId;
      const settingId = 1;
      const loader = this.$loading.show({
        container: this.$el,
        canCancel: false,
      });

      this.$store.dispatch('loadSetting', settingId)
        .then((setting) => {
          this.assignSetting(setting);

        })
        .catch(() => {})
        .finally(() => {
          loader.hide();
        });
    }
    else {
      this.assignSetting(hotel);
    }

  },
}
</script>