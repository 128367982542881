<template>
    <div class="col-sm-12">
        <div class="row">
            <div class="d-sm-none d-lg-block col-lg-3">
            </div>
            <div class="col-lg-6">
                <form @submit="submit">
                    <fieldset>
                        <div class="form-group">
                            <label for="email">e-mail:</label>
                            <input type="email" id="email" class="form-control" v-model="email"/>
                        </div>
                        <div class="form-group">
                            <label for="password">Password:</label>
                            <input type="password" id="password" class="form-control" v-model="password"/>
                        </div>
                    </fieldset>
                    <input type="submit" value="Login" :disabled="processing" />
                </form>
            </div>
            <div class="d-sm-none d-lg-block col-lg-3">
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from "vuex";
import VueRouter from "vue-router";

export default {
    name: "Login",
    mounted() {
        if (this.$store.getters.isLoggedIn) {
            this.$router.replace({ name: 'dashboard' });
        }
    },
    data() {
        return {
            email: '',
            password: '',
            processing: false,
        };
    },
    methods: {
        submit(evt) {
            const data = {
                email: this.email, 
                password: this.password
            };


            this.processing = true;

            this.$store.dispatch('login', data).then(() => {
                const searchParams = new URLSearchParams(window.location.search);
                var redirect = searchParams.has('redirect') ? searchParams.get('redirect') : { name: 'dashboard' };
                this.$router.push(redirect);
            })
            .catch((reason) => {
                if (reason) {
                    alert(reason);
                }
            })
            .finally(() => {
                this.processing = false;
            });

            evt.preventDefault();
            return false;
        }
    },

}
</script>