<script>
import DealAdd from "./DealAdd";
import axios from "axios"

export default {
    extends: DealAdd,

    data() {
        return {
            modalName: 'hotel-deal-add',
            defaultLanguage: '',
            supportedLanguages: [],
        };
    },

    methods: {
    getSupportedLanguages(){
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/languages`)
      .then(response => {
        this.supportedLanguages = response.data.items
        this.getSettings()
      })
    },

    getSettings() {
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/settings/1`)
      .then(response => {
        if(response.data.default_lang_iso_code !== ''){
        this.defaultLanguage = this.supportedLanguages.find(item => item.iso_code === response.data.default_lang_iso_code).description
        }
      })
    },

    addMultiLang(deal){
      axios.post(`${process.env.MIX_API_SERVER_PATH}/api/deal-multi-langs`, {
        deal_id: deal.id,
        iso_code: this.supportedLanguages.find(item => item.description === this.defaultLanguage).iso_code,
        name: this.deal.name,
        discount_title: this.deal.discount.name,
        description: this.deal.description,
      })
      .then((response) => {
        if(!response.data.success){
          alert('unable to save item')
          return
        }
        
        this.$emit('deal-created', {
          id: deal.id,
          name: this.deal.name,
          description: this.deal.description,
          isInCarousel: false
        });

        this.$swal.fire(
          'Saved!',
          'Language and contents added',
          'success'
        )

      })
    },
        submit(evt) {
            const loader = this.$loading.show({
                container: this.$refs.content,
                canCancel: false,
            });
            
            this.$store.dispatch('addHotelDeal', {
                hotelId: this.hotel.id,
                deal: this.deal,
                selected_discount_value: this.selected_discount_value,
                discount_code: this.discount_code,
                discount_code_link_phone: this.discount_code_link_phone,
            })
                .then((deal) => {
                    setTimeout(() => {
                        this.$modal.hide('hotel-deal-add');
                        this.addMultiLang(deal)
                    }, 1);
                })
                .catch((error) => {
                    console.log("/api/hotel/:hotelId/deals:add error", error, error.response);
                })
                .finally(() => {
                    loader.hide();
                });

            if (evt) {
                evt.preventDefault();
                return false;
            }
        },
    },

    mounted(){
     this.getSupportedLanguages()
  }
}
</script>