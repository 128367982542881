import Vue from "vue";

import App from "./js/App";
import store from "./js/Store";
import router from "./js/Router";
import "bootstrap";
import LoadingOverlay from "vue-loading-overlay";
import VModal from "vue-js-modal";
import BootstrapVue, { TabsPlugin } from "bootstrap-vue";
// import Buefy from 'buefy'
import { Table } from 'buefy'
import 'buefy/dist/buefy.css'
import lodash from "lodash"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(LoadingOverlay);
Vue.use(VModal, {
    dynamic: true,
    injectModalsContainer: true,
});
Vue.use(BootstrapVue);
Vue.use(TabsPlugin);
// Vue.use(Buefy)
Vue.use(Table)

Vue.use(VueSweetalert2);

new Vue({
    el: "#app",
    store,
    router,
    components: {
        App,
    },
    beforeCreate() {
    },
    mounted() {
    },
});
