<template>
    <modal name="deal-view" classes="v--modal my-4" height="auto" :scrollable="true" @before-open="beforeOpen">
        <div class="col-sm-12 py-4">
            <a class="link float-right" @click="close"> <i class="fas fa-times-circle"></i> </a>
            <h3>View Deal</h3>
            <div class="row">
                <div class="col-sm-12">
                    <dl class="row">
                        <dt class="col-sm-3">Name:</dt>
                        <dd class="col-sm-9">{{ deal.name }}</dd>
                        <dt class="col-sm-3">Description:</dt>
                        <dd class="col-sm-9">{{ deal.description }}</dd>
                        <div v-if="deal.discount_code_option !== 'none'">
                            <dt class="col-sm-3">Discount Code:</dt>
                            <dd class="col-sm-9">{{ deal.discount_code }}</dd>
                        </div>
                        <div v-if="deal.discount_code_option !== 'none'">
                            <dt class="col-sm-3"></dt>
                            <dd class="col-sm-9">{{ deal.discount_code_link_phone }}</dd>
                        </div>
                        <dt class="col-sm-12">
                            Date
                        </dt>
                        <dd class="col-sm-12">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <dl class="row">
                                        <dt class="col-sm-4">Start:</dt>
                                        <dd class="col-sm-8 text-right">{{ deal.date.formatted_dates.start_date }}</dd>
                                    </dl>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <dl class="row">
                                        <dt class="col-sm-4">End:</dt>
                                        <dd class="col-sm-8 text-right">{{ deal.date.formatted_dates.end_date }}</dd>
                                    </dl>
                                </div>
                            </div>
                        </dd>

                        <dt class="col-sm-12">
                            Discount
                        </dt>
                        <dd class="col-sm-12">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <dl class="row">
                                        <dt class="col-sm-4">Amount:</dt>
                                        <dd class="col-sm-8 text-right">{{ deal.discount.amount }}</dd>
                                    </dl>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <dl class="row">
                                        <dt class="col-sm-4">Name:</dt>
                                        <dd class="col-sm-8">{{ deal.discount.name }}</dd>
                                    </dl>
                                </div>
                            </div>
                        </dd>

                        <template v-if="deal.image.url">
                        <dt class="col-sm-3">Image:</dt>
                        <dd class="col-sm-8 text-center">
                            <img :src="deal.image.url" width="100%" />
                        </dd>

                        <dt class="col-sm-3">Name Color:</dt>
                        <dd class="col-sm-8 text-left">
                            <input type="color" disabled class="form-control" v-model="deal.name_color"/>
                        </dd>

                        <dt class="col-sm-3">Discount Color:</dt>
                        <dd class="col-sm-8 text-left">
                            <input type="color" disabled class="form-control" v-model="deal.title_color"/>
                        </dd>

                        <dt class="col-sm-3">Description Color:</dt>
                        <dd class="col-sm-8 text-left">
                            <input type="color" disabled class="form-control" v-model="deal.description_color"/>
                        </dd>

                        <dt class="col-sm-3">Availability Color:</dt>
                        <dd class="col-sm-8 text-left">
                            <input type="color" disabled class="form-control" v-model="deal.availability_color"/>
                        </dd>
                        </template>
                    </dl>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data() {
        return {
            deal: {
                name: "",
                description: "",
                date: {
                    start: null,
                    end: null,
                    formatted_dates: {
                        start_date: null,
                        end_date: null,
                    },
                },
                discount: {
                    amount: "",
                    name: "",
                },
                image: {
                    url: null,
                },
                title_color: '#FF5F58',
                name_color: '#FFFFFF',
                description_color: '#FFFFFF',
                availability_color: '#FFFFFF',
            },
        };
    },
    methods: {
        beforeOpen(event) {
            this.deal = event.params.deal;
        },
        close() {
            this.$modal.hide('deal-view');
        },
    },
}
</script>