<template>
    <div class="col-sm-12">
        <h2 style="font-size: 24px; margin-top: 20px">
            Hotels
        </h2>
    <div style="margin-top: 30px" class="field">
        <p class="control has-icons-left">
            <input
                class="input is-shadowless"
                type="text"
                placeholder="Search Hotel"
                v-model="search"
            />
            <span class="icon is-small is-left">
            <i class="fas fa-search"></i>
            </span>
        </p>
    </div>
    <b-table
      :data="filtered"
      :paginated="true"
      per-page="10"
      current-page.sync="1"
      :pagination-simple="false"
      pagination-position="bottom"
      default-sort-direction="asc"
      sort-icon="chevron-up"
      sort-icon-size="is-small"
      :striped="true"
      :hoverable="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :mobile-cards="false"
    >
        <b-table-column
          field="id"
          label="ID"
          sortable
          v-slot="props"
        >
          {{ props.row.id }}
        </b-table-column>

        <b-table-column
          field="name"
          label="Name"
          sortable
          v-slot="props"
        >
          {{ props.row.name }}
        </b-table-column>

        <b-table-column
          field="description"
          label="Description"
          sortable
          v-slot="props"
        >
          {{ props.row.description }}
        </b-table-column>

        <b-table-column
          field="location.address"
          label="Location"
          sortable
          v-slot="props"
        >
          {{ props.row.location.location_address }}
        </b-table-column>

        <b-table-column centered width="5" label="Actions"  v-slot="props">
          <div class="buttons is-centered is-inline-block">

           <div class="row"> 

            <button class="button is-shadowless" @click="viewItem(props.row)">
              <span class="icon">
                <i class="fas fa-eye has-text-success"></i>
              </span>
            </button>

             <!-- Edit icon -->
            <button class="button is-shadowless" @click="editItem(props.row)">
              <span class="icon">
                <i class="fas fa-edit has-text-primary"></i>
              </span>
            </button>

             <!-- Delete icon -->
            <button class="button is-shadowless" @click="deleteItem(props.row)">
              <span class="icon">
                <i class="fas fa-trash has-text-danger"></i>
              </span>
            </button>

           </div>

          </div>
        </b-table-column>

    </b-table>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "../Store";
import WebUtils from '../utils/WebUtils';
import LoadingOverlay from "vue-loading-overlay";
import axios from "axios"

export default {

    data() {
        return {
            hotels: [],
            search: '',
            defaultLang: ''
        };
    },

    computed: {
        filtered() {
            if (!this.search.trim()) {
                return this.hotels
            }
            return this.hotels.filter(data =>
                Object.values(data).some(val =>
                String(val)
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
                )
            )
        },
       
    },

    methods: {
        getHotels(){
           const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            axios.get(`${process.env.MIX_API_SERVER_PATH}/api/hotels`)
            .then(response => {

              let hotels = []

              _.each(response.data.items, (item) => {

                let multiLang = item.multi_langs.find(items => items.iso_code === this.defaultLang)

                if(!_.isEmpty(multiLang)){
                  hotels.push({
                    id: item.id,
                    name: multiLang.name,
                    description: multiLang.description,
                    access_code: item.access_code,
                    ratio: item.ratio,
                    location:{
                      lat: item.location.lat,
                      lng: item.location.lng,
                      location_address: multiLang.location_address
                    },
                    hotel_currency: item.hotel_currency,
                    currency_symbol: item.currency_symbol,
                  })
                }
              })

              this.hotels = hotels

              loader.hide()
            })
        },

        viewItem(item){
             this.$router.push({ name: 'hotel_view', params: { hotelId: item.id, hotel: item } })
        },

        editItem(item){
             this.$router.push({ name: 'hotel_edit', params: { hotelId: item.id } })
        },

        deleteItem(item){
           this.$swal.fire({
            title: 'Are you sure?',
            text: 'Hotel, outlets, hotel deals and outlet deals will be gone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
            }).then((result) => {
            if (result.value) {
              axios.delete(`${process.env.MIX_API_SERVER_PATH}/api/hotels/${item.id}`)
              .then(response => {
                  this.hotels.splice(this.hotels.findIndex(items => items.id === item.id), 1);

                  this.$swal.fire(
                    'Deleted!',
                    'Hotel and related data has been deleted.',
                    'success'
                  )
              })
              
            }
            })
        },

        async getSettings() {
           const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

          axios.get(`${process.env.MIX_API_SERVER_PATH}/api/settings/1`)
            .then(response => {
              if(response.data.default_lang_iso_code !== ''){
                this.defaultLang = response.data.default_lang_iso_code
              }
              loader.hide()
            })
        },

    },

    async mounted() {
        await this.getSettings()
        this.getHotels()
    },

}
</script>