<template>
    <div class="col-sm-6 col-md-3 my-3">
        <button type="button" class="btn btn-danger btn-block text-center mb-1" @click="remove"> <i class="fas fa-trash"></i> </button>
        <img :src="image" width="100%" :tabindex="index" />
    </div>
</template>

<script>
export default {
    props: {
        image: {
            required: true,
        },
        index: {
            required: true,
        },
    },
    methods: {
        remove(evt) {
            this.$emit('removeImage', this.index);

            evt.preventDefault();
            return false;
        }
    }
}
</script>