<template>
  <div class="col-sm-12 py-4" ref="content">
            <h2>Edit Outlet</h2>
        <div style="margin-top: 10px" class="form-group">
            <label for="selectedLanguage" class="has-text-weight-bold">Default language:</label>
            <input type="text" class="form-control"  readonly v-model="defaultLanguage"/>
         </div>
       <div style="margin-top: 10px" class="card">
        <header class="card-header">
          <p class="card-header-title">
            Multi Language
          </p>
          <button type="button" class="button pull-right"  @click="onAddNewLangModalClick">Support new language</button>
        </header>
        <div class="card-content">
          <div class="content">
           <b-table
          :data="supporttedMultiLangs"
          :paginated="false"
          per-page="10"
          current-page.sync="1"
          :pagination-simple="false"
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="chevron-up"
          sort-icon-size="is-small"
          :striped="true"
          :hoverable="true"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          :mobile-cards="false"
        >
        <b-table-column
          field="lang_description"
          label="Language"
          sortable
          v-slot="props"
        >
          {{ props.row.lang_description }}
        </b-table-column>

          <b-table-column
          field="name"
          label="Name"
          sortable
          v-slot="props"
        >
          {{ props.row.name }}
        </b-table-column>

        <b-table-column
          field="description"
          label="Description"
          sortable
          v-slot="props"
        >
          {{ props.row.description }}
        </b-table-column>

        <b-table-column centered label="Actions"  v-slot="props">
          <div class="buttons is-centered is-inline-block">

           <div class="row"> 

             <!-- Edit icon -->
            <button class="button is-shadowless" @click="editModalItem(props.row)">
              <span class="icon">
                <i class="fas fa-edit has-text-primary"></i>
              </span>
            </button>

             <!-- Delete icon -->
           <div v-if="defaultLanguage !== props.row.lang_description">
              <button class="button is-shadowless" @click="deleteModalItem(props.row)">
              <span class="icon">
                <i class="fas fa-trash has-text-danger"></i>
              </span>
            </button>
           </div>

           </div>

          </div>
        </b-table-column>

    </b-table>
          </div>
        </div>
      </div>

            <div class="row">
                <div class="col-sm-12">
                    <form ref="form" @submit="submit">
                        <fieldset>
                            <div class="form-group">
                                <label for="access_code">Authorization Code:</label>
                                <input type="number" id="access_code" step="1" class="form-control" v-model="access_code" min="0" max="9999" pattern="\d{4}"/>
                            </div>

                            <div class="form-group required">
                                <label>Schedule(s)</label>
                                <table class="table">
                                    <tbody>
                                        <outlet-schedule v-for="(schedule, index) in schedules" :key="index" :index="index"
                                            :schedule="schedule" @remove="removeOutletSchedule"
                                            ></outlet-schedule>
                                        <tr>
                                            <td colspan="4" class="text-center">
                                                <button type="button" class="btn btn-secondary" @click="addSchedule">Add Schedule</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="form-group required">
                                <label for="images">Images:</label>
                                <input type="file" id="images" class="form-control d-none" accept="image/*" ref="imageSelector" multiple @change="processSelected"/>
                                <button type="button" class="btn btn-secondary" @click="selectImages">Add Images</button>
                                <small class="form-text text-muted">Minimum image size is 640 width and 260 height with ideal content ratio is 5:2.</small>
                                <div class="row">
                                    <RemovableImage v-for="(image, index) in images" :image="image" :key="index" :index="index" @removeImage="removeImage">
                                    </RemovableImage>
                                </div>
                            </div>
                        </fieldset>
                    </form>

                    <!-- Modal -->
                    <div>
                        <b-modal  ref="newLangModal" 
                          id="addNewLangModal" 
                          hide-backdrop content-class="shadow" 
                          title="Support new language"
                          @ok="saveModalItem">
                          
                          <div v-if="isMSelectedLanguageVisible" class="form-group required">
                            <label for="name">Language:</label>
                              <select class="form-control required" v-model="mSelectedLanguage" @change="onMSelectedLanguageChange">
                                <option :value="mSelectedLanguage" disabled >{{ mSelectedLanguage }}</option>
                                <option v-for="(item, key) in filteredMultiLangItems" :value="item.description" :key="key">{{item.description}} </option>
                              </select>
                          </div>
                          <div class="form-group required">
                            <label for="name">Name:</label>
                            <input type="text" required class="form-control" v-model="mName"/>
                          </div>
                          <div class="form-group required">
                            <label for="description">Description:</label>
                            <textarea required class="form-control" v-model="mDescription"/>
                          </div>
                        </b-modal>
                    </div>
                    
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <button type="button" class="btn btn-primary" @click="triggerSubmit">
                        Save
                    </button>
                </div>
            </div>
        </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios"
import OutletSchedule from "../components/OutletSchedule";
import RemovableImage from "../components/RemovableImage";
import moment from "moment";

export default {
  components: {
      OutletSchedule,
        RemovableImage,
  },

  data() {
    return {
      removedSchedules: [],
      schedules: [],

      hotelId: '',
      name: '',
      description: '',
      access_code: '',
      ratio: '',
      lat: '',
      lng: '',
      currency_id: '',
      langIsoCode: '',
      outletImages: [],

      hotelCodeId: '',
      
      removedImages: [],
      selectedCurrency: 'Choose Option',
      hotelsCurrency: [],

      defaultLanguage: '',
      supportedLanguages: [],

      mItemId: '',
      mLangIsoCode: '',
      mSelectedLanguage: '',
      mName: '',
      mDescription: '',

      isEditModalItem: false,
      isMSelectedLanguageVisible: true,

      supporttedMultiLangs: [],
      tempSupportedLanguages: [],
      
    };
  },

   computed: {
    ...mapGetters(['getHotel',]),
     images() {
            const images = [];
            
            this.outletImages.forEach(imageFile => {
                if (imageFile instanceof File) {
                    images.push(URL.createObjectURL(imageFile));
                }
                else {
                    images.push(imageFile.url);
                }
            });

            return images;
        },

    filteredMultiLangItems(){
       _.each(this.supporttedMultiLangs, (item) => {
          _.remove(this.tempSupportedLanguages, (n) => {
            return n.iso_code === item.iso_code
          })
      })

      return this.tempSupportedLanguages
    }

  },

  methods: {

    clear(){
      this.name = ''
      this.description = ''
      this.access_code = ''
      this.schedules = []
      this.images = []
    },

    clearModalItem(){
      this.isMSelectedLanguageVisible = true
      this.mSelectedLanguage = 'Choose Option'
      this.mItemId = ''
      this.mLangIsoCode = ''
      this.mName = ''
      this.mDescription = ''
    },
      
        selectImages() {
            this.$refs.imageSelector.click();
        },
        processSelected(evt) {
            var images = Object.values(evt.target.files);
            images.forEach((image) => {
                this.outletImages.push(image);
            });
        },
        triggerSubmit() {

          if(this.schedules.length < 1){
            return
          }

          if(this.outletImages.length < 1){
            return
          }

          if (this.$refs.form.reportValidity()) {
             this.submit();
          }
        },
        submit(evt) {

            const loader = this.$loading.show({
                container: this.$refs.content,
                canCancel: false,
            });
            
            this.$store.dispatch('editHotelOutlet', {
                hotelId: this.$route.params.hotelId,
                outlet: {
                  id: this.$route.params.outletId,
                  name: this.name,
                  description: this.description,
                  access_code: this.access_code,
                  schedules: this.schedules,
                  images: this.outletImages
                },
                removedSchedules: this.removedSchedules,
                removedImages: this.removedImages,
            })
                .then((outlet) => {
                    this.$router.push({ name: 'hotel_view_outlets', params: {
                      hotelId: this.$route.params.hotelId,
                    }});
                })
                .catch((error) => {
                    console.log("/api/hotel/:hotelId/outlets:edit error", error, error.response);
                })
                .finally(() => {
                    loader.hide();
                });

            if (evt) {
                evt.preventDefault();
                return false;
            }
        },
        
        addSchedule() {
            this.schedules.push({
                day: "",
                time: {
                    start: "08:00:00",
                    end: "20:00:00",
                },
            });
        },
        removeOutletSchedule(index) {
            const removedSchedule = this.schedules[index];
            this.schedules.splice(index, 1);

            if (removedSchedule.id) {
                this.removedSchedules.push(removedSchedule.id);
            }
        },
        removeImage(index) {
            const image = this.outletImages[index];
            if (!(image instanceof File)) {
                this.removedImages.push(image.id);
            }
            this.outletImages.splice(index, 1);
        },

        

    //new code
    getOutlet(){
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/hotel/${this.$route.params.hotelId}/outlets/${this.$route.params.outletId}`)
        .then(response => {

          const {
            data
          } = response
          
          this.name = data.name
          this.description = data.description
          this.access_code = data.access_code
          this.outletImages = data.images.data

          data.schedules.data.forEach((schedule) => {
                const {
                    id,
                    day,
                    time,
                } = schedule;

                this.schedules.push({
                    id,
                    day,
                    time: {
                        start: moment(time.start, "H:mma").format("HH:mm:ss"),
                        end: moment(time.end, "H:mma").format("HH:mm:ss"),
                    },
                });
            });

          console.info(data)

        })
    },


    //end code
    
    
    // submit(evt) {
    //   const loader = this.$loading.show({ 
    //     container: this.$el,
    //     canCancel: false,
    //   });

    //   this.$store.dispatch('editHotel', {
    //     hotel: {
    //     id: this.hotelId,
    //     access_code: this.access_code,
    //     ratio: this.ratio,
    //     lat: this.lat,
    //     lng: this.lng,
    //     currency_id: this.currency_id,
    //     hotel_images: this.outletImages,
    //     },
    //     removedImages: this.removedImages,
    //   })
    //     .then((hotel) => {
    //       this.$router.push({ name: 'hotel_view', params: {
    //         hotelId: hotel.id,
    //         hotel: hotel
    //       }});
    //     })
    //     .catch(() => {})
    //     .finally(() => {
    //       loader.hide();
    //     });

    //   evt.preventDefault();
    //   return false;
    // },


    getSupportedLanguages(){

      const loader = this.$loading.show({
        container: this.$el,
        canCancel: false,
      });

      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/languages`)
      .then(response => {
        this.supportedLanguages = response.data.items
        this.tempSupportedLanguages = _.clone(this.supportedLanguages);

        this.getSettings()

        loader.hide()

      })
    },

    getSettings() {
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/settings/1`)
      .then(response => {
        if(response.data.default_lang_iso_code !== ''){
        this.defaultLanguage = this.supportedLanguages.find(item => item.iso_code === response.data.default_lang_iso_code).description
        this.langIsoCode = response.data.default_lang_iso_code
        }
      })
    },

    getSelectedLangHotel(item){
      axios.post(`${process.env.MIX_API_SERVER_PATH}/api/find-hotel-lang`, item)
      .then(response => {
        
        console.info(response.data)

        this.name = response.data.name
        this.description = response.data.description

         console.info('langxx', this.langIsoCode)

      })
    },
    
    getSupportedMultiLangs(){
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/hotel-outlet-multi-langs`, {
        params: {
          outlet_id: this.$route.params.outletId
        }
      })
      .then(response => {
        this.supporttedMultiLangs = response.data.items
        console.info('xx', response.data.items)

      })
    },

    onSelectedLanguageChange(e){
      this.clear()

      let lang = _.find(this.supportedLanguages, ['description', e.target.value]).iso_code

      this.langIsoCode = lang

      console.info(this.hotelCodeId)
      console.info('lang', lang)

      this.getSelectedLangHotel({
      hotel_code_id: this.hotelCodeId,
      lang_iso_code: lang,
      fall_back: 0,
      })
    },

    removeTempSupportedLangs(isoCode){
      this.tempSupportedLanguages.splice(this.supportedLanguages.find(items => items.iso_code === isoCode), 1)
    },

    addtempSupportedLangs(isoCode){
      let item = this.supportedLanguages.find(item => item.iso_code === isoCode)
      this.tempSupportedLanguages.push(item)
    },

    saveModalItem(){
      let item = {
          outlet_id: this.$route.params.outletId,
          iso_code: this.supportedLanguages.find(item => item.description === this.mSelectedLanguage).iso_code,
          name: this.mName,
          description: this.mDescription,
        }

      if(!this.isEditModalItem){
        axios.post(`${process.env.MIX_API_SERVER_PATH}/api/hotel-outlet-multi-langs`, item)
        .then((response) => {
          if(!response.data.success){
            alert('unable to save item')
            return
          }
          this.supporttedMultiLangs.push(response.data.item)
          
          this.removeTempSupportedLangs(item.iso_code)

          this.$swal.fire(
            'Saved!',
            'Language and contents added',
            'success'
          )
        })
      }else{
        axios.patch(`${process.env.MIX_API_SERVER_PATH}/api/hotel-outlet-multi-langs/${this.mItemId}`, item)
        .then((response) => {
          if(!response.data.success){
             alert('unable to save item')
            return
          }
          let itemIndex = this.supporttedMultiLangs.findIndex(item => item.id === this.mItemId)
          this.supporttedMultiLangs.splice(itemIndex, 1, response.data.item)

           this.$swal.fire(
            'Updated!',
            'Language and contents updated',
            'success'
          )

        })
      }
    },

    onMSelectedLanguageChange(item){
      this.mSelectedLanguage = item.target.value
    },

    editModalItem(item){
      this.clearModalItem()

      this.isEditModalItem = true
      this.mSelectedLanguage = item.lang_description
      this.mItemId = item.id
      this.mLangIsoCode = item.lang_iso_code
      this.mName = item.name
      this.mDescription = item.description

      if(this.defaultLanguage === item.lang_description){
        this.isMSelectedLanguageVisible = false
      }

      this.$refs["newLangModal"].show();
    },

    deleteModalItem(item){
      this.$swal.fire({
        title: 'Are you sure?',
        text: 'Supported language will be gone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          axios.delete(`${process.env.MIX_API_SERVER_PATH}/api/hotel-outlet-multi-langs/${item.id}`)
            .then((response) => {
              if(!response.data.success){
                alert('Something went wrong.')
                return
              }

              let itemIndex = this.supporttedMultiLangs.findIndex(items => items.id === item.id)

              this.supporttedMultiLangs.splice(itemIndex, 1)

              this.addtempSupportedLangs(item.iso_code)

              this.$swal.fire(
                'Deleted!',
                'Language and contents deleted',
                'success'
              )
            })
        }
      })
    },

    onAddNewLangModalClick(){
      this.clearModalItem()
      this.isEditModalItem = false
      this.$refs["newLangModal"].show();
    }


  },

  mounted() {

    this.getSupportedLanguages()

    this.getOutlet();

    this.getSupportedMultiLangs()


    // this.getSupportedLanguages()

    // var hotel = this.getHotel;

    // if (!hotel) {
    //   const hotelId = this.$route.params.hotelId;
    //   const loader = this.$loading.show({
    //     container: this.$el,
    //     canCancel: false,
    //   });

    //   this.$store.dispatch('loadHotel', hotelId)
    //     .then((hotel) => {

    //       this.assignHotel(hotel);

    //       this.getSupportedMultiLangs(hotelId)

    //     })
    //     .catch(() => {})
    //     .finally(() => {
    //       loader.hide();
    //     });
    // }
    // else {
    //   this.assignHotel(hotel);
    // }

    // this.getHotelCurrency()

    // this.getSupportedMultiLangs(this.hotelId)

  },

}
</script>