<template>
  <div class="col-sm-12">
    <br>
    <div class="row">
      <div class="col-sm-12">
        <div v-if="visible" style="margin: auto;" class="box col-sm-5">
          <div v-if="isVerified" style="text-align: center">
            <i style="color: #008000" class="fas fa-5x fa-check-circle"></i>
            <p style="margin-top: 10px; font-size: 18px; color: #00000;"> Thank you </p>
            <p style="font-size: 18px"> You have verified your email. </p>
            <br>
            <br>
          </div>

          <div v-if="!isVerified" style="text-align: center">
            <i style="color: #FF0000" class="fas fa-5x fa-times"></i>
            <p style="margin-top: 10px; font-size: 18px; color: #00000;"> Try verifying your email again. </p>
            <p style="font-size: 18px"> Your request to verify your email has expired or the link has already been used. </p>
            <br>
            <br>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>

import axios from "axios"
import { saveAs } from 'file-saver';
import DatePicker from 'vue2-datepicker';
import moment from "moment";

export default {
   components: {
    DatePicker,
  },

  data() {
    return {
      visible: false,
      isVerified: false,
    };
  },
  methods: {

    verifyToken(token){
      const loader = this.$loading.show({
        container: this.$refs.content,
        canCancel: false,
      });

      axios.post(`${process.env.MIX_API_SERVER_PATH}/api/verify-change-email`, {
        token
      }).then(response => {
        if(response.data.success){
          this.isVerified = true
        }
        this.visible = true
        console.info(response.data)
        loader.hide()
      })
    }

  },

  mounted(){

    if(this.$route.query.token){
      this.verifyToken(this.$route.query.token)
    }
    
  }

}
</script>