<template>
    <div class="col-sm-12">
        <h2>Export Data</h2>
        <br>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group ">
                    <label for="images">Export Option</label>
                    <select v-model="selectedExportOption"  class="form-control" @change="onExportOptionChange">
                        <option value="all">Export all data</option>
                        <option value="single">Export single data</option>
                    </select>
                </div>
                <div class="form-group" v-if="isExportItemVisible">
                    <label for="images">Select item to export</label>
                    <select v-model="selectedExportItem"  class="form-control" @change="onExportItemChange">
                        <option v-for="(item, key) in exportItems" :key="key" :value="item.name">{{item.name}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="images">Export format</label>
                    <select v-model="selectedExportFormat"  class="form-control" @change="onExportFormatChange">
                        <option value="csv">CSV</option>
                        <option value="xlsx">Excel file</option>
                    </select>
                </div>

                <div class="form-group">
                   <button type="button" class="button" @click="onDownloadBtnClick">Download</button>
                </div>   
            </div>
            <div class="col-sm-6">
                  <div class="">
                    <div class="form-group">
                        <label for="sort">Report for:</label>
                        <select class="form-control" v-model="selectedTransactionHistoryFor">
                            <option value="Choose Option" selected disabled>Choose Option</option>
                            <option value="collected">Collected Points History</option>
                            <option value="redeemed">Redeemed Points History</option>
                        </select>

                        <label style="margin-top: 5px"  for="sort">Sort by:</label>
                        <select class="form-control" v-model="selectedSortBy">
                            <option value="Choose Option" selected disabled>Choose Option</option>
                            <option value="id">ID</option>
                            <option value="hotel_name">Hotel Name</option>
                            <option value="outlet_name">Outlet Name</option>
                            <option value="transaction_date">Transaction Date</option>
                            <option value="receipt_number">Receipt Number</option>
                            <option value="amount_spent">Amount Spent</option>
                            <option value="points_collected">Points Collected</option>
                            <option value="membership_number">Membership Number</option>
                        </select>
                        <select style="margin-top: 5px" class="form-control" v-model="selectedSortByFlag">
                            <option value="Choose Option" selected disabled>Choose Option</option>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                        <div style="margin-top: 5px" class="form-group required">
                            <label>Date Range:</label>
                            <br>
                            <date-picker format="YYYY-MM-DD" valueType="format" :disabled-date="disabledDateStart" v-model="date.start" :input-attr="{ name: 'date-start' }"></date-picker>
                            <date-picker format="YYYY-MM-DD" valueType="format" :disabled-date="disabledDateEnd" v-model="date.end" :input-attr="{ name: 'date-end' }"></date-picker>
                        </div>
                    </div>

                <button class="button primary" @click="onGenerateReportClick">Generate Report</button>
        </div>
            </div>
        </div>
    </div>
</template>
<script>

import axios from "axios"
import { saveAs } from 'file-saver';
import DatePicker from 'vue2-datepicker';
import moment from "moment";

export default {
     components: {
        DatePicker,
    },

    data() {
        return {
            exportItems: [
                {
                    id: 1,
                    name: 'customers',
                },
                {
                    id: 2,
                    name: 'hotels',
                },
                {
                    id: 3,
                    name: 'outlets',
                },
            ],
            
            selectedExportOption: 'Choose Option',
            selectedExportItem: 'Choose Option',
            selectedExportFormat: 'Choose Option',

            isExportItemVisible: false,

            selectedSortBy: 'Choose Option',
            selectedSortByFlag: 'Choose Option',
            selectedTransactionHistoryFor: 'Choose Option',

            date: {
                start: null,
                end: null,
            },

        };
    },
    methods: {

        onExportOptionChange(e){
            this.selectedExportOption = e.target.value

            if(e.target.value == 'single'){
                this.isExportItemVisible = true
            }else{
                this.isExportItemVisible = false
            }
        },

        onExportItemChange(e){
            this.selectedExportItem = e.target.value
        },

        onExportFormatChange(e){
            this.selectedExportFormat = e.target.value
        },

        onDownloadBtnClick(e){
            saveAs(`${process.env.MIX_API_SERVER_PATH}/api/export-data?export_option=${this.selectedExportOption}&export_format=${this.selectedExportFormat}&export_item=${this.selectedExportItem}`, 'download.' + this.selectedExportFormat);
        },

        disabledDateStart(date) {
            const dateMoment = moment(date);
            const nowMoment = moment().startOf('day');
            var retVal = false;
            if (this.date.end) {
                const endMoment = moment(this.date.end);
                retVal = dateMoment.isAfter(endMoment);
            }
            else {
                retVal = dateMoment.isAfter(nowMoment);
            }
            return retVal;
        },

        disabledDateEnd(date) {
            const dateMoment = moment(date);
            const nowMoment = moment().startOf('day');
            var retVal = false;
            if (this.date.start) {
                const startMoment = moment(this.date.start);
                retVal = dateMoment.isBefore(startMoment) || dateMoment.isAfter(nowMoment);
            }
            else {
                retVal = dateMoment.isAfter(nowMoment);
            }
            return retVal;
        },

        onGenerateReportClick(){
            if(this.date.start == null || this.date.end == null){return}

            let routeData = this.$router.resolve({
                name: 'transaction_report', 
                query: {
                    transactionHistoryFor: this.selectedTransactionHistoryFor !== 'Choose Option' ? this.selectedTransactionHistoryFor : 'collected',
                    sortBy: this.selectedSortBy,
                    sortFlag: this.selectedSortByFlag,
                    dateStart: this.date.start,
                    dateEnd: this.date.end,
                }
            });

            window.open(routeData.href, '_blank');
        },


    }

}
</script>