<template>
    <div class="col-sm-12">
        <div class="row">
            <div class="col-sm-12 mb-3 text-right">
                <button type="button" class="btn btn-primary" @click="addClicked"> <i class="fas fa-plus-circle"></i> Add Deal </button>
            </div>
            <div class="col-sm-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                            <th scope="col"> </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="hasDeals">
                            <slot name="rows">
                            </slot>
                            <slot name="footer"></slot>
                        </template>
                        <template v-else>
                            <td colspan="4" class="text-center">
                                -- No deals yet --
                            </td>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        hasDeals: {
            required: true,
        },
    },

    methods: {
        addClicked() {
            this.$emit('addClicked');
        },
    },

}
</script>