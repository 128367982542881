<template>
    <div class="col-sm-12 py-3">
        <div class="row">
            <DealsTable class="col-sm-12" @addClicked="showAddModal" :hasDeals="true">
                <template v-slot:rows>
                    <HotelViewDealRow v-for="deal in deals" :key="deal.id" :hotel="hotel" :deal="deal" @clickedDeal="viewDeal" v-on:get-deals="getDeals"></HotelViewDealRow>
                </template>
            </DealsTable>
        </div>
        <HotelDealAdd :hotel="hotel" @deal-created="dealCreated"></HotelDealAdd>
        <HotelDealEdit :hotel="hotel"></HotelDealEdit>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DealsTable from "./DealsTable";
import HotelDealAdd from "./HotelDealAdd";
import HotelViewDealRow from "./HotelViewDealRow";
import HotelDealEdit from "./HotelDealEdit";
import axios from "axios"

export default {
    props: {
        hotel: null,
    },

    data() {
        return {
            deals: [],
            defaultLang: '',
        };
    },

    components: {
        DealsTable,
        HotelDealAdd,
        HotelViewDealRow,
        HotelDealEdit,
    },

    watch: {
        hotel(newValue, oldValue) {
            this.hotel = newValue;
            this.getDeals()
        },
        
        // '$route.query.page'(newValue, oldValue) {
        //     if (!newValue) {
        //         newValue = 1;
        //     }
        //     this.loadDeals(newValue);

        // },
    },

    methods: {
        getSettings(context, settingId) {
          axios.get(`${process.env.MIX_API_SERVER_PATH}/api/settings/1`)
            .then(response => {
              if(response.data.default_lang_iso_code !== ''){
                this.defaultLang = response.data.default_lang_iso_code
              }
            })
        },

        getDeals(){
           const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            axios.get(`${process.env.MIX_API_SERVER_PATH}/api/hotel/${this.$route.params.hotelId}/deals?all`)
            .then(response => {

              let deals = []

              _.each(response.data.items, (item) => {

                let multiLang = item.multi_langs.find(items => items.iso_code === this.defaultLang)

                if(!_.isEmpty(multiLang)){
                  deals.push({
                    id: item.id,
                    name: multiLang.name,
                    discount_title: multiLang.discount_title,
                    description: multiLang.description,

                    discount:{
                      amount: item.discount.amount,
                    },
                    image:{
                      url: item.image.url,
                    },

                    title_color: item.title_color,
                    name_color: item.name_color,
                    description_color: item.description_color,
                    availability_color: item.availability_color,
                    isInCarousel: item.isInCarousel,
                    discount_code_option: item.discount_code_option,
                    discount_code: item.discount_code,
                    discount_code_link_phone: item.discount_code_link_phone,
                  })
                }
              })

              this.deals = deals

              console.info('deals', deals)

              loader.hide()
            })
        },
        loadDeals(page = 1) {
            const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });

            this.$store.dispatch('loadHotelDeals', {
                hotelId: this.hotel.id,
                page
            })
                .then((response) => {
                    this.deals = this.getHotelDeals;
                })
                .catch((error) => {
                })
                .finally(() => {
                    loader.hide();
                });
        },
        showAddModal() {
            this.$modal.show('hotel-deal-add');
        },
        dealCreated(deal) {
            console.log("emit:dealCreated", deal);
            this.deals.push(deal);
        },
        viewDeal(deal) {
            const modalShowed = this.$modal.show('deal-view', {
                deal
            });
        },
    },

    computed: {
        ...mapGetters([
            'getHotelDeals'
        ]),
        // getPages() {
        //     try {
        //         const currentPage = this.deals.pagination.current_page;
        //         const totalPages = this.deals.pagination.total_pages;
        //         const pages = [];
        //         for (var x = Math.max(1, currentPage - 2); x <= Math.min(totalPages, currentPage + 2); x++) {
        //             pages.push(x);
        //         }
        //         return pages;
        //     }
        //     catch(ex) {
        //         return [];
        //     }
        // },
        // currentPage() {
        //     try {
        //         return this.deals.pagination.current_page;
        //     }
        //     catch(ex) {
        //         return 1;
        //     }
        // },
        // totalPages() {
        //     try {
        //         return this.deals.pagination.total_pages;
        //     }
        //     catch(ex) {
        //         return 1;
        //     }
        // },
        hasEntries() {
            try {
                return Object.keys(this.deals).length > 0;
            }
            catch(ex) {
                return false;
            }
        }
    },

    mounted() {
        this.getSettings()
        this.getDeals()
    },
}
</script>