<template>
    <div class="col-sm-12">
        <ul class="nav flex-column">
            <li class="nav-item" v-for="sidebarEntry in getSidebarEntries" :key="sidebarEntry.path + '-' + sidebarEntry.label">
                <router-link class="nav-link" :to="sidebarEntry.path">{{ sidebarEntry.label }}</router-link>
            </li>
        </ul>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters([
            'getSidebarEntries'
        ]),
    },
}
</script>