<script>
import DealAdd from "./DealAdd";

export default {
    extends: DealAdd,

    data() {
        return {
            title: 'Edit Deal',
            modalName: 'hotel-deal-edit',
        };
    },

    methods: {
        beforeOpen(event) {
            const {
                id,
                name,
                description,
                date,
                discount,
                image,
                title_color,
                name_color,
                description_color,
                availability_color,
                discount_code_option,
                discount_code,
                discount_code_link_phone,
            } = event.params.deal;

            this.deal = {
                id,
                name,
                description,
                date: {...date},
                discount: {...discount},
                image: {...image},
                title_color,
                name_color,
                description_color,
                availability_color,
            };
            
            this.selected_discount_code = this.discount_code_select_option.find(item => item.value === discount_code_option).name
            this.discount_code = discount_code
            this.discount_code_link_phone = discount_code_link_phone


        },
        submit(evt) {
            const loader = this.$loading.show({
                container: this.$refs.content,
                canCancel: false,
            });
            
            this.$store.dispatch('editHotelDeal', {
                hotelId: this.hotel.id,
                deal: this.deal,
                selected_discount_value: this.selected_discount_value,
                discount_code: this.discount_code,
                discount_code_link_phone: this.discount_code_link_phone,
            })
                .then((deal) => {
                    this.$emit('dealCreated', deal);
                    setTimeout(() => {
                        this.$modal.hide('hotel-deal-edit');
                    }, 1);
                })
                .catch((error) => {
                    console.log("/api/hotel/:hotelId/deals:edit error", error, error.response);
                })
                .finally(() => {
                    loader.hide();
                });

            if (evt) {
                evt.preventDefault();
                return false;
            }
        },
    },
    computed: {
        image() {
            try {
                if (this.deal.image.url) {
                    return this.deal.image.url;
                }
            }
            catch (ex) {
                console.log("no image url");
            }

            try {
                return URL.createObjectURL(this.deal.image);
            }
            catch (ex) {
                console.log("no image for upload");
            }

            return null;
        },
    }
}
</script>