export default class FileUtils {
    static encodeImageFileToBase64(imageFile, nullable = false, options = {}) {
        return new Promise((resolve, reject) => {
            try {
                var image = new Image();
                image.onload = (imageEvent) => {
                    const canvas = document.createElement('canvas');
                    const maxSize = options.maxSize ?? 1440;
                    var width = image.width;
                    var height = image.height;
                    
                    if (width > height) {
                        if (width > maxSize) {
                            height *= maxSize / width;
                            width = maxSize;
                        }
                    }
                    else if (height > maxSize) {
                        width *= maxSize / height;
                        height = maxSize;
                    }

                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                    let data = canvas.toDataURL('image/jpeg');
                    let noPrefix = data.split(',')[1];
                    resolve(noPrefix);
                };
                image.src = URL.createObjectURL(imageFile);
            }
            catch (ex) {
                if (!nullable) {
                    reject(ex);
                }
                else {
                    resolve(null);
                }
            }
        });
    }

    static getImageFilename(imageFile) {
        return imageFile.name.substr(0, imageFile.name.lastIndexOf('.'));
    }

    static getImageType(imageFile) {
        return imageFile.type.replace("image/", "");
    }
}