<template>
    <div class="container">
        <table class="table borderless">
            <thead>
                <tr>
                    <td>
                        <div class="row mt-4 mb-1">
                            <div class="col-sm-6">
                                <strong>Hotels Report</strong>
                            </div>
                            <div class="col-sm-6 text-right" v-html="dateRange"></div>
                        </div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="actual-content">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th class="align-middle text-center">ID</th>
                                    <th class="align-middle text-center">Name</th>
                                    <th class="align-middle text-center">Outlets</th>
                                    <th class="align-middle text-center">Deals</th>
                                    <th class="align-middle text-center">Points<br/>Claimed<br/>From</th>
                                    <th class="align-middle text-center">Points<br/>Redeemed<br/>From</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="hotel in hotels" :key="hotel.id">
                                    <td class="text-right">{{ hotel.id }}</td>
                                    <td>{{ hotel.name }}</td>
                                    <td class="text-right">{{ hotel.outlets }}</td>
                                    <td class="text-right">{{ hotel.deals }}</td>
                                    <td class="text-right">{{ hotel.claimed }}</td>
                                    <td class="text-right">{{ hotel.redeemed }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th class="text-left" colspan="4">Total</th>
                                    <td class="text-right">{{ totalClaimed }}</td>
                                    <td class="text-right">{{ totalRedeemed }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </td>
                </tr>
            </tbody>
            <tfoot class="footer-group">
                <tr>
                    <td class="footer text-right">
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    data() {
        return {
            hotels: [],
        };
    },

    mounted() {
        const {
            sort = 'id',
            direction = 'asc',
        } = this.$route.query;
        const params = {
            sort, direction,
        };
        if (this.$route.query['date-start']) {
            params['date-start'] = this.$route.query['date-start'];
        }
        if (this.$route.query['date-end']) {
            params['date-end'] = this.$route.query['date-end'];
        }
        this.$store.dispatch('loadHotelsReports', params).then((hotels) => {
            this.hotels = hotels;
        });
    },

    computed: {
        dateRange() {
            var dateStart = this.$route.query['date-start'];
            var dateEnd = this.$route.query['date-end'];

            if (dateStart) {
                dateStart = moment(dateStart).format('DD MMM YYYY');
            }
            if (dateEnd) {
                dateEnd = moment(dateEnd).format('DD MMM YYYY');
            }

            if (dateStart && dateEnd) {
                if (dateStart == dateEnd) {
                    return `<strong>Date Range:</strong> ${dateStart}`;
                }
                else {
                    return `<strong>Date Range:</strong> ${dateStart} to ${dateEnd}`;
                }
            }
            else if (dateStart) {
                return `<strong>Date Range:</strong> ${dateStart} onwards`;
            }
            else if (dateEnd) {
                return `<strong>Date Range:</strong> up to ${dateEnd}`;
            }
            return '<strong>Date Range:</strong> All';
        },
        totalClaimed() {
            return this.hotels.map(item => parseInt(item.claimed)).reduce((a, b) => a + b, 0);
        },
        totalRedeemed() {
            return this.hotels.map(item => parseInt(item.redeemed)).reduce((a, b) => a + b, 0);
        },
    },
}
</script>