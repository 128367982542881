import Vue from "vue";
import VueRouter from "vue-router";

import store from "./Store";
import Dashboard from './pages/Dashboard';
import Login from "./pages/Login";
import HotelList from "./pages/HotelList";
import HotelAdd from "./pages/HotelAdd";
import HotelView from "./pages/HotelView";
import HotelViewImages from "./components/HotelViewImages";
import HotelViewOutlets from "./components/HotelViewOutlets";
import HotelViewDeals from "./components/HotelViewDeals";
import HotelEdit from "./pages/HotelEdit";
import HotelOutletEdit from "./pages/HotelOutletEdit";
import DealEdit from "./pages/DealEdit";
import HotelOutletView from "./pages/HotelOutletView";
import CustomerList from "./pages/CustomerList";
import CustomerView from "./pages/CustomerView";
import HotelsReportForm from "./pages/reports/HotelsReportForm";
import CustomersReportForm from "./pages/reports/CustomersReportForm";

import HotelsReport from "./reports/HotelsReport";
import CustomersReport from "./reports/CustomersReport";
import TransactionReport from "./reports/TransactionReport";

import PageList from "./pages/PageList";
import PageForm from "./pages/PageForm";
import PageView from "./pages/PageView";

import SettingView from "./pages/SettingView";
import CurrencySettingsView from "./pages/CurrencySettingsView";
import ExportView from "./pages/ExportView";

import NotFound from "./pages/NotFound";

import Notification from "./pages/Notification";

import EmailConfirmed from "./pages/EmailConfirmed";
import ResetPassword from "./pages/ResetPassword";
import ChangeEmailConfirm from "./pages/ChangeEmailConfirm";

Vue.use(VueRouter);

const hotelSidebarEntries = [ {path: '/build/reports/hotels', label: 'Build Report'}, {path: '/hotels', label: 'List Hotels'}, {path: '/hotels/add', label: 'Add Hotel'}  ];
const hotelViewSidebarEntries = [{ path: { name: 'hotel_edit', params: [':hotelId'] }, label: 'Edit Hotel' }, { path: { name: 'hotel_delete', params: [':hotelId'] }, label: 'Delete Hotel' }].concat(hotelSidebarEntries);
const hotelOutletSidebarEntries = [ { path: { name: 'hotel_view_outlets', params: [':hotelId'] }, label: 'View Hotel' } ];

const customerSidebarEntries = [ {path: '/build/reports/customers', label: 'Build Report'} ];

const pageSidebarEntries = [ {path: '/pages', label: 'List Pages'}, {path: '/pages/add', label: 'Add Page'}  ];

const routes = [
    { path: '/', name: 'dashboard', component: Dashboard, meta: { requiresAuth: true, sidebarEntries: [ {path: '/build/reports/hotels', label: 'Build Hotels Reports'}, {path: '/build/reports/customers', label: 'Build Customers Reports'}, ] } },
    { path: '/login', name: "login", component: Login },
    { path: '/logout', name: 'logout', beforeEnter: (to, from, next) => {   
        store.dispatch('removeAuthToken').then(() => {
            next({ name: 'login' });
        });
    }, meta: { requiresAuth: true } },
    { path: '/hotels', name: 'hotel_list', component: HotelList, meta: { requiresAuth: true, sidebarEntries: hotelSidebarEntries } },
    { path: '/hotels/add', name: 'hotel_add', component: HotelAdd, meta: { requiresAuth: true, sidebarEntries: hotelSidebarEntries }},
    { path: '/hotels/:hotelId', component: HotelView, 
        children: [
            { path: '', name: 'hotel_view', component: HotelViewImages,
                meta: { requiresAuth: true,
                    sidebarEntries: hotelViewSidebarEntries
                },
            },
            { path: 'outlets', name: 'hotel_view_outlets', component: HotelViewOutlets,
                meta: { requiresAuth: true,
                    sidebarEntries: hotelViewSidebarEntries
                },
            },
            { path: 'deals', name: 'hotel_view_deals', component: HotelViewDeals,
                meta: { requiresAuth: true,
                    sidebarEntries: hotelViewSidebarEntries
                },
            },
        ],
    },
    { path: '/hotels/:hotelId/edit', name: 'hotel_edit', component: HotelEdit, 
        meta: { requiresAuth: true, 
            sidebarEntries: [
                { path: { name: 'hotel_view', params: [':hotelId'] }, label: 'View Hotel' },
                { path: { name: 'hotel_delete', params: [':hotelId'] }, label: 'Delete Hotel' },
            ].concat(hotelSidebarEntries) 
        }, 
    },
    { path: '/hotels/:hotelId/outlet/:outletId/edit', name: 'hotel_outlet_edit', params: {edit: 'outlet'}, component: HotelOutletEdit, 
        meta: { requiresAuth: true, 
            // sidebarEntries: [
            //     { path: { name: 'hotel_view', params: [':hotelId'] }, label: 'View Hotel' },
            //     { path: { name: 'hotel_delete', params: [':hotelId'] }, label: 'Delete Hotel' },
            // ].concat(hotelSidebarEntries) 
        }, 
    },
    { path: '/hotels/:hotelId/deals/:dealId', name: 'hotel_deal_edit', component: DealEdit, 
        meta: { requiresAuth: true, 
            // sidebarEntries: [
            //     { path: { name: 'hotel_view', params: [':hotelId'] }, label: 'View Hotel' },
            //     { path: { name: 'hotel_delete', params: [':hotelId'] }, label: 'Delete Hotel' },
            // ].concat(hotelSidebarEntries) 
        }, 
    },
    { path: '/hotels/:hotelId/outlet/:outletId/deals/:dealId', name: 'outlet_deal_edit', component: DealEdit, 
        meta: { requiresAuth: true, 
            // sidebarEntries: [
            //     { path: { name: 'hotel_view', params: [':hotelId'] }, label: 'View Hotel' },
            //     { path: { name: 'hotel_delete', params: [':hotelId'] }, label: 'Delete Hotel' },
            // ].concat(hotelSidebarEntries) 
        }, 
    },
    { path: '/hotels/:hotelId/delete', name: 'hotel_delete', beforeEnter: (to, from, next) => { 
        const hotelId =  to.params.hotelId;
        store.dispatch('removeHotel', hotelId).then((success) => {
            console.log("delete", hotelId, success);
        }).finally(() => {
            next({ name: 'hotel_list', params: { reload: true } });
        });
    }, meta: { requiresAuth: true } },
    { path: '/hotels/:hotelId/outlet/:outletId', name: 'hotel_outlet_view', component: HotelOutletView, 
        meta: {
            requiresAuth: true,
            sidebarEntries: hotelOutletSidebarEntries,
        } 
    },
    { path: '/customers', name: 'customer_list', component: CustomerList, 
        meta: {
            requiresAuth: true,
            sidebarEntries: customerSidebarEntries,
        } 
    },
    { path: '/customers/:customerId', name: 'customer_view', component: CustomerView, 
        meta: {
            requiresAuth: true,
            sidebarEntries: customerSidebarEntries,
        } 
    },
    { 
        path: '/build/reports/hotels', name: 'hotels_report_form', component: HotelsReportForm,
        meta: {
            requiresAuth: true,
        }
    },
    { 
        path: '/reports/hotels', name: 'hotels_report', component: HotelsReport,
        meta: {
            requiresAuth: true,
            reportsMode: true,
        }
    },
    { 
        path: '/build/reports/customers', name: 'customers_report_form', component: CustomersReportForm,
        meta: {
            requiresAuth: true,
        }
    },
    { 
        path: '/reports/transactions', name: 'transaction_report', component: TransactionReport,
        meta: {
            requiresAuth: true,
        }
    },
    { 
        path: '/reports/customers', name: 'customers_report', component: CustomersReport,
        meta: {
            requiresAuth: true,
            reportsMode: true,
        }
    },
    { path: '/pages', name: 'page_list', component: PageList, meta: { requiresAuth: true, sidebarEntries: pageSidebarEntries } },
    { path: '/pages/add', name: 'page_add', component: PageForm, meta: { requiresAuth: true, sidebarEntries: pageSidebarEntries }},
    { path: '/pages/:pageId', name: 'page_view', component: PageView, 
        meta: { requiresAuth: true, 
            sidebarEntries: [
                { path: { name: 'page_edit', params: [':pageId'] }, label: 'Edit Page' },
                { path: { name: 'page_delete', params: [':pageId'] }, label: 'Delete Page' },
            ].concat(pageSidebarEntries) 
        }, 
    },
    { path: '/pages/:pageId/edit', name: 'page_edit', component: PageForm, 
        meta: { requiresAuth: true, 
            sidebarEntries: [
                { path: { name: 'page_view', params: [':pageId'] }, label: 'View Page' },
                { path: { name: 'page_delete', params: [':pageId'] }, label: 'Delete Page' },
            ].concat(pageSidebarEntries) 
        }, 
    },
    { path: '/pages/:pageId/delete', name: 'page_delete', beforeEnter: (to, from, next) => { 
        const pageId =  to.params.pageId;
        store.dispatch('removePage', pageId).then((success) => {
            console.log("delete", pageId, success);
        }).finally(() => {
            next({ name: 'page_list', params: { reload: true } });
        });
    }, meta: { requiresAuth: true } },
    { path: '*', name: "404", component: NotFound },

    { path: '/settings', name: 'settings', component: SettingView, meta: { requiresAuth: true,  } },
    { path: '/currency-settings', name: 'currency_settings', component: CurrencySettingsView, meta: { requiresAuth: true,  } },
    { path: '/export-data', name: 'export_data', component: ExportView, meta: { requiresAuth: true,  } },

    { path: '/notifications', name: 'notifications', component: Notification, 
        meta: {
        requiresAuth: true,
        } 
    },
    { path: '/email-confirm', name: 'email_confirm', component: EmailConfirmed, 
        meta: {
            NavBar: false,
        } 
    },
    { path: '/reset-password', name: 'reset_password', component: ResetPassword, 
        meta: {
            NavBar: false,
        } 
    },
    { path: '/confirm-change-email', name: 'change_email_confirm', component: ChangeEmailConfirm, 
        meta: {
            NavBar: false,
        } 
    },
    
]

const router = new VueRouter({
    mode: "history",
    routes
});

router.beforeEach((to, from, next) => {
    const isLoggedIn = store.getters.isLoggedIn;

    store.commit('setSidebarEntries', to.meta.sidebarEntries || []);
    store.commit('setUrlParams', to.params);

    if (to.name == "login" && isLoggedIn) {
        next('/');
    }
    else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isLoggedIn) {
            next({
                name: 'login',
                query: {
                    redirect: to.fullPath,
                }
            });
        }
        else {
            next();
        }
    }
    else {
        next();
    }
});

export default router;