<template>
    <div class="col-sm-12 dashboard">
        <h2>Dashboard</h2>
        <div class="card-columns" v-if="dashboard">
            <div class="card">
                <div class="card-header">
                    <strong>Customers</strong>
                </div>
                <div class="card-body">
                    <dl class="row mr-4">
                        <dt class="col-sm-6">Total:</dt>
                        <dd class="col-sm-6 mb-0 text-right">{{ dashboard.customers.count }}</dd>
                        <dt class="col-sm-6">New:</dt>
                        <dd class="col-sm-6 mb-0 text-right">{{ dashboard.customers.new }}</dd>
                        <dt class="col-sm-6">Google:</dt>
                        <dd class="col-sm-6 mb-0 text-right">{{ dashboard.customers.google }}</dd>
                        <dt class="col-sm-6">Facebook:</dt>
                        <dd class="col-sm-6 mb-0 text-right">{{ dashboard.customers.facebook }}</dd>
                    </dl>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <strong>Points</strong>
                </div>
                <div class="card-body">
                    <dl class="row mr-4">
                        <dt class="col-sm-12">Claimed:</dt>
                        <dd class="col-sm-12">
                            <dl class="row ml-4">
                                <dt class="col-sm-6">Total:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.points.claimed.count }}</dd>
                                <dt class="col-sm-6">Today:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.points.claimed.today }}</dd>
                                <dt class="col-sm-6">This Week:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.points.claimed.week }}</dd>
                                <dt class="col-sm-6">This Month:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.points.claimed.month }}</dd>
                            </dl>
                        </dd>
                        <dt class="col-sm-12">Redeemed:</dt>
                        <dd class="col-sm-12">
                            <dl class="row ml-4">
                                <dt class="col-sm-6">Total:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.points.redeemed.count }}</dd>
                                <dt class="col-sm-6">Today:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.points.redeemed.today }}</dd>
                                <dt class="col-sm-6">This Week:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.points.redeemed.week }}</dd>
                                <dt class="col-sm-6">This Month:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.points.redeemed.month }}</dd>
                            </dl>
                        </dd>
                    </dl>
                </div>
            </div>
            
            <div class="card">
                <div class="card-header">
                    <strong>Hotels</strong>
                </div>
                <div class="card-body">
                    <dl class="row mr-4">
                        <dt class="col-sm-6">Total:</dt>
                        <dd class="col-sm-6 mb-0 text-right">{{ dashboard.hotels.count }}</dd>
                        <dt class="col-sm-12">Deals:</dt>
                        <dd class="col-sm-12">
                            <dl class="row ml-4">
                                <dt class="col-sm-6">Total:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.hotels.deals.count }}</dd>
                                <dt class="col-sm-6">Active:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.hotels.deals.active }}</dd>
                                <dt class="col-sm-6">Upcoming:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.hotels.deals.upcoming }}</dd>
                                <dt class="col-sm-6">Expired:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.hotels.deals.expired }}</dd>
                            </dl>
                        </dd>
                    </dl>
                </div>
            </div>
            
            <div class="card">
                <div class="card-header">
                    <strong>Outlets</strong>
                </div>
                <div class="card-body">
                    <dl class="row mr-4">
                        <dt class="col-sm-6">Total:</dt>
                        <dd class="col-sm-6 mb-0 text-right">{{ dashboard.outlets.count }}</dd>
                        <dt class="col-sm-12">Deals:</dt>
                        <dd class="col-sm-12">
                            <dl class="row ml-4">
                                <dt class="col-sm-6">Total:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.outlets.deals.count }}</dd>
                                <dt class="col-sm-6">Active:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.outlets.deals.active }}</dd>
                                <dt class="col-sm-6">Upcoming:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.outlets.deals.upcoming }}</dd>
                                <dt class="col-sm-6">Expired:</dt>
                                <dd class="col-sm-6 mb-0 text-right">{{ dashboard.outlets.deals.expired }}</dd>
                            </dl>
                        </dd>
                    </dl>
                </div>
            </div>
            
            <div class="card">
                <div class="card-header">
                    <strong>Deals</strong>
                </div>
                <div class="card-body">
                    <dl class="row mr-4">
                        <dt class="col-sm-6">Total:</dt>
                        <dd class="col-sm-6 mb-0 text-right">{{ dashboard.deals.count }}</dd>
                        <dt class="col-sm-6">Active:</dt>
                        <dd class="col-sm-6 mb-0 text-right">{{ dashboard.deals.active }}</dd>
                        <dt class="col-sm-6">Upcoming:</dt>
                        <dd class="col-sm-6 mb-0 text-right">{{ dashboard.deals.upcoming }}</dd>
                        <dt class="col-sm-6">Expired:</dt>
                        <dd class="col-sm-6 mb-0 text-right">{{ dashboard.deals.expired }}</dd>
                    </dl>
                </div>
            </div>
            
            <div class="card">
                <div class="card-header">
                    <strong>Users</strong>
                </div>
                <div class="card-body">
                    <dl class="row mr-4">
                        <dt class="col-sm-6">Total:</dt>
                        <dd class="col-sm-6 mb-0 text-right">{{ dashboard.users.count }}</dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            dashboard: {
                customers: {},
                points: {
                    claimed: {},
                    redeemed: {},
                },
                hotels: {
                    deals: {},
                },
                outlets: {
                    deals: {},
                },
                deals: {},
                users: {},
            },
        };
    },

    mounted() {
        const dashboard = this.getDashboard;
        this.loadDashboard();
    },

    computed: {
        ...mapGetters(['getDashboard']),
    },
    methods: {
        ...mapActions(['loadDashboard']),
    },

    watch: {
        getDashboard(newValue) {
            this.dashboard = newValue;
        }
    }
}
</script>

<style lang="scss" scoped>
    .card-columns {
        column-count: 2;
    }
</style>