import axios from "axios"


export default class WebUtils {
    static getApiPath(path) {
        return process.env.MIX_API_SERVER_PATH + path;
    }

    static request(url, method = "GET", data = {}, includeToken = true) {

        const config = {
            url,
            method: method.toLocaleLowerCase(),
            data,
            headers: {

            },
        };

        console.log("REQUESTING", url, method.toUpperCase());

        if (includeToken) {
            let authToken = localStorage.getItem("AUTH_TOKEN");
            if (authToken) {
                config.headers["Authorization"] = `Bearer ${authToken}`;
            }
            else {
                return new Promise((res, rej) => {
                    rej(new Error("Not logged in."));
                });
            }
        }

        return axios.request(config);
    }
}