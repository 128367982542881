<template>
    <div class="col-sm-12">
        <h2>{{ page.title }}</h2>
        <dl class="row">
            <dt class="col-sm-3">Endpoint</dt>
            <dd class="col-sm-9">{{page.endpoint}}</dd>
            <dt class="col-sm-3">Contents</dt>
            <dd class="col-sm-9">
                <VueEditor disabled v-model="page.contents"></VueEditor>
            </dd>
        </dl>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapGetters } from "vuex";

export default {
    components: {
        VueEditor,
    },

    data() {
        return {
            page: {},
        };
    },

    mounted() {
        let page = this.getPage;

        if (!page) {
            const loader = this.$loading.show({
                container: this.$el,
                canCancel: false,
            });
            
            this.$store.dispatch('loadPage', this.$route.params.pageId)
            .then((page) => {
                this.page = page;
            })
            .finally(() => {
                loader.hide();
            });
        }
        else {
            this.page = page;
        }
    },

    computed: {
        ...mapGetters(['getPage']),
    },
}
</script>