<template>
    <div class="container">
    
    <div class="col-sm-12">
          <div style="margin-top: 30px" class="col-sm-4">
                <button type="button" class="button" @click="onDownloadBtnClick">Download Report</button>
            </div>   
        <div style="margin-top: 30px" class="field col-sm-6">
            <p class="control has-icons-left">
                <input
                    class="input is-shadowless"
                    type="text"
                    placeholder="Search Report"
                    v-model="search"
                />
                <span class="icon is-small is-left">
                <i class="fas fa-search"></i>
                </span>
            </p>
        </div>
    </div>

    <b-table
      :data="filtered"
      :paginated="true"
      per-page="10"
      current-page.sync="1"
      :pagination-simple="false"
      pagination-position="bottom"
      default-sort-direction="asc"
      sort-icon="chevron-up"
      sort-icon-size="is-small"
      :striped="true"
      :hoverable="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :mobile-cards="false"
    >
        <b-table-column
          field="id"
          label="ID"
          sortable
          v-slot="props"
        >
          {{ props.row.id }}
        </b-table-column>

        <b-table-column
          field="hotel_name"
          label="Hotel Name"
          sortable
          v-slot="props"
        >
          {{ props.row.hotel_name }}
        </b-table-column>

        <b-table-column
          field="outlet_name"
          label="Outlet Name"
          sortable
          v-slot="props"
        >
          {{ props.row.outlet_name }}
        </b-table-column>

        <b-table-column
          field="formatted_transaction_date"
          label="Transaction Date"
          sortable
          v-slot="props"
        >
          {{ props.row.formatted_transaction_date }}
        </b-table-column>

        <b-table-column
          field="receipt_number"
          label="Receipt Number"
          sortable
          v-slot="props"
          :visible="isCollectedHeaderVisible"
        >
          {{ props.row.receipt_number }}

        </b-table-column>

        <b-table-column
          field="currency_iso_code"
          label="Currency"
          sortable
          v-slot="props"
        >
          {{ props.row.currency_iso_code }}
        </b-table-column>

        <b-table-column
          field="amount_spent"
          label="Amount Spent"
          sortable
          v-slot="props"
        >
          {{ props.row.amount_spent }}
        </b-table-column>

        <b-table-column
          field="points_collected"
          label="Points Collected"
          sortable
          v-slot="props"
          :visible="isCollectedHeaderVisible"
        >
          {{ props.row.points_collected }}
        </b-table-column>

        <b-table-column
          field="points_redeemed"
          label="Points Redeemed"
          sortable
          v-slot="props"
          :visible="isRedeemHeaderVisible"
        >
          {{ props.row.points_redeemed }}
        </b-table-column>

        <b-table-column
          field="membership_number"
          label="Membership Number"
          sortable
          v-slot="props"
        >
          {{ props.row.membership_number }}
        </b-table-column>

        <b-table-column
          field="redemption_code"
          label="Redemption Code"
          sortable
          v-slot="props"
          :visible="isRedeemHeaderVisible"
        >
          {{ props.row.redemption_code }}
        </b-table-column>

        <b-table-column
          field="is_points_expired"
          label="Expired"
          sortable
          v-slot="props"
          :visible="isCollectedHeaderVisible"
        >
           <span class="icon is-small is-left">
            <i :class="props.row.is_points_expired ? 'fas fa-check' : 'fas fa-times'"></i>
           </span>
            
        </b-table-column>

    </b-table>

    <p v-for="(item, id) in transactionHistories.present_currency_totals" :key="id">
         Total amount spent {{item.currency_iso_code}}  {{item.total_amount_spent}}
    </p>

    <p> {{totalPoints.label}}: {{totalPoints.value}} </p>

    </div>
</template>

<script>

import moment from 'moment';
import axios from "axios"

export default {

    data() {
        return {
            search: '',
            transactionHistories: [],
            isRedeemHeaderVisible: false,
            isCollectedHeaderVisible: true,
        };
    },

    computed: {
        filtered() {
            if (!this.search.trim()) {
                return this.transactionHistories.items
            }
            return this.transactionHistories.items.filter(data =>
                Object.values(data).some(val =>
                String(val)
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
                )
            )
        },
        
        totalPoints(){
            if(this.transactionHistories.transaction_for !== 'collected'){
                return {
                    'label': 'Total points redeemed',
                    'value': this.transactionHistories.total_points_redeemed,
                }
            }
            
            return {
                    'label': 'Total points collected',
                    'value': this.transactionHistories.total_points_collected,
                }
        },
    },

    methods: {
        
        onDownloadBtnClick(){
             const {
                transactionHistoryFor = 'collected',
                sortBy = 'id',
                sortFlag = 'asc',
                dateStart = null,
                dateEnd = null,
            } = this.$route.query

            saveAs(`${process.env.MIX_API_SERVER_PATH}/api/dl-transaction-histories?
                transaction_history_for=${transactionHistoryFor}&
                sort_by=${sortBy}&
                sort_flag=${sortFlag}&
                date_start=${dateStart}&
                date_end=${dateEnd}&
                `, 'transaction-history.xlsx');
        }
    },

    async mounted() {

        const {
            transactionHistoryFor = 'collected',
            sortBy = 'id',
            sortFlag = 'asc',
            dateStart = null,
            dateEnd = null,
        } = this.$route.query
        
        console.info(transactionHistoryFor)
        console.info(sortBy)
        console.info(sortFlag)
        console.info(dateStart)
        console.info(dateEnd)

        axios.post(`${process.env.MIX_API_SERVER_PATH}/api/transaction-histories`, {
            'transaction_history_for': transactionHistoryFor,
            'sort_by': sortBy,
            'sort_flag': sortFlag,
            'date_start': dateStart,
            'date_end': dateEnd,
        }).then((response) => {
            this.transactionHistories = response.data
            console.info('response', this.transactionHistories)

            if(this.transactionHistories.transaction_for !== "collected"){
                this.isRedeemHeaderVisible =  true
                this.isCollectedHeaderVisible =  false
            }else{
                this.isRedeemHeaderVisible = false
                this.isCollectedHeaderVisible = true
            }
        })

    },

}
</script>