<template>
    <div class="main-content col-sm-12">
        <h2>Customer Details</h2>
        <div v-if="customer">
            <dl class="row">
                <dd class="col-sm-12 col-md-6 col-lg-4 offset-md-6 offset-lg-4 text-center">
                    <img :src="customer.profile_image_url" width="100%"/>
                </dd>
                <dd class="w-100"></dd>

                <dd class="col-sm-12 col-md-6">
                    <legend>Basic Info</legend>
                    <dl class="row">
                        <dt class="col-sm-4">First Name:</dt>
                        <dd class="col-sm-8">{{ customer.first_name }}</dd>
                        <dt class="col-sm-4">Last Name:</dt>
                        <dd class="col-sm-8">{{ customer.last_name }}</dd>
                        <dt class="col-sm-4">e-mail:</dt>
                        <dd class="col-sm-8">{{ customer.user_account.email }}</dd>
                        <dt class="col-sm-4">Phone Number:</dt>
                        <dd class="col-sm-8">{{ customer.phone_number }}</dd>
                    </dl>
                </dd>
                <dd class="col-sm-12 col-md-6">
                    <legend>Membership</legend>
                    <dl class="row">
                        <dt class="col-sm-4">Number:</dt>
                        <dd class="col-sm-8">{{ customer.membership.number }}</dd>
                        <dt class="col-sm-4">Since:</dt>
                        <dd class="col-sm-8">{{ customer.formatted_dates.member_since }}</dd>
                    </dl>
                </dd>

                <dd class="col-sm-12">
                    <b-card no-body>
                        <b-tabs pills card v-model="tab" @activate-tab="tabChanged">
                            <b-tab title="Points Summary">
                                <div class="col-sm-12 col-md-9">
                                    <dl class="row">
                                        <dt class="col-sm-4 text-right">Total Points:</dt>
                                        <dd class="col-sm-8 text-left">{{ customer.points.total }}</dd>
                                        <dt class="col-sm-4 text-right">Expiring on {{ customer.points.month }}:</dt>
                                        <dd class="col-sm-8 text-left">{{ customer.points.month_expiring || 0 }}</dd>
                                    </dl>
                                </div>
                            </b-tab>
                            <b-tab title="Claim History">
                                <PointsHistory :tab="1" action="loadCustomerClaimPointsHistory"></PointsHistory>
                            </b-tab>
                            <b-tab title="Redeem History">
                                <PointsHistory :tab="2" action="loadCustomerRedeemPointsHistory"></PointsHistory>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </dd>
                
            </dl>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapACtions } from "vuex";
import PointsHistory from "../components/PointsHistory";

export default {

    components: {
        PointsHistory,
    },

    data() {
        return {
            customer: null,
            tab: 0,
        };
    },

    mounted() {
        const customerId = this.$route.params.customerId;

        const {
            tab = 0,
            page = 1,
        } = this.$route.query;

        this.tab = parseInt(tab);
        
        const loader = this.$loading.show({
            container: this.$el,
            canCancel: false,
        });

        this.$store.dispatch('loadCustomer', customerId)
            .then((customer) => {
                this.customer = customer;
            })
            .catch((ex) => {
            })
            .finally(() => {
                loader.hide();
            });
    },

    methods: {
        tabChanged(tabIndex) {
            const {
                name,
                hash,
                query,
                params,
            } = this.$route;

            const newQuery = {
                tab: tabIndex,
            };

            const { tab = this.tab } = query;

            if (this.tab == tabIndex) {
                newQuery.page = query.page;
            }
            else if (tabIndex != 0) {
                newQuery.page = 1;
            }

            this.$router.replace({
                name, hash, query: newQuery, params,
            });
        },
    },
    
    computed: {
    }
}
</script>
<style scoped>
div.main-content {
    min-height: 480px;
}
</style>