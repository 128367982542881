<template>
  <div class="col-sm-12">
    <h2>Edit Hotel</h2>
        <div style="margin-top: 10px" class="form-group">
            <label for="selectedLanguage" class="has-text-weight-bold">Default language:</label>
            <input type="text" class="form-control"  readonly v-model="defaultLanguage"/>
         </div>
       <div style="margin-top: 10px" class="card">
        <header class="card-header">
          <p class="card-header-title">
            Multi Language
          </p>
          <button type="button" class="button pull-right"  @click="onAddNewLangModalClick">Support new language</button>
        </header>
        <div class="card-content">
          <div class="content">
           <b-table
          :data="supporttedMultiLangs"
          :paginated="false"
          per-page="10"
          current-page.sync="1"
          :pagination-simple="false"
          pagination-position="bottom"
          default-sort-direction="asc"
          sort-icon="chevron-up"
          sort-icon-size="is-small"
          :striped="true"
          :hoverable="true"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          :mobile-cards="false"
        >
        <b-table-column
          field="lang_description"
          label="Language"
          sortable
          v-slot="props"
        >
          {{ props.row.lang_description }}
        </b-table-column>

          <b-table-column
          field="name"
          label="Name"
          sortable
          v-slot="props"
        >
          {{ props.row.name }}
        </b-table-column>

        <b-table-column
          field="description"
          label="Description"
          sortable
          v-slot="props"
        >
          {{ props.row.description }}
        </b-table-column>

         <b-table-column
          field="location_address"
          label="Location address"
          sortable
          v-slot="props"
        >
          {{ props.row.location_address }}
        </b-table-column>

        <b-table-column centered label="Actions"  v-slot="props">
          <div class="buttons is-centered is-inline-block">

           <div class="row"> 

             <!-- Edit icon -->
            <button class="button is-shadowless" @click="editModalItem(props.row)">
              <span class="icon">
                <i class="fas fa-edit has-text-primary"></i>
              </span>
            </button>

             <!-- Delete icon -->
           <div v-if="defaultLanguage !== props.row.lang_description">
              <button class="button is-shadowless" @click="deleteModalItem(props.row)">
              <span class="icon">
                <i class="fas fa-trash has-text-danger"></i>
              </span>
            </button>
           </div>

           </div>

          </div>
        </b-table-column>

    </b-table>
          </div>
        </div>
        <!-- <footer class="card-footer">
          <a href="#" class="card-footer-item">Save</a>
          <a href="#" class="card-footer-item">Edit</a>
          <a href="#" class="card-footer-item">Delete</a>
        </footer> -->
      </div>
      
    <form @submit="submit">
      <fieldset>  
        <div class="form-group required">
          <label for="access_code">Authorization Code:</label>
          <input type="number" required id="access_code" step="1" class="form-control" v-model="access_code" min="0" max="9999" pattern="\d{4}"/>
        </div>
        <div class="form-group required">
          <label for="ratio">Points Ratio:</label>
          <input type="number" required step="any" id="ratio" class="form-control" min="1" v-model="ratio"/>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group required">
              <label for="location_lat">Latitude:</label>
              <input type="number" required step="any" id="location_lat" class="form-control" v-model="lat"/>
            </div>
          </div>
          <div class="col-sm- required6">
            <div class="form-group">
              <label for="location_lng">Longitude:</label>
              <input type="number" required step="any" id="location_lng" class="form-control" v-model="lng"/>
            </div>
          </div>
        </div>
         <div class="form-group required">
          <label for="location_address">Currency</label>
          <select v-model="selectedCurrency"  class="form-control" @change="onCurrencyChange">
             <option :value="selectedCurrency" disabled >{{ selectedCurrency }}</option>
            <option v-for="(item, key) in hotelsCurrency" :key="key" :value="item.iso_code">{{item.currency}} - {{item.iso_code}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="images">Images:</label>
          <input type="file" id="images" class="form-control d-none" accept="image/*" ref="imageSelector" multiple @change="processSelected"/>
          <button type="button" class="button" @click="selectImages">Add Images</button>
          <small class="form-text text-muted">Minimum image size is 640 width and 260 height with ideal content ratio is 5:2.</small>
          <div class="row">
            <RemovableImage v-for="(image, index) in images" :image="image" :key="index" :index="index" @removeImage="removeImage">
            </RemovableImage>
          </div>
        </div>
        <button type="submit" class="button">Save</button>
      </fieldset>
    </form>

    <!-- Modal -->
    <div>
        <b-modal  ref="newLangModal" 
          id="addNewLangModal" 
          hide-backdrop content-class="shadow" 
          title="Support new language"
          @ok="saveModalItem">
          
          <div v-if="isMSelectedLanguageVisible" class="form-group required">
            <label for="name">Language:</label>
              <select class="form-control required" v-model="mSelectedLanguage" @change="onMSelectedLanguageChange">
                <option :value="mSelectedLanguage" disabled >{{ mSelectedLanguage }}</option>
                <option v-for="(item, key) in filteredMultiLangItems" :value="item.description" :key="key">{{item.description}} </option>
              </select>
          </div>
          <div class="form-group required">
            <label for="name">Name:</label>
            <input type="text" required class="form-control" v-model="mName"/>
          </div>
          <div class="form-group required">
            <label for="description">Description:</label>
            <textarea required class="form-control" v-model="mDescription"/>
          </div>
          <div class="form-group required">
            <label for="location_address">Location Address:</label>
            <input type="text" required class="form-control" v-model="mAddress"/>
          </div>

        </b-modal>
    </div>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
import RemovableImage from "../components/RemovableImage";
import axios from "axios"

export default {
  components: {
    RemovableImage,
  },

  data() {
    return {
      hotelId: '',
      name: '',
      description: '',
      access_code: '',
      ratio: '',
      address: '',
      lat: '',
      lng: '',
      langIsoCode: '',
      hotelImages: [],

      hotelCodeId: '',
      
      removedImages: [],
      selectedCurrency: 'Choose Option',
      hotelsCurrency: [],

      defaultLanguage: '',
      supportedLanguages: [],

      mItemId: '',
      mLangIsoCode: '',
      mSelectedLanguage: '',
      mName: '',
      mDescription: '',
      mAddress: '',

      isEditModalItem: false,
      isMSelectedLanguageVisible: true,

      supporttedMultiLangs: [],
      tempSupportedLanguages: [],
      
    };
  },

   computed: {
    images() {
      const images = [];
      
      this.hotelImages.forEach((imageFile) => {
        if (imageFile instanceof File) {
          images.push(URL.createObjectURL(imageFile));
        }
        else {
          images.push(imageFile.url);
        }
      });

      return images;
    },

    // filteredMultiLang() {
    //         if (!this.search.trim()) {
    //             return this.supporttedMultiLangs
    //         }
    //         return this.supporttedMultiLangs.filter(data =>
    //             Object.values(data).some(val =>
    //             String(val)
    //                 .toLowerCase()
    //                 .includes(this.search.toLowerCase())
    //             )
    //         )
    //     },

    filteredMultiLangItems(){
       _.each(this.supporttedMultiLangs, (item) => {
          _.remove(this.tempSupportedLanguages, (n) => {
            return n.iso_code === item.iso_code
          })
      })

      return this.tempSupportedLanguages
    }

  },

  methods: {

    clearModalItem(){
      this.isMSelectedLanguageVisible = true
      this.mSelectedLanguage = 'Choose Option'
      this.mItemId = ''
      this.mLangIsoCode = ''
      this.mName = ''
      this.mDescription = ''
      this.mAddress = ''
    },

    assignHotel(hotel) {
      console.info('hotelx', hotel)
      this.hotelId = hotel.id
      this.access_code = hotel.access_code
      this.ratio = hotel.ratio
      this.lat = hotel.location.lat
      this.lng = hotel.location.lng
      this.hotelImages = hotel.images.data
      this.selectedCurrency = hotel.hotel_currency_iso_code
    },

    selectImages() {
      this.$refs.imageSelector.click();
    },

    processSelected(evt) {
      var images = Object.values(evt.target.files);
      images.forEach((image) => {
        this.hotelImages.push(image);
      });
    },
    
    submit(evt) {

      evt.preventDefault()

      if(this.hotelImages.length < 1){
        return
      }

      const loader = this.$loading.show({ 
        container: this.$el,
        canCancel: false,
      });

      this.$store.dispatch('editHotel', {
        hotel: {
        id: this.hotelId,
        access_code: this.access_code,
        ratio: this.ratio,
        lat: this.lat,
        lng: this.lng,
        currency_id: this.hotelsCurrency.find(item => item.iso_code === this.selectedCurrency).id,
        hotel_images: this.hotelImages,
        },
        removedImages: this.removedImages,
      })
        .then((hotel) => {
          this.$router.push({ name: 'hotel_view', params: {
            hotelId: hotel.id,
            hotel: hotel
          }});
        })
        .catch(() => {})
        .finally(() => {
          loader.hide();
        });

      evt.preventDefault();
      return false;
    },
    removeImage(index) {
      const image = this.hotelImages[index];
      if (!(image instanceof File)) {
        this.removedImages.push(image.id);
      }
      this.hotelImages.splice(index, 1);
    },
    async getHotelCurrency(){
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/currency-settings`)
      .then(response => {
        this.hotelsCurrency = response.data.items
      })
    },

    onCurrencyChange(e){
      this.selectedCurrency = e.target.value
    },

    getSupportedLanguages(){
      const loader = this.$loading.show({
        container: this.$el,
        canCancel: false,
      });

      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/languages`)
      .then(response => {
        this.supportedLanguages = response.data.items
        this.tempSupportedLanguages = _.clone(this.supportedLanguages);

        this.getSettings()

        loader.hide()
      })
    },

    getSettings(context, settingId) {
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/settings/1`)
      .then(response => {
        if(response.data.default_lang_iso_code !== ''){
        this.defaultLanguage = this.supportedLanguages.find(item => item.iso_code === response.data.default_lang_iso_code).description
        this.langIsoCode = response.data.default_lang_iso_code
        }
      })
    },

    
    getSupportedMultiLangs(hotelId){
      axios.get(`${process.env.MIX_API_SERVER_PATH}/api/hotel-multi-langs`, {
        params: {
          hotel_id: hotelId
        }
      })
      .then(response => {
        this.supporttedMultiLangs = response.data.items
        console.info('xx', response.data.items)

      })
    },

    removeTempSupportedLangs(isoCode){
      this.tempSupportedLanguages.splice(this.supportedLanguages.find(items => items.iso_code === isoCode), 1)
    },

    addtempSupportedLangs(isoCode){
      let item = this.supportedLanguages.find(item => item.iso_code === isoCode)
      this.tempSupportedLanguages.push(item)
    },

    saveModalItem(e){

      if(this.mName.trim().length < 1){
        e.preventDefault()
        return
      } 

      if(this.mSelectedLanguage.trim().length < 1 || this.mSelectedLanguage === 'Choose Option'){
        e.preventDefault()
        return
      }

      let item = {
          hotel_id: this.hotelId,
          iso_code: this.supportedLanguages.find(item => item.description === this.mSelectedLanguage).iso_code,
          name: this.mName,
          description: this.mDescription,
          location_address: this.mAddress,
        }

      if(!this.isEditModalItem){
        axios.post(`${process.env.MIX_API_SERVER_PATH}/api/hotel-multi-langs`, item)
        .then((response) => {
          if(!response.data.success){
            alert('unable to save item')
            return
          }
          this.supporttedMultiLangs.push(response.data.item)
          
          this.removeTempSupportedLangs(item.iso_code)

          this.$swal.fire(
            'Saved!',
            'Language and contents added',
            'success'
          )
        })
      }else{
        axios.patch(`${process.env.MIX_API_SERVER_PATH}/api/hotel-multi-langs/${this.mItemId}`, item)
        .then((response) => {
          if(!response.data.success){
             alert('unable to save item')
            return
          }
          let itemIndex = this.supporttedMultiLangs.findIndex(item => item.id === this.mItemId)
          this.supporttedMultiLangs.splice(itemIndex, 1, response.data.item)

           this.$swal.fire(
            'Updated!',
            'Language and contents updated',
            'success'
          )

        })
      }
    },

    onMSelectedLanguageChange(item){
      this.mSelectedLanguage = item.target.value
    },

    editModalItem(item){
      this.clearModalItem()

      this.isEditModalItem = true
      this.mSelectedLanguage = item.lang_description
      this.mItemId = item.id
      this.mLangIsoCode = item.lang_iso_code
      this.mName = item.name
      this.mDescription = item.description
      this.mAddress = item.location_address

      if(this.defaultLanguage === item.lang_description){
        this.isMSelectedLanguageVisible = false
      }

      this.$refs["newLangModal"].show();
    },

    deleteModalItem(item){
      this.$swal.fire({
        title: 'Are you sure?',
        text: 'Supported language will be gone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          axios.delete(`${process.env.MIX_API_SERVER_PATH}/api/hotel-multi-langs/${item.id}`)
            .then((response) => {
              if(!response.data.success){
                alert('Something went wrong.')
                return
              }

              let itemIndex = this.supporttedMultiLangs.findIndex(items => items.id === item.id)

              this.supporttedMultiLangs.splice(itemIndex, 1)

              this.addtempSupportedLangs(item.iso_code)

              this.$swal.fire(
                'Deleted!',
                'Language and contents deleted',
                'success'
              )
            })
        }
      })
    },

    onAddNewLangModalClick(){
      this.clearModalItem()
      this.isEditModalItem = false
      this.$refs["newLangModal"].show();
    }


  },

  mounted() {

    this.getSupportedLanguages()

    const hotelId = this.$route.params.hotelId;

    const loader = this.$loading.show({
      container: this.$el,
      canCancel: false,
    });

    this.$store.dispatch('loadHotel', hotelId)
      .then((hotel) => {

        this.assignHotel(hotel);

        this.getSupportedMultiLangs(hotelId)

      })
      .catch(() => {})
      .finally(() => {
        loader.hide();
    });

    this.getHotelCurrency()


  },

}
</script>